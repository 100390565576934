import React, { useContext } from "react";
import constants from "../../../../constants";
import BackSvgIcon from "../../../../Svg/BackSvgIcon";
import CodingTable from "../../CodingPage/CodingTable";
import TestDueHooks from "../../../../Hooks/TestDueHooks";
import AlertContext from "../../../../context/AlertContext";
import StatusLabel from "../../../CommonComponents/StatusLabel";
import TestDueDate from "../../../CommonComponents/TestDueDate";
import WarningNotice from "../../InstructionPage/WarningNotice";
import AssignmentPage from "../../AssignmentPage/AssignmentPage";
import InstructionPage from "../../InstructionPage/InstructionPage";
import useCommonFunctionHooks from "../../../../Hooks/useCommonFunctionHooks";
import WarningNoticeBodySvgIcon from "../../../../Svg/WarningNoticeBodySvgIcon";

export default function RightMain() {
  const Alert = useContext(AlertContext);

  const { currentDueLabel } = TestDueHooks();
  const { checkBatchType } = useCommonFunctionHooks();

  window.onload = () => {
    Alert.navigate("mock/library");
  };

  const handleBackFromAssignment = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    }

    if (Alert.testData.mockuse) {
      Alert.navigate("/mock/library");
    } else {
      Alert.navigate(`/batches/${sessionStorage.getItem("abatchid")}/report#`);
    }
  };

  return (
    <div className="rightMainContainer">
      {(Alert.testData.mockuse ||
        (Alert.isAdmin &&
          Alert.testData.testtype === constants.libTypeIds.ASSIGNMENT)) && (
        <div className="rightMainBack">
          <BackSvgIcon onClick={handleBackFromAssignment} />
        </div>
      )}
      <div className="rightMainHeader">
        <div className="rightMainHeaderInnerDiv">
          <span className="rightMainHeaderTestLabel">
            {Alert.testData.testname !== null
              ? Alert.testData.testname
              : Alert.testData.libname}
          </span>
          <StatusLabel component={"rightMain"} />
        </div>
        {!Alert.isAdmin &&
          !checkBatchType() &&
          !localStorage.getItem("mockLp") &&
          !Alert.testData.hasOwnProperty("mockuse") && <TestDueDate />}
      </div>
      {(!localStorage.getItem("mockLp") &&
        !Alert.isAdmin &&
        currentDueLabel(Alert.testData.duedate) === "Past Due" &&
        Alert.status !== constants.PROGRESS_STATUS.EVALUATED &&
        Alert.status !== constants.PROGRESS_STATUS.TO_BE_EVALUATED) ||
      ((Alert.status === constants.PROGRESS_STATUS.EVALUATED ||
        Alert.status === constants.PROGRESS_STATUS.IN_PROGRESS ||
        Alert.status === constants.PROGRESS_STATUS.TO_BE_EVALUATED) &&
        (Alert.testData.testtype === constants.libTypeIds.MCQ ||
          Alert.testData.testtype === constants.libTypeIds.DESCRIPTIVE)) ? (
        <div>
          <WarningNotice />
          {/* {(Alert.status === constants.PROGRESS_STATUS.EVALUATED ||
            Alert.status === constants.PROGRESS_STATUS.TO_BE_EVALUATED ||
            Alert.status === constants.PROGRESS_STATUS.PARTIAL_EVALUATED) &&
            (Alert.testData.testtype === constants.libTypeIds.MCQ ||
              Alert.testData.testtype === constants.libTypeIds.DESCRIPTIVE) && (
              <WarningNoticeBodySvgIcon component="rightMain" />
            )} */}
        </div>
      ) : (
        <>
          {Alert.showInstruction &&
            Alert.status !== constants.PROGRESS_STATUS.EVALUATED && (
              <InstructionPage />
            )}
          {!Alert.showInstruction &&
          Alert.testData.testtype === constants.libTypeIds.CODING_CHALLENGE ? (
            <CodingTable />
          ) : (
            (Alert.isAdmin
              ? Alert.testData.testtype === constants.libTypeIds.ASSIGNMENT
              : Alert.testData.libtype === constants.libTypeIds.ASSIGNMENT) && (
              <AssignmentPage />
            )
          )}
        </>
      )}
    </div>
  );
}
