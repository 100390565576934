import React, { useContext, useEffect, useState } from "react";
import { Select, MenuItem, ListItemIcon } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import { changeFavicon } from "../../util";
import constants from "../../constants";
import { getDataFromStorage } from "../../util";
import AlertContext from "../../context/AlertContext";

// const useStyles = makeStyles((theme) => ({
//     menuItem: {
//       width: '220px',
//       height: '44px',
//       display: 'flex',
//       justifyContent: 'space-between',
//       fontFamily:'Inter',
//       fontSize:'16px',
//       fontWeight: '500',
//       color:'#101828 !important'
//     },
//     listItemIcon: {
//       width: '20px',
//       height: '20px',
//       color: '#F55533',
//     },
//     menuItemBack: {
//         width: '220px',
//         height: '44px',
//         fontFamily:'Inter', fontSize:'16px', fontWeight: '500', color:'#101828'
//     },
//     listItemArrowIcon: {
//         minWidth: '20px !important',
//         maxHeight: '20px',
//         color: '#667085',
//     },
//     svg: {
//         width: '20px',
//         height: '20px',
//         color: '#667085',
//     }
//   }));

export default function BatchDropDown(props) {
  const Alert = useContext(AlertContext);

  const { pathname } = useLocation();
  const [selectedOption, setSelectedOption] = useState("");

  /* for get specific selected batchname */
  const selectedBatch = props.batchdetails?.find(
    (item) => item.id == props.batchId
  );

  const handleChange = (event) => {
    if (event.target.value === "" || event.target.value === undefined) {
      Alert.navigate("batch");
      props.setBatchId(0);
      props.setNavhome(false);
      localStorage.removeItem("batchid");
      localStorage.removeItem("batchname");
    } else {
      setSelectedOption(event.target.value);

      /* for get specific selected batchid */

      const selectedBatchId = props.batchdetails.find(
        (item) => item.name === event.target.value
      );

      props.setBatchId(selectedBatchId.id);
      localStorage.setItem("batchid", selectedBatchId.id);
      localStorage.setItem("batchname", selectedBatchId.name);

      localStorage.setItem("batchid", selectedBatchId.id);
      localStorage.setItem("batchname", selectedBatchId.name);
      localStorage.setItem("favicon", selectedBatchId.favicon);
      localStorage.setItem("logo", selectedBatchId.logo);
      if (
        selectedBatchId.favicon &&
        selectedBatchId.favicon !== "" &&
        selectedBatchId.favicon !== "null"
      ) {
        changeFavicon(selectedBatchId.favicon);
      }

      if (
        selectedBatchId.logo &&
        selectedBatchId.logo !== "" &&
        selectedBatchId.logo !== "null"
      ) {
        let element = document.getElementsByClassName(
          "headerDivLogoInnerDiv"
        )[0];

        element.innerHTML = ` <div className="companyLogoContainer">
            <img
              className="companyLogo"
              alt="logo"
              src=${selectedBatchId.logo}
              width= 28.15px
            height= 28px
            />
            <div
              className= "companyLogoText"
              
            >
              Powered by Gradious
            </div>`;
      }
    }
  };

  // useEffect(() => {
  //   let from =
  //     props.option == 2
  //       ? "optionsscore"
  //       : props.option == 3
  //       ? "optionstimespent"
  //       : null;
  //   if (from != null) {
  //     props.handleLeaderboardDetail(from);
  //   }
  // }, [props.batchId]);

  return (
    <div className="dashboard-batch-dropdown-div">
      <div className="naviagtorfortestscorestimespent">
        <div className="testscorestimespentcontainer">
          <span
            onClick={() => {
              props.setOption(1);
              Alert.navigate("landingpage");
            }}
            className={
              pathname === "/landingpage"
                ? "optionsfornavigatecurrent"
                : "optionsfornavigate"
            }
          >
            <span
              className={
                pathname === "/landingpage"
                  ? "optiontextfornavigatecurrent"
                  : "optiontextfornavigate"
              }
            >
              Overview
            </span>
          </span>
          <span
            onClick={() => {
              Alert.navigate("landingpage/testresults");
            }}
            className={
              pathname.includes("testresults")
                ? "optionsfornavigatecurrent"
                : "optionsfornavigate"
            }
          >
            <span
              className={
                pathname.includes("testresults")
                  ? "optiontextfornavigatecurrent"
                  : "optiontextfornavigate"
              }
            >
              Test Results
            </span>
          </span>
          <span
            onClick={() => {
              Alert.navigate("landingpage/timespent");
            }}
            className={
              pathname.includes("timespent")
                ? "optionsfornavigatecurrent"
                : "optionsfornavigate"
            }
          >
            <span
              className={
                pathname.includes("timespent")
                  ? "optiontextfornavigatecurrent"
                  : "optiontextfornavigate"
              }
            >
              Time Spent{" "}
            </span>
          </span>
        </div>
      </div>
      <div className="dashboard-batch-dropdown">
        <Select
          className="batch-dropdown-selectbox"
          value={selectedOption}
          onChange={handleChange}
          displayEmpty
          renderValue={(value) =>
            value
              ? value
              : selectedBatch
              ? selectedBatch.name
              : "Select an option"
          }
          sx={{
            // '& .MuiOutlinedInput-notchedOutline': {
            //   borderColor: '#F9B9AC',
            // },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "#F9B9AC",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: "#F9B9AC",
            },
          }}
        >
          {props.batchdetails
            ?.filter(
              (data) =>
                (getDataFromStorage("role") == constants.Roles.parent &&
                  data.type == constants.BATCH_TYPE.LEARNING) ||
                (getDataFromStorage("role") != constants.Roles.parent &&
                  (data.type == constants.BATCH_TYPE.LEARNING ||
                    data.type == constants.BATCH_TYPE.TESTING))
            )
            .map((item) => (
              <MenuItem
                className="batch-dropdown-selectbox-menuitem"
                /* className={classes.menuItem} */ key={item.id}
                value={item.name} /* title={item.name} */
              >
                {/* <span className='batch-dropdown-item-name'>{item.name}</span> */}
                <Tooltip title={item.name} placement="right">
                  <span className="batch-dropdown-item-name">{item.name}</span>
                </Tooltip>
                {selectedOption !== "" && selectedOption === item.name ? (
                  <ListItemIcon
                    className="batch-dropdown-selectbox-listitemicon" /* className={classes.listItemIcon} */
                  >
                    <CheckIcon />
                  </ListItemIcon>
                ) : (
                  selectedBatch &&
                  selectedBatch.name === item.name && (
                    <ListItemIcon
                      className="batch-dropdown-selectbox-listitemicon" /* className={classes.listItemIcon} */
                    >
                      <CheckIcon />
                    </ListItemIcon>
                  )
                )}
              </MenuItem>
            ))}
          <MenuItem
            className="batch-dropdown-selectbox-menuitemback"
            /* className={classes.menuItemBack} */ onClick={() =>
              handleChange({ target: { value: "" } })
            }
          >
            <ListItemIcon
              className="batch-dropdown-selectbox-listitemarrowicon" /* className={classes.listItemArrowIcon} */
            >
              <ArrowBackIosIcon
                className="batch-dropdown-selectbox-svg" /* className={classes.svg} */
              />
            </ListItemIcon>
            View all batches
          </MenuItem>
        </Select>
      </div>
    </div>
  );
}
