import React, { useContext, useEffect, useRef, useState } from "react";
import ToggleButton from "../../../CommonComponents/ToggleButton";
import Button from "../../../Button";
import { TextField } from "@mui/material";
import ButtonGroup from "../../../CommonComponents/ButtonGroup";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import SettingsRightHeader from "./SettingsRightHeader";
import { useParams } from "react-router-dom";
import _ from "lodash";
import useAxiosPrivate from "../../../../Hooks/useAxiosPrivate";
import AlertContext from "../../../../context/AlertContext";
import constants from "../../../../constants";
import Checkbox from "../../../CommonComponents/Checkbox";
import { getDataFromStorage } from "../../../../util";
import ProfileUpload from "../../../Student/Profile/ProfileUpload";
import BatchPathManager from "./BatchPaths";

const BatchDetails = () => {
  const { id } = useParams();
  const axios = useAxiosPrivate();
  const {
    setIsLoaded,
    batchDetails,
    setBatchDetails,
    navigate,
    setShowNotify,
  } = useContext(AlertContext);

  const [paths, setPaths] = useState([]);
  const [newPath, setNewPath] = useState({
    courseName: "",
    lpId: null,
    lpName: "",
  });
  const [isChangePathName, setIsChangePathName] = useState(false);
  const [pathNames, setPathNames] = useState(() =>
    _.keyBy(batchDetails.learningpath, "lpid")
  );
  const [focusStates, setFocusStates] = useState(() =>
    ["name", "description", "stardate", "slackid", "roletitle"].reduce(
      (acc, key) => ({ ...acc, [key]: false }),
      {}
    )
  );
  const [thumbnail, setThumbnail] = useState(batchDetails.thumbnail);
  const settings = useRef({
    ...batchDetails,
  });

  useEffect(() => {
    setPathNames(_.keyBy(batchDetails.learningpath, "lpid"));
  }, [batchDetails.learningpath]);

  useEffect(() => {
    const fetchPaths = async () => {
      try {
        setIsLoaded(true);
        let response = await axios.get("node/admin/learningpath/get", {
          headers: {
            "Content-type": "application/json",
          },
        });
        if (response.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
          if (response.data.data !== null) {
            const res = _.filter(response.data.data, { archived: 0 });
            const newPaths = _.differenceBy(
              res,
              batchDetails.learningpath.map((lp) => ({ id: lp.lpid })),
              "id"
            );
            setPaths(
              _.map(newPaths, (path) => {
                return { label: path.name, value: path.id };
              })
            );
          } else {
            setPaths([]);
          }
        } else {
          setShowNotify({
            show: true,
            title: "Error !",
            msg: "Something went wrong. Please try again later or contact gradious team",
          });

          return;
        }
      } catch (error) {
        console.error("err while fetch path", error);
        if (error.message.includes("403")) {
          setShowNotify({
            show: true,
            title: "Warning !",
            msg: "You have been logged-out due to inactivity. Login again.",
            isUnAuth: true,
          });
        } else
          setShowNotify({
            show: true,
            title: "Error !",
            msg: "Something went wrong. Please try again later or contact gradious team",
          });
      } finally {
        setIsLoaded(false);
      }
    };
    fetchPaths();
  }, [axios, id, setIsLoaded, setShowNotify, batchDetails.learningpath]);

  // useEffect(() => {
  //   const updateBatchSettings = async () => {};
  //   updateBatchSettings();
  // }, [axios, setIsLoaded, id, batchDetails]);

  useEffect(() => {
    const updateThumbnail = async () => {
      try {
        setIsLoaded(true);
        let response = await axios.post(
          "node/admin/batch/updatethumbnail",
          {
            batchid: id,
            newimage: thumbnail,
          },
          {
            headers: {
              "Content-type": "application/json",
            },
          }
        );
        if (response.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
          setBatchDetails((prev) => ({ ...prev, thumbnail: thumbnail }));
        } else {
          setShowNotify({
            show: true,
            title: "Error !",
            msg: "Something went wrong. Please try again later or contact gradious team",
          });
        }
      } catch (error) {
        console.error("update thumbnail error", error);
        if (error.message.includes("403")) {
          setShowNotify({
            show: true,
            title: "Warning !",
            msg: "You have been logged-out due to inactivity. Login again.",
            isUnAuth: true,
          });
        } else
          setShowNotify({
            show: true,
            title: "Error !",
            msg: "Something went wrong. Please try again later or contact gradious team",
          });
      } finally {
        setIsLoaded(false);
      }
    };
    if (thumbnail) updateThumbnail();
  }, [thumbnail, axios, setIsLoaded, setShowNotify, id, setBatchDetails]);

  const handleResponse = (title, msg) => {
    setShowNotify({
      show: true,
      title,
      msg,
    });
  };

  const updateSettings = async (field, value) => {
    setBatchDetails((pre) => ({ ...pre, [field]: value }));
    settings.current = { ...settings.current, [field]: value };
    if (field === "archived" || field === "batchtype" || field === "useorder")
      await handleSave();
  };

  const onChangeOldPathName = (value, lpId) => {
    setIsChangePathName(true);
    setPathNames((prev) => ({
      ...prev,
      [lpId]: { ...prev[lpId], coursename: value },
    }));
  };

  const updateOldPathName = async (event, lpId) => {
    event.preventDefault();
    try {
      setIsLoaded(true);
      let payload = {
        lpId,
        batchId: id,
        courseName: pathNames[lpId].coursename,
      };
      let response = await axios.post(
        "node/admin/batch/coursename/create",
        JSON.stringify(payload),
        {
          headers: {
            "Content-type": "application/json",
          },
        }
      );
      if (response.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
        setShowNotify({
          show: true,
          title: "Info",
          msg: "Path name renamed successfully.",
        });

        let learningpaths = JSON.parse(
          JSON.stringify(batchDetails.learningpath)
        );
        learningpaths = _.map(learningpaths, (path) => {
          if (path.lpid === lpId) {
            path.coursename = payload.courseName;
            path.lpname = payload.courseName;
          }
          return path;
        });
        setBatchDetails((prev) => ({ ...prev, learningpath: learningpaths }));
        setIsChangePathName(false);
      } else if (
        response.data.resultCode === constants.RESULT_STATUS.TECHNICAL_ERROR &&
        response.data.msg.includes("Duplicate entry")
      ) {
        setShowNotify({
          show: true,
          title: "Warning !",
          msg: "This path name is already exists. Please give a different name.",
        });
      } else {
        setShowNotify({
          show: true,
          title: "Error !",
          msg: "Something went wrong. Please try again later or contact gradious team",
        });
      }
    } catch (error) {
      if (error.message.includes("403")) {
        setShowNotify({
          show: true,
          title: "Warning !",
          msg: "You have been logged-out due to inactivity. Login again.",
          isUnAuth: true,
        });
      } else {
        setShowNotify({
          show: true,
          title: "Error !",
          msg: "Something went wrong. Please try again later or contact gradious team",
        });
      }
    } finally {
      setIsLoaded(false);
    }
  };

  const addNewPath = async (event) => {
    event.preventDefault();
    try {
      setIsLoaded(true);
      let payload = { ...newPath, batchId: id, batchName: batchDetails.name };
      if (newPath && newPath.lpId) {
        if (!newPath.courseName || newPath.courseName === "")
          payload.courseName = payload.lpName;
        let response = await axios.post(
          "node/admin/batch/learningpath/create",
          JSON.stringify(payload),
          {
            headers: {
              "Content-type": "application/json",
            },
          }
        );
        if (response.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
          setShowNotify({
            show: true,
            title: "Info",
            msg: `A new path has been assigned to the ${batchDetails.name} batch. Please check course page for more.`,
          });

          let learningpaths = JSON.parse(
            JSON.stringify(batchDetails.learningpath)
          );
          learningpaths.push({
            lpid: response.data.data.lpId,
            coursename: payload.courseName,
            lpname: payload.courseName,
          });
          setBatchDetails((prev) => ({ ...prev, learningpath: learningpaths }));
          setNewPath({ courseName: "", lpId: null, lpName: "" });
        } else if (
          response.data.resultCode ===
            constants.RESULT_STATUS.TECHNICAL_ERROR &&
          response.data.msg.includes("Duplicate entry")
        ) {
          setShowNotify({
            show: true,
            title: "Warning !",
            msg: "This path name is already exists. Please give a different name.",
          });
        } else {
          setShowNotify({
            show: true,
            title: "Error !",
            msg: "Something went wrong. Please try again later or contact gradious team",
          });
        }
      } else
        setShowNotify({
          show: true,
          title: "Warning !",
          msg: "Please select a path to assign",
        });
    } catch (error) {
      if (error.message.includes("403")) {
        setShowNotify({
          show: true,
          title: "Warning !",
          msg: "You have been logged-out due to inactivity. Login again.",
          isUnAuth: true,
        });
      } else {
        setShowNotify({
          show: true,
          title: "Error !",
          msg: "Something went wrong. Please try again later or contact gradious team",
        });
      }
    } finally {
      setIsLoaded(false);
    }
  };

  const handleSave = async (isNavigate) => {
    try {
      setIsLoaded(true);
      let payload = {
        archived: settings.current.archived,
        useorder: settings.current.useorder,
        type: settings.current.batchtype,
        slackid: settings.current.slackid,
        name: settings.current.name,
        description: settings.current.description,
        startdate: settings.current.startdate,
        roletitle: settings.current.roletitle ?? "",
      };
      let response = await axios.put(
        `node/admin/batch/settings/${id}`,
        payload,
        {
          headers: { "Content-type": "application/json" },
        }
      );
      if (response.data.resultCode !== constants.RESULT_STATUS.SUCCESS) {
        setShowNotify({
          show: true,
          title: "Error !",
          msg: "An error occured while update batch settings.",
        });
        return;
      }
      if (
        response.data.resultCode === constants.RESULT_STATUS.SUCCESS &&
        isNavigate
      ) {
        navigate(`/batches/${id}/settings/skillmap`);
      }
    } catch (error) {
      if (error.message.includes("403")) {
        setShowNotify({
          show: true,
          title: "Warning !",
          msg: "You have been logged-out due to inactivity. Login again.",
          isUnAuth: true,
        });
      } else {
        setShowNotify({
          show: true,
          title: "Error !",
          msg: "Something went wrong. Please try again later or contact gradious team",
        });
      }
    } finally {
      setIsLoaded(false);
    }
  };

  return (
    <div className="batchDetailsContainer">
      <SettingsRightHeader
        title="Batch Details"
        titleIcon={
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 16V12M12 8H12.01M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z"
              stroke="#344054"
              strokeWidth="1.6"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        }
        button={
          <ToggleButton
            size="sm"
            text="Archive Batch"
            pressed={batchDetails?.archived}
            onChange={(value) => updateSettings("archived", value)}
          />
        }
      />
      <div className="batchDetailsBodySec">
        <div className="batchDetailsFormSec">
          <form className="batchDetailsForm">
            <div className="bdTopSec">
              <div
                className="bdTopLeftSec"
                style={
                  parseInt(getDataFromStorage("accountType")) ===
                  constants.ACCOUNT_TYPE.CLIENT_EVAL
                    ? { width: "80%" }
                    : {}
                }
              >
                <div className="bdNameAndTypeSec">
                  <TextField
                    label="Batch Name"
                    name="name"
                    id="bdBatchName"
                    InputLabelProps={{
                      shrink:
                        focusStates["name"] || Boolean(batchDetails?.name),
                    }}
                    variant="outlined"
                    value={batchDetails?.name}
                    InputProps={{
                      maxLength: 100,
                      onFocus: (event) =>
                        setFocusStates({
                          ...focusStates,
                          [event.target.name]: true,
                        }),
                      onBlur: (event) =>
                        setFocusStates({
                          ...focusStates,
                          [event.target.name]: false,
                        }),
                    }}
                    onChange={(event) =>
                      setBatchDetails((prev) => ({
                        ...prev,
                        [event.target.name]: event.target.value,
                      }))
                    }
                    size="small"
                  />
                  {parseInt(getDataFromStorage("accountType")) ===
                    constants.ACCOUNT_TYPE.LMS &&
                    getDataFromStorage("role") === constants.Roles.admin && (
                      <div className="bdTypeSec">
                        <label className="bdTypeLabel">Type</label>
                        <ButtonGroup
                          buttons={[
                            {
                              label: "Learning",
                              icon: (
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M11.6666 1.89128V5.3334C11.6666 5.80011 11.6666 6.03346 11.7575 6.21172C11.8374 6.36853 11.9649 6.49601 12.1217 6.5759C12.2999 6.66673 12.5333 6.66673 13 6.66673H16.4421M13.3333 10.8333H6.66665M13.3333 14.1667H6.66665M8.33331 7.50001H6.66665M11.6666 1.66667H7.33331C5.93318 1.66667 5.23312 1.66667 4.69834 1.93916C4.22793 2.17884 3.84548 2.56129 3.6058 3.0317C3.33331 3.56647 3.33331 4.26654 3.33331 5.66667V14.3333C3.33331 15.7335 3.33331 16.4335 3.6058 16.9683C3.84548 17.4387 4.22793 17.8212 4.69834 18.0609C5.23312 18.3333 5.93318 18.3333 7.33331 18.3333H12.6666C14.0668 18.3333 14.7668 18.3333 15.3016 18.0609C15.772 17.8212 16.1545 17.4387 16.3942 16.9683C16.6666 16.4335 16.6666 15.7335 16.6666 14.3333V6.66667L11.6666 1.66667Z"
                                    stroke="#344054"
                                    strokeWidth="1.67"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              ),
                              disabled: false,
                              name: "learning",
                              value: constants.BATCH_TYPE.LEARNING,
                            },
                            {
                              label: "Evaluation",
                              icon: (
                                <svg
                                  width="20"
                                  height="20"
                                  viewBox="0 0 20 20"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M17.5 17.5H10.8333M2.08331 17.9167L6.7077 16.1381C7.00349 16.0243 7.15138 15.9674 7.28975 15.8931C7.41265 15.8272 7.52981 15.751 7.64001 15.6655C7.76408 15.5692 7.87612 15.4572 8.10021 15.2331L17.5 5.83334C18.4205 4.91286 18.4205 3.42048 17.5 2.5C16.5795 1.57953 15.0871 1.57953 14.1667 2.5L4.76688 11.8998C4.54279 12.1239 4.43075 12.2359 4.33447 12.36C4.24896 12.4702 4.17281 12.5873 4.10684 12.7102C4.03257 12.8486 3.97569 12.9965 3.86192 13.2923L2.08331 17.9167ZM2.08331 17.9167L3.79841 13.4575C3.92114 13.1384 3.98251 12.9788 4.08776 12.9057C4.17975 12.8419 4.29357 12.8177 4.40356 12.8387C4.52942 12.8628 4.6503 12.9836 4.89205 13.2254L6.77462 15.108C7.01637 15.3497 7.13725 15.4706 7.16128 15.5964C7.18229 15.7064 7.15813 15.8203 7.09427 15.9122C7.02118 16.0175 6.86164 16.0789 6.54254 16.2016L2.08331 17.9167Z"
                                    stroke="#344054"
                                    strokeWidth="1.67"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                  />
                                </svg>
                              ),
                              disabled: false,
                              name: "evaluation",
                              value: constants.BATCH_TYPE.TESTING,
                            },
                          ]}
                          onClick={(value) =>
                            updateSettings("batchtype", parseInt(value))
                          }
                          value={parseInt(batchDetails?.batchtype)}
                        />
                      </div>
                    )}
                </div>
                <div className="bdDescriptionSec">
                  <TextField
                    label="Description"
                    name="description"
                    className="bdDescription"
                    id="bdDescription"
                    variant="outlined"
                    InputLabelProps={{
                      shrink:
                        focusStates["description"] ||
                        Boolean(batchDetails?.description),
                    }}
                    placeholder="Description of batch"
                    size="small"
                    multiline={true}
                    fullWidth
                    value={batchDetails?.description}
                    InputProps={{
                      maxLength: 200,
                      onFocus: (event) =>
                        setFocusStates({
                          ...focusStates,
                          [event.target.name]: true,
                        }),
                      onBlur: (event) =>
                        setFocusStates({
                          ...focusStates,
                          [event.target.name]: false,
                        }),
                    }}
                    onChange={(event) =>
                      setBatchDetails((prev) => ({
                        ...prev,
                        [event.target.name]: event.target.value,
                      }))
                    }
                  />
                </div>
              </div>
              <div
                className="bdTopRightSec"
                style={
                  parseInt(getDataFromStorage("accountType")) ===
                  constants.ACCOUNT_TYPE.CLIENT_EVAL
                    ? { width: "20%" }
                    : {}
                }
              >
                <ProfileUpload
                  profilePic={thumbnail}
                  setProfilePic={setThumbnail}
                  handleResponse={handleResponse}
                  buttonText="Upload a new Thumbnail"
                  size={"480 * 270"}
                />
              </div>
            </div>
            {getDataFromStorage("role") === "admin" ? (
              <div className="bdMandateOrderSec">
                <Checkbox
                  size="sm"
                  text="Mandate Order"
                  checked={batchDetails?.useorder}
                  onChange={(value) => updateSettings("useorder", value)}
                />
              </div>
            ) : null}
            <div className="bdStartDateSec">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label="Start Date"
                  id="bdStartDate"
                  className="bdStartDate"
                  name="startdate"
                  minDate={dayjs("1990-01-01")}
                  maxDate={dayjs("9999-12-31")}
                  value={
                    batchDetails?.startdate
                      ? dayjs(batchDetails.startdate)
                      : null
                  }
                  onChange={(event) =>
                    setBatchDetails((prev) => ({
                      ...prev,
                      [event.target.name]: event.target.value,
                    }))
                  }
                />
              </LocalizationProvider>
            </div>
            <div className="bdSlackNameSec">
              <TextField
                label="Slack ID"
                name="slackid"
                className="bdSlackName"
                id="bdSlackName"
                variant="outlined"
                InputLabelProps={{
                  shrink:
                    focusStates["slackid"] || Boolean(batchDetails?.slackid),
                }}
                placeholder="Enter Slack ID"
                size="small"
                value={batchDetails?.slackid}
                InputProps={{
                  maxLength: 50,
                  onFocus: (event) =>
                    setFocusStates({
                      ...focusStates,
                      [event.target.name]: true,
                    }),
                  onBlur: (event) =>
                    setFocusStates({
                      ...focusStates,
                      [event.target.name]: false,
                    }),
                }}
                onChange={(event) =>
                  updateSettings("slackid", event.target.value)
                }
              />
            </div>
            {parseInt(getDataFromStorage("accountType")) ===
              constants.ACCOUNT_TYPE.LMS && (
              <div className="bdRoleTitleSec">
                <TextField
                  label="Role Title"
                  name="roletitle"
                  className="bdRoleTitle"
                  id="bdRoleTitle"
                  variant="outlined"
                  InputLabelProps={{
                    shrink:
                      focusStates["roletitle"] ||
                      Boolean(batchDetails?.roletitle),
                  }}
                  placeholder="Enter Role Title"
                  size="small"
                  value={batchDetails?.roletitle}
                  InputProps={{
                    maxLength: 50,
                    onFocus: (event) =>
                      setFocusStates({
                        ...focusStates,
                        [event.target.name]: true,
                      }),
                    onBlur: (event) =>
                      setFocusStates({
                        ...focusStates,
                        [event.target.name]: false,
                      }),
                  }}
                  onChange={(event) =>
                    updateSettings("roletitle", event.target.value)
                  }
                />
              </div>
            )}
            <BatchPathManager
              batchDetails={batchDetails}
              paths={paths}
              pathNames={pathNames}
              onChangeOldPathName={onChangeOldPathName}
              isChangePathName={isChangePathName}
              setIsChangePathName={setIsChangePathName}
              updateOldPathName={updateOldPathName}
              newPath={newPath}
              setNewPath={setNewPath}
              addNewPath={addNewPath}
            />
          </form>
        </div>
        <div className="batchDetailsBtnSec">
          <Button
            size="sm"
            hierarchy={{ type: "primary", buttonText: "Save" }}
            onClick={() => handleSave(true)}
          />
        </div>
      </div>
    </div>
  );
};

export default BatchDetails;
