import "./App.scss";
import "./Sass/Main.scss";
import Error404 from "./Error404";
import constants from "./constants";
import Layout from "./Components/Layout";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";
import Notify from "./Components/Notify/Notify";
import AlertContext from "./context/AlertContext";
import PushNotification from "./PushNotification";
import RequireAuth from "./Components/RequireAuth";
import Batch from "./Components/Admin/Batch/Batch";
import PresistLogin from "./Components/PresistLogin";
import Batches from "./Components/Admin/Batch/Batches";
import Courses from "./Components/Admin/Batch/Courses";
import LoginPage from "./Components/LoginPage/LoginPage";
import TestList from "./Components/Admin/Tests/TestList";
import Headers from "./Components/Student/Header/Header";
import BrowserSupport from "./Components/BrowserSupport";
import Header from "./Components/CommonComponents/Header";
import { changeFavicon, getDataFromStorage } from "./util";
import Profile from "./Components/Student/Profile/Profile";
import { ProfileProvider } from "./context/ProfileContext";
import { LibraryProvider } from "./context/LibraryContext";
import Resume from "./Components/Admin/Batch/Users/Resume";
import Report from "./Components/Admin/Batch/Report/Report";
import { browserName, CustomView } from "react-device-detect";
import Content from "./Components/Student/Home/Content/Content";
import AdminHeader from "./Components/Admin/Header/AdminHeader";
import Dashboard from "./Components/CommonComponents/Dashboard";
import Clients from "./Components/Admin/Batch/Settings/Clients";
import UserTable from "./Components/Admin/Batch/Users/UserTable";
import MockTestPageProvider from "./context/MockTestPageContext";
import { UserReportProvider } from "./context/UserReportContext";
import ParentInfo from "./Components/Student/Profile/ParentInfo";
import Settings from "./Components/Admin/Batch/Settings/Settings";
import IssueTable from "./Components/Admin/Batch/issue/IssueTable";
import CodingPage from "./Components/Student/CodingPage/CodingPage";
import AcademicWork from "./Components/Student/Profile/AcademicWork";
import RegistrationPage from "./Components/Student/RegistrationPage";
import RightMain from "./Components/Student/Home/RightMain/RightMain";
import EducationForm from "./Components/Student/Profile/EducationForm";
import React, { useState, useRef, useContext, useEffect } from "react";
import TestPage from "./Components/Student/Home/Mcq/TestPage/TestPage";
import StudentTestPageProvider from "./context/StudentTestPageContext";
import DashBoardForm from "./Components/Admin/Dashboard/DashBoardForm";
import Loading from "./Components/Notification-Loading/Loading/Loading";
import Attendance from "./Components/Admin/Batch/Attendance/Attendance";
import LeftNavBar from "./Components/Student/Home/LeftNavBar/LeftNavBar";
import DashBoardTable from "./Components/Admin/Dashboard/DashBoardTable";
import AdminDashBoard from "./Components/Admin/Dashboard/AdminDashBoard";
import SocialProfiles from "./Components/Student/Profile/SocialProfiles";
import CompanyProfile from "./Components/Student/Profile/CompanyProfile";
import ContentEntry from "./Components/Content/ContentEntry/ContentEntry";
import AssignmentForm from "./Components/Admin/Assignment/AssignmentForm";
import BatchDetails from "./Components/Admin/Batch/Settings/BatchDetails";
import SkillMapping from "./Components/Admin/Batch/Settings/SkillMapping";
import MockTestPage from "./Components/Student/MockTestPage/MockTestPage";
import SharedTopics from "./Components/Admin/QuestionReport/SharedTopics";
import QuestionsEntry from "./Components/Admin/QuestionEntry/QuestionEntry";
import BatchTrainers from "./Components/Admin/Batch/Settings/BatchTrainers";
import LandingPage from "./Components/Student/Home/LandingPage/LandingPage";
import PersonalInfoForm from "./Components/Student/Profile/PersonalInfoForm";
import MockTestList from "./Components/Admin/MockPage/MockTest/MockTestList";
import QuestionReport from "./Components/Admin/QuestionReport/QuestionReport";
import LibraryDetails from "./Components/Admin/QuestionReport/LibraryDetails";
import MockTestLibrary from "./Components/Student/MockTestPage/MockTestLibrary";
import MockTestResults from "./Components/Student/MockTestPage/MockTestResults";
import SandPackEditor from "./Components/Student/AssignmentPage/SandPackEditor";
import Attandaceonline from "./Components/Admin/Batch/Attendance/Attandaceonline";
import AdminLearningPath from "./Components/Admin/Learningpath/AdminLearningPath";
import StudentTestPage from "./Components/Student/StudentTestPage/StudentTestPage";
import Attandaceoffline from "./Components/Admin/Batch/Attendance/Attandaceoffline";
import FloatingActionButton from "./Components/CommonComponents/FloatingActionButton";
import CreateSession from "./Components/Admin/Batch/Attendance/CreateSession/CreateSession";
import MockLearningPath from "./Components/Admin/MockPage/MockLearningPath/MockLearningPath";
import useRequestPermissionForNotification from "./Hooks/useRequestPermissionForNotification";
import UserTimeSpentReport from "./Components/UserReport/UserTimeSpentReport/UserTimeSpentReport";
import MockLearningPathList from "./Components/Admin/MockPage/MockLearningPath/MockLearningPathList";
import AttendanceReport from "./Components/Admin/Batch/Attendance/AttendanceReport/AttendanceReport";
import UserTestResultReportTable from "./Components/UserReport/UserTestResultReport/UserTestResultReportTable";

import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";

window.env = {
  token: localStorage.getItem("tokenKey"),
  REACT_APP_NODE_API: process.env.REACT_APP_NODE_API,
};

function App() {
  const {
    msg,
    lpId,
    show,
    title,
    libId,
    setMsg,
    status,
    setAuth,
    setLpId,
    setShow,
    setTitle,
    isLoaded,
    handleMcq,
    learnerId,
    setStatus,
    lpConfigId,
    handleClose,
    setIsLoaded,
    contentData,
    learnerNotes,
    isFullScreen,
    handleContent,
    isstableState,
    setLoginStatus,
    isAddSubmission,
    selectedQuestion,
    setIsStableState,
    LoadDeviceDetails,
    takeMcqAndDesTest,
    openSandpackEditor,
    notificationPayload,
    stablePageforBackbtn,
    stableTextforBackbtn,
    setTakeMcqAndDesTest,
    setOpenSandpackEditor,
    stableStateforBackbtn,
    setNotificationPayload,
    setStablePageforBackbtn,
    setStableTextforBackbtn,
    setStableStateforBackbtn,

    //content

    selectque,
    isqueReport,
    setselectque,
    setisqueReport,
    selectedLibType,
    tableShowStatus,
    setSelectedLibType,
    setTableShowStatus,

    // content end
  } = useContext(AlertContext);

  const { requestPermissionForNotification } =
    useRequestPermissionForNotification();

  let isAdmin;

  const getRole = () => {
    isAdmin = getDataFromStorage("role") === "learner" ? false : true;

    return isAdmin;
  };

  isAdmin = getRole();

  let scrpos = useRef(0);

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const showFloatingActionBtn = ["/landingpage"].includes(pathname);

  let [performance, setperformance] = useState(false);

  const [report, setReport] = useState({});
  const [assignQnDetailsForLearner, setAssignQnDetailsForLearner] = useState(
    {}
  );

  const handleNavigate = () => {
    navigate("/batch");
  };

  useEffect(() => {
    setAuth(localStorage.getItem("tokenKey"));
    LoadDeviceDetails();
  }, []);

  useEffect(() => {
    if (pathname === "/batch") changeFavicon("/favicon.ico");
    else return;
  }, [pathname]);

  const quereport = (que) => {
    setselectque(que);

    if (que.type === constants.libTypeIds.ASSIGNMENT)
      navigate("/library/assignment");
    else navigate("/library/questions");
  };

  const querepor = (que) => {
    setselectque(que);

    try {
      if (que.type === constants.libTypeIds.ASSIGNMENT)
        navigate("/library/assignment");
      else if (que.description === null) {
        navigate("/library/questions");
      } else if (typeof JSON.parse(que.description) === "object") {
        navigate("/library/contententry");
      } else {
        navigate("/library/contententry");
      }
    } catch (err) {
      navigate("/library/questions");
    }
  };

  const setscrpos = (val) => {
    scrpos.current = val;
  };

  const assignQuestionToLearner = (qnData) => {
    setAssignQnDetailsForLearner(qnData);
    navigate("/coding");
  };

  const disableRightClick = (e) => {
    if (!isAdmin) {
      e.preventDefault();
    }
  };

  return (
    <div>
      <CustomView
        condition={browserName !== "Chrome" && browserName !== "Electron"}
      >
        <BrowserSupport />
      </CustomView>
      <CustomView
        condition={browserName === "Chrome" || browserName === "Electron"}
      >
        <div
          onContextMenu={disableRightClick}
          className={isLoaded ? "appOnBlur" : "App"}
        >
          <Notify />
          {/* {isLoaded && <Loading />} */}
          {(getDataFromStorage("role") === constants.Roles.learner ||
            getDataFromStorage("role") === constants.Roles.parent ||
            (getDataFromStorage("role") === constants.Roles.admin &&
              pathname === "/sandpack")) &&
            localStorage.getItem("tokenKey") &&
            pathname !== "/home" &&
            (pathname === "/sandpack" ? (
              <Headers hideTimer={["/coding", "/mcq"]} />
            ) : (
              !isFullScreen && <Header />
            ))}

          {notificationPayload.open && (
            <PushNotification
              notificationData={notificationPayload}
              setNotificationData={setNotificationPayload}
            />
          )}

          {(getDataFromStorage("role") === constants.Roles.admin ||
            getDataFromStorage("role") === constants.Roles.trainer ||
            getDataFromStorage("role") === constants.Roles.hr) &&
          localStorage.getItem("tokenKey") &&
          pathname !== "/coding" &&
          pathname !== "/assignment" &&
          pathname !== "/mcq" &&
          pathname !== "/sandpack" ? (
            <AdminHeader
              status={status}
              isAdmin={isAdmin}
              hideTimer={["/coding"]}
              setselectque={setselectque}
              setLoginStatus={setLoginStatus}
              setSelectedLibType={setSelectedLibType}
              setTableShowStatus={setTableShowStatus}
              showUserName={[
                "/mcq",
                "/home",
                "/users",
                "/report",
                "/coding",
                "/batches",
                "/library/questions",
                "/library/questionlist",
              ]}
            />
          ) : (
            isAdmin &&
            (pathname === "/coding" ||
              pathname === "/mcq" ||
              pathname === "/assignment") && <Header />
          )}

          {showFloatingActionBtn && !isAdmin && <FloatingActionButton />}

          <Routes>
            <Route path="/" element={<Layout />}>
              <Route
                path="/"
                element={
                  <LoginPage
                    show={show}
                    setShow={setShow}
                    handleClose={handleClose}
                  />
                }
              />

              {/* Protected Routes */}

              <Route element={<PresistLogin />}>
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={[
                        constants.Roles.admin,
                        constants.Roles.trainer,
                        constants.Roles.hr,
                      ]}
                    />
                  }
                >
                  {getDataFromStorage("role") !== constants.Roles.hr && (
                    <>
                      {" "}
                      <Route
                        path="/library"
                        element={
                          <LibraryProvider>
                            <LibraryDetails
                              que={tableShowStatus !== 1 ? quereport : querepor}
                              setisqueReport={setisqueReport}
                            />
                          </LibraryProvider>
                        }
                      >
                        <Route
                          index
                          path="/library/:id/shared"
                          element={<SharedTopics />}
                        />
                        <Route
                          path="/library/:id/questionlist"
                          element={
                            <QuestionReport
                              isstableState={isstableState}
                              setisqueReport={setisqueReport}
                              selectedLibType={selectedLibType}
                              tableShowStatus={tableShowStatus}
                              setIsStableState={setIsStableState}
                              stablePageforBackbtn={stablePageforBackbtn}
                              stableTextforBackbtn={stableTextforBackbtn}
                              stableStateforBackbtn={stableStateforBackbtn}
                              setStableTextforBackbtn={setStableTextforBackbtn}
                              setStablePageforBackbtn={setStablePageforBackbtn}
                              que={tableShowStatus !== 1 ? quereport : querepor}
                              setStableStateforBackbtn={
                                setStableStateforBackbtn
                              }
                            />
                          }
                        />
                        <Route
                          path="/library/contententry"
                          element={
                            <ContentEntry
                              selectque={selectque}
                              quereport={quereport}
                              isqueReport={isqueReport}
                              setselectque={setselectque}
                              setisqueReport={setisqueReport}
                              tableShowStatus={tableShowStatus}
                              selectedLibType={selectedLibType}
                            />
                          }
                        />
                        <Route
                          path="/library/testlist"
                          element={
                            <TestList
                              setselectque={setselectque}
                              setTableShowStatus={setTableShowStatus}
                            />
                          }
                        />
                        <Route
                          path="/library/questions"
                          element={
                            <QuestionsEntry
                              msg={msg}
                              show={show}
                              title={title}
                              setMsg={setMsg}
                              setShow={setShow}
                              setTitle={setTitle}
                              isLoaded={isLoaded}
                              quereport={quereport}
                              selectque={selectque}
                              handleClose={handleClose}
                              isqueReport={isqueReport}
                              setIsLoaded={setIsLoaded}
                              setselectque={setselectque}
                              isstableState={isstableState}
                              setisqueReport={setisqueReport}
                              tableShowStatus={tableShowStatus}
                              selectedLibType={selectedLibType}
                              setIsStableState={setIsStableState}
                            />
                          }
                        />
                        <Route
                          path="/library/assignment"
                          element={
                            <AssignmentForm
                              selectque={selectque}
                              isqueReport={isqueReport}
                              setselectque={setselectque}
                              setisqueReport={setisqueReport}
                            />
                          }
                        />
                      </Route>
                    </>
                  )}

                  <Route path="/batches" element={<Batches />} />
                  <Route path="/batches/:id/" element={<Batch />}>
                    {getDataFromStorage("role") !== constants.Roles.hr && (
                      <>
                        <Route
                          element={<Settings />}
                          path="/batches/:id/settings"
                        >
                          <Route
                            index
                            path="details"
                            element={<BatchDetails />}
                          />
                          <Route path="skillmap" element={<SkillMapping />} />
                          <Route path="trainers" element={<BatchTrainers />} />
                          <Route path="clients" element={<Clients />} />
                        </Route>
                        <Route
                          path="/batches/:id/courses"
                          element={
                            <Courses
                              quereport={querepor}
                              setisqueReport={setisqueReport}
                              setTableShowStatus={setTableShowStatus}
                            />
                          }
                        />
                        <Route
                          element={<Attendance />}
                          path="/batches/:id/event"
                        />
                        <Route
                          element={<Attandaceonline />}
                          path="/batches/:id/event/online"
                        />
                        <Route
                          element={<Attandaceoffline />}
                          path="/batches/:id/event/offline"
                        />
                        <Route
                          element={<CreateSession />}
                          path="/batches/:id/event/add"
                        />
                        <Route
                          element={<AttendanceReport />}
                          path="/batches/:id/event/record"
                        />
                        <Route
                          element={<IssueTable />}
                          path="/batches/:id/issue"
                        />
                      </>
                    )}
                    <Route
                      index
                      path="/batches/:id/users"
                      element={
                        <UserReportProvider>
                          <UserTable />
                        </UserReportProvider>
                      }
                    />

                    <Route
                      path="/batches/:id/report"
                      element={
                        <Report
                          lpid={lpId}
                          report={report}
                          scrpos={scrpos}
                          setLpId={setLpId}
                          navigate={navigate}
                          handleMcq={handleMcq}
                          setReport={setReport}
                          setStatus={setStatus}
                          setscrpos={setscrpos}
                          performance={performance}
                          setIsLoaded={setIsLoaded}
                          handleContent={handleContent}
                          setperformance={setperformance}
                          selectedQuestion={selectedQuestion}
                          assignQuestionToLearner={assignQuestionToLearner}
                        />
                      }
                    />
                  </Route>

                  {getDataFromStorage("role") !== constants.Roles.hr && (
                    <>
                      {" "}
                      <Route
                        path="/learningPath"
                        element={
                          <AdminLearningPath
                            quereport={querepor}
                            setisqueReport={setisqueReport}
                            setTableShowStatus={setTableShowStatus}
                          />
                        }
                      />
                      <Route
                        path="/mcqadmin"
                        element={<TestPage isAdmin={true} />}
                      />
                      <Route
                        path="/profile/companyinfo"
                        exact
                        element={<CompanyProfile />}
                      />
                    </>
                  )}
                  <Route
                    path="/"
                    element={<Navigate replace to="/batches" />}
                  />
                </Route>

                <Route
                  element={
                    <RequireAuth
                      allowedRoles={[
                        constants.Roles.learner,
                        constants.Roles.parent,
                      ]}
                    />
                  }
                >
                  <Route path="/batch" element={<LandingPage />} />
                  <Route
                    path="/landingpage"
                    element={
                      <UserReportProvider>
                        <Dashboard />
                      </UserReportProvider>
                    }
                  >
                    <Route path="timespent" element={<UserTimeSpentReport />} />
                    <Route
                      path="testresults"
                      element={<UserTestResultReportTable />}
                    />
                  </Route>
                  <Route path="/landingpage" element={<Dashboard />} />
                  <Route path="/" element={<Navigate replace to="/batch" />} />
                </Route>

                <Route
                  element={
                    <RequireAuth allowedRoles={[constants.Roles.learner]} />
                  }
                >
                  <Route path="/home" element={<LeftNavBar />} />
                  <Route
                    path="/content"
                    element={
                      <ContentEntry
                        selectque={selectque}
                        quereport={quereport}
                        isqueReport={isqueReport}
                        setselectque={setselectque}
                        setisqueReport={setisqueReport}
                        tableShowStatus={tableShowStatus}
                        selectedLibType={selectedLibType}
                      />
                    }
                  />

                  <Route
                    path="/newcontent"
                    element={
                      <Content
                        lpId={lpId}
                        status={status}
                        setLpId={setLpId}
                        lpConfigId={lpConfigId}
                        contentData={contentData}
                        handleNavigate={handleNavigate}
                      />
                    }
                  />

                  <Route path="/registration" element={<RegistrationPage />} />
                  <Route path="/" element={<Navigate replace to="/batch" />} />
                </Route>
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={[
                        constants.Roles.admin,
                        constants.Roles.learner,
                        constants.Roles.trainer,
                      ]}
                    />
                  }
                >
                  <Route path="/coding" element={<CodingPage />} />
                  <Route path="/assignment" element={<RightMain />} />
                  <Route path="/instruction" element={<RightMain />} />
                  <Route
                    path="/mock"
                    element={
                      <MockTestPageProvider>
                        <MockTestPage />
                      </MockTestPageProvider>
                    }
                  >
                    <Route path="results" element={<MockTestResults />} />
                    <Route
                      exact
                      index
                      path="library"
                      element={<MockTestLibrary />}
                    />
                    <Route
                      path="test"
                      element={
                        <MockTestList
                          setselectque={setselectque}
                          setTableShowStatus={setTableShowStatus}
                        />
                      }
                    />
                    <Route
                      path="learningpath"
                      element={<MockLearningPathList />}
                    />
                    <Route
                      path="create/learningpath"
                      element={<MockLearningPath />}
                    />
                  </Route>

                  <Route
                    path="/sandpack"
                    element={
                      <SandPackEditor
                        status={status}
                        isqueReport={false}
                        isAddSubmission={isAddSubmission}
                        selectedTemplate={contentData.template}
                        openSandpackEditor={openSandpackEditor}
                        setOpenSandpackEditor={setOpenSandpackEditor}
                        learnerId
                        libDetails={{
                          id: libId,
                          learnerId,
                          lpId: lpId,
                          lpConfigId: lpConfigId,
                          learnerNotes: learnerNotes,
                        }}
                      />
                    }
                  />

                  <Route
                    path="/profile"
                    element={
                      <ProfileProvider>
                        <Profile />
                      </ProfileProvider>
                    }
                  >
                    <Route
                      exact
                      index
                      path="personalinfo"
                      element={
                        <PersonalInfoForm role={getDataFromStorage("role")} />
                      }
                    />
                    <Route
                      exact
                      index
                      path="parentinfo"
                      element={<ParentInfo role={getDataFromStorage("role")} />}
                    />
                    <Route
                      path="education"
                      element={
                        <EducationForm role={getDataFromStorage("role")} />
                      }
                    />
                    <Route
                      path="academic"
                      element={
                        <AcademicWork role={getDataFromStorage("role")} />
                      }
                    />
                    <Route
                      path="socialprofile"
                      element={
                        <SocialProfiles
                          role={getDataFromStorage("role")}
                          learnerId={getDataFromStorage("learnerid")}
                        />
                      }
                    />
                    <Route
                      path="viewresume"
                      element={<Resume role={getDataFromStorage("role")} />}
                    />
                  </Route>

                  {takeMcqAndDesTest && (
                    <Route
                      path="/mcq"
                      element={
                        <StudentTestPageProvider>
                          <StudentTestPage />
                        </StudentTestPageProvider>
                      }
                    />
                  )}
                </Route>
                <Route
                  element={
                    <RequireAuth
                      allowedRoles={[
                        constants.Roles.hr,
                        constants.Roles.admin,
                        constants.Roles.trainer,
                      ]}
                    />
                  }
                >
                  <Route path="/dashboards" element={<DashBoardTable />} />
                  <Route
                    path="/dashboard"
                    element={
                      getDataFromStorage("role") == constants.Roles.hr ? (
                        <AdminDashBoard />
                      ) : (
                        <div style={{ marginTop: "54px" }}>
                          <AdminDashBoard />
                        </div>
                      )
                    }
                  />
                  <Route path="/dashboardadd" element={<DashBoardForm />} />
                  <Route
                    path="/"
                    element={<Navigate replace to="/dashboards" />}
                  />
                </Route>
              </Route>
            </Route>
            <Route path="*" element={<Error404 />} />
          </Routes>
        </div>
      </CustomView>
    </div>
  );
}

export default App;
