import { nanoid } from "nanoid";
import Select from "react-select";
import "quill/dist/quill.snow.css";
import "./CodingQuestionEntry.scss";
import { Input, Table } from "antd";
import Io from "../TestCases/Io/Io";
import Form from "react-bootstrap/Form";
import "react-quill/dist/quill.snow.css";
import Modules from "../Modules/Modules";
import { Editor } from "@tinymce/tinymce-react";
import Complexity from "../Complexity/Complexity";
import CreatableSelect from "react-select/creatable";
import LanguageInput from "./LanguageInput/LanguageInput";
import AlertContext from "../../../../context/AlertContext";
import React, { useState, useEffect, useContext } from "react";
import constants, { complexityid } from "../../../../constants";
import useAxiosPrivate from "../../../../Hooks/useAxiosPrivate";
import Notify from "../../../Notification-Loading/Notify/Notify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useAddOrUpdateLp from "../../../../Hooks/useAddOrUpdateLp";
import Loading from "../../../Notification-Loading/Loading/Loading";
import CodingQuestionEntryHooks from "./Hooks/CodingQuestionEntryHooks";
import { faArrowLeft, faTrashCan } from "@fortawesome/free-solid-svg-icons";

import {
  getDataFromStorage,
  generateBoilerPlateCCode,
  generateBoilerPlateJSCode,
  generateBoilerPlateCPPCode,
  generateBoilerPlateJavaCode,
  generateBoilerPlatePythonCode,
} from "../../../../util";

export default function CodingQuestionEntry(Props) {
  const {
    msg,
    show,
    title,
    setMsg,
    lpData2,
    setShow,
    setTitle,
    isUnAuth,
    isFromLp,
    fromList,
    navigate,
    isLoaded,
    setLpData2,
    isFixedQn,
    resultCode,
    setIsLoaded,
    setIsUnAuth,
    handleClose,
    isFromBatch,
    setFromList,
    setIsFromLp,
    setResultCode,
    isOldLpDetail,
    handleUnAuthAlert,
    handleNavigateToLpAfterAddTest,
  } = useContext(AlertContext);
  console.log("props",Props)

  const {
    inva,
    cuin,
    role,
    init,
    isTest,
    setRole,
    setInVa,
    testMsg,
    setInit,
    evalRule,
    isChange,
    setIsTest,
    editorRef,
    langLabel,
    isChecked,
    varTypeRef,
    masterData,
    setIsChange,
    settingCuin,
    setEvalRule,
    setLangLabel,
    inputVarsObj,
    setIsChecked,
    setInVaTypes,
    languageData,
    setMasterData,
    isNoTestCases,
    setIoVarsData,
    languagesData,
    handleNavigate,
    setLanguageData,
    setInputVarsObj,
    evaluationRules,
    alreadySelected,
    isSelectedTopic,
    setIsNoTestCases,
    setLanguagesData,
    enableTestButton,
    enableSaveButton,
    fromEditQuestion,
    showNotification,
    isOptionDisabled,
    handleNoTestCases,
    setEvaluationRules,
    setShowNotification,
    setFromEditQuestion,
    setEnableSaveButton,
    setLibTypeIdByParent,
    languageSelectedList,
    customStylesForTopic,
    isSelectedComplexity,
    handleBackFromQuestion,
    setLibTypeNameByParent,
    handleEvaluationChange,
    setLanguageSelectedList,
    cancelClikedInConfirmBox,
    languagesOfPassedTestCases,
    setLanguagesOfPassedTestCases,
    removeLanguageFromPassedTestCases,
  } = CodingQuestionEntryHooks();

  const [count, setCount] = useState(1);
  const [reset, setReset] = useState(false);
  const [showAdd, setShowAdd] = useState(false);

  const axios = useAxiosPrivate();
  const { handleAddOrUpdateLp } = useAddOrUpdateLp();
  
  let [qcodes, setQcodes] = useState([]);
  const [topic, setTopic] = useState(null);
  const [topics, setTopics] = useState([]);
  const [level, setLevel] = useState(null);
  const [handleadd,setHandleadd]=useState(false);
  const [complexities, setComplexities] = useState([]);

  const setInVafun = (inpVars) => {
    setInputVarsObj(inpVars);

    const inputVar = [];
    const dataTypes = [];

    for (const obj of inpVars) {
      inputVar.push(obj.varname);
      dataTypes.push(obj.vartype);
    }

    setInVa(inputVar);
    setInVaTypes(dataTypes);

    setUserInp({
      ...userInput,
      ["inputvars"]: inputVar.toString(),
      ["datatypes"]: dataTypes.toString(),
    });
  };

  const handleAdd = (e) => {
    e.preventDefault();

    if(topicAndComplexity.map(item=>item.name).includes(topic?.name)){
      setShow(true);
      setTitle("Warning !");
      setMsg(`Topic "${topic?.name}" already added!`);

      return;
    }

    // setShowAdd(false);

    let newData;

    newData = {
      key: count + 1,
      topicname: topic?.name,
      complexity: level?.name,
    };

    let topicData;

    topicData = {
      key: count +1,
      type: topic?.type,
      name: topic?.name,
      value: topic?.value,
      complexity: level?.value,
    };
    setCount((count) => count + 1);
    setTopicAndCompexity([...topicAndComplexity, topicData]);
    setDataTopicAndComplexity([...dataTopicAndComplexity, newData]);
    setHandleadd(true);
    setReset(true);
  };

  // Props.isqueReport
  // ? Props.inputDataSource.filter(
  //     (obj) => obj.varname !== "" || obj.vartype !== null
  //   )

  const [dataTopicAndComplexity, setDataTopicAndComplexity] = useState([]);

  useEffect(() => {
    if (
      Props.isqueReport &&
      Props.tableShowStatus === constants.libTypeIds.CODING_CHALLENGE
    ) {
      const topicDetails = JSON.parse(Props.selectque.topicData).map(
        (q) => ({
          topicid: q.topicid,
          topicname: topics.find((t) => t.value === q.topicid)?.label || "",
          complexityid: q.complexityid,
        })
      );

      const resultArray = topicDetails.map((qd, index) => ({
        key: index + 1,
        topicname: qd.topicname,
        complexity:
          complexities.find((c) => c.value === qd.complexityid)?.label || "",
      }));

      setDataTopicAndComplexity(resultArray);
      setCount(resultArray.length);


      const topicDetailstoDb = JSON.parse(Props.selectque.topicData).map(
        (q,index) => ({
          key:index+1,
          type:"id",
          value: q.topicid,
          name: topics.find((t) => t.value === q.topicid)?.label || "",
          complexity: q.complexityid,
        })
      );

      setTopicAndCompexity(topicDetailstoDb);
    }
  }, [topics, complexities]);

  // embed Links

  const [embedLinksList, setEmbedLinksList] = useState(
    Props.isqueReport ? Props.selectque.embedlinks : []
  );

  const [makeStableState, setMakeStableState] = useState(false);

  let [userInput, setUserInp] = useState(
    Props.isqueReport &&
      Props.tableShowStatus === constants.libTypeIds.CODING_CHALLENGE
      ? {
          name: Props.selectque.qdata.name,
          topicData: Props.selectque.topicData,
          ansexp: Props.selectque.qdata.ansexp,
          inputvars: Props.selectque.cc.inputvars,
          datatypes: Props.selectque.cc.datatypes,
          evalrules: Props.selectque.cc.evalrules,
          maxtime: Props.selectque.qdata.timelimit,
          returntype: Props.selectque.cc.returntype,
          questionid: Props.selectque.qdata.questionid,
          functionname: Props.selectque.cc.functionname,
          description: Props.selectque.qdata.description,
          autoevaluate: Props.selectque.qdata.autoevaluate,
          exampleinput: Props.selectque.io
          ?.filter((data) => data.isexample === 1)
          .map((filteredinput) => filteredinput.input)
          .join("\r\n"),
          exampleoutput: Props.selectque.io
          ?.filter((data) => data.isexample === 1)
          .map((filteredoutput) => filteredoutput.output)
          .join("\r\n"),

          // topic: Props.selectque.qdata.topic,
          // complexity: Props.selectque.qdata.complexity,
        }
      : Props.tableShowStatus === constants.libTypeIds.CODING_CHALLENGE
      ? { autoevaluate: 1 }
      : {}
  );

  const [topicAndComplexity, setTopicAndCompexity] = useState([]);

  let parsedReturnType;

  const returnTypes = [
    { value: "int", label: "Int" },
    { value: "float", label: "Float" },
    { value: "string", label: "String" },
    { value: "boolean", label: "Boolean" },
    { value: "array-1int", label: "Array 1D Number" },
    { value: "array-2int", label: "Array 2D Number" },
    { value: "array-1string", label: "Array 1D String" },
    { value: "array-2string", label: "Array 2D String" },
  ];

  try {
    parsedReturnType = userInput.returntype && JSON.parse(userInput.returntype);
  } catch (error) {
    // If parsing as JSON fails, it's a regular string

    parsedReturnType = userInput.returntype && userInput.returntype;
  }

  const [currentType, setCurrentType] = useState(
    Props.isqueReport
      ? parsedReturnType && typeof parsedReturnType === "object"
        ? parsedReturnType
        : returnTypes.find((obj) => obj.value === parsedReturnType)
      : {}
  );

  const handleReturnChange = (e) => {
    if (e.hasOwnProperty("value")) {
      setCurrentType(e);
    }
  };

  let response;

  let [Errors, setErrors] = useState({
    url: "",
    marks: "",
    maxtime: "",
    exampleio: "",
    testcaseio: "",
    oursolution: "",
    boilerplate: "",
    language: "java",
    executioncode: "",
    difficulty: "easy",
    problemstatement: "",
  });

  const inputVars = userInput.inputvars?.split(",");
  const dataTypes = userInput.datatypes?.split(",");

  const inputDataSource = [];

  let obj;

  for (let i = 0; i < inputVars?.length; i++) {
    obj = {
      key: i + 2,
      varname: inputVars[i],
      vartype: dataTypes ? dataTypes[i] : null,
    };

    inputDataSource.push(obj);
  }

  const handleDelete = (key) => {
    const newData = dataTopicAndComplexity.filter((item) => item.key !== key);

    const topicandcomplexityData = topicAndComplexity.filter(
      (item) => item.key !== key
    );

    //Props.setInVatypes(newData);

    setTopicAndCompexity(topicandcomplexityData);
    setDataTopicAndComplexity(newData);
  };

  const defaultColumns = [
    {
      title: "Topic",
      width: "100px",
      editable: false,
      dataIndex: "topicname",
    },
    {
      width: "100px",
      editable: false,
      title: "Complexity",
      dataIndex: "complexity",
    },
    {
      width: "100px",
      title: "Action",
      dataIndex: "Action",
      render: (_, record) =>
        dataTopicAndComplexity.length >= 1 ? (
          <button
            onClick={() => handleDelete(record.key)}
            style={{ border: "none", background: "none" }}
          >
            <FontAwesomeIcon className="faTrashCan" icon={faTrashCan} />
          </button>
        ) : null,
    },
  ];

  const handleSave = (row) => {
    const newData = [...dataTopicAndComplexity];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];

    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataTopicAndComplexity(newData);
  };

  const Columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        handleSave,
        title: col.title,
        editable: col.editable,
        dataIndex: col.dataIndex,
      }),
    };
  });

  const handleToggleChange = (event) => {
    const target = event.target;

    let value;

    const name = target.name;

    let checked = event.target.checked;

    if (checked === false) {
      value = 0;
    } else {
      value = 1;
    }

    setUserInp({ ...userInput, [name]: value, result: "", success: true });
  };

  const handleInputChange = (event, name) => {
    if (event.name === "topicname") {
      let tempUsrInp = userInput;

      if (tempUsrInp.hasOwnProperty("topic")) {
        delete tempUsrInp.topic;
      }

      setUserInp({
        result: "",
        ...tempUsrInp,
        success: true
      });
    } 
    
    else if (event.name === "topic") {
      let tempUsrInp = userInput;

      if (tempUsrInp.hasOwnProperty("topicname")) {
        delete tempUsrInp.topicname;
      }

      setUserInp({
        result: "",
        success: true,
        ...tempUsrInp
      });
    } 
    else if (name === "complexity") {
      setUserInp({
        result: "",
        ...userInput,
        success: true,
        [name]: event.value,
      });
      setLevel({ name: event.label, value: event.value });
    } else if (name === "description") {
      setUserInp({
        result: "",
        ...userInput,
        success: true,
        ["description"]: event,
      });
    } else if (name === "ansexp") {
      setUserInp({
        result: "",
        ...userInput,
        success: true,
        ["ansexp"]: event,
      });
    } else {
      const target = event.target;

      let value = event.target.value;

      const name = target.name;

      if (name === "maxtime") {
        if (Props.tableShowStatus === constants.libTypeIds.ASSIGNMENT) {
          value = value * 60;
        }

        if (isNaN(parseInt(value))) {
          setErrors({
            ...Errors,
            success: false,
            maxtime: "Please enter only Numbers",
          });
        } else {
          setErrors({ ...Errors, maxtime: "", success: true });
        }
      }

      if (name === "marks") {
        if (isNaN(parseInt(value))) {
          setErrors({
            ...Errors,
            success: false,
            marks: "Please enter only Numbers",
          });
        } else {
          setErrors({ ...Errors, marks: "", success: true });
        }
      }

      setUserInp({ ...userInput, [name]: value, result: "", success: true });
    }

    /* stable the state from qn report */

    if (Props.isqueReport && !makeStableState) {
      setMakeStableState(true);
    } else if (Props.isqueReport && makeStableState) {
      Props.setIsStableState(false);
    }
  };

  // useEffect(() => {
  //   if (
  //     topic !== "" ||
  //     topic !== null ||
  //     topic !== undefined
  //   ) {
  //     if (
  //       level !== "" ||
  //       level !== null ||
  //       level !== undefined
  //     ) {
  //       setShowAdd(true);
  //     }
  //   }
  // }, [level, topic, userInput.topicname]);

  const enableTestandSaveButton = (languageName, type, selectedlist) => {
    let languageList = languagesOfPassedTestCases;

    if (type === "remove") {
      if (languageList.indexOf(languageName) >= 0) {
        languageList.splice(languageList.indexOf(languageName), 1);
      }
    }

    if (type === "add") {
      if (languageList.indexOf(languageName) < 0) {
        languageList.push(languageName);
      }
    }

    let langLabelArr = langLabel.map((lan) => lan.label);

    if (fromEditQuestion) {
      langLabelArr = languageData
        .filter((options) => Object.keys(languageKeys).includes(options.label))
        .map((val) => val.label);
    }

    if (langLabelArr.length > 0 && languageList.length > 0) {
      setEnableSaveButton(true);
    }

    setLanguagesOfPassedTestCases(languageList);
  };

  const handleSubmit = (data, languageName, languageid) => {
    let url = "";
    let data1 = data;
    let questionsData;
    let EvalRules = evalRule.map((rule) => rule.value).join(", ");

    if (languageName.toLowerCase() === "javascript") {
      setIsLoaded(true);
      questionsData = JSON.stringify({
        testCases: ioData,
        evalrules: EvalRules,
        learnerid: getDataFromStorage("learnerid"),
        type: isNoTestCases ? "runcode" : "testcode",
        solutionCode: data1[languageName].solutionCode,
        executionCode: data1[languageName].executionCode,
        returntype: currentType
          ? typeof currentType === "object"
            ? currentType.value
            : currentType
          : null,
      });
      url = process.env.REACT_APP_NODE_API + "node/learner/code/js/test";
    } else if (languageName.toLowerCase() === "java") {
      setIsLoaded(true);
      questionsData = JSON.stringify({
        type: isNoTestCases ? "runcode" : "testcode",
        testCases: ioData,
        evalrules: EvalRules,
        learnerid: getDataFromStorage("learnerid"),
        solutionCode: data1[languageName].solutionCode,
        returntype: currentType
          ? typeof currentType === "object"
            ? currentType.value
            : currentType
          : null,
      });
      url = process.env.REACT_APP_CONTAINER_API + "node/learner/code/java/test";
    } else if (languageName.toLowerCase() === "python") {
      setIsLoaded(true);
      questionsData = JSON.stringify({
        testCases: ioData,
        evalrules: EvalRules,
        learnerid: getDataFromStorage("learnerid"),
        type: isNoTestCases ? "runcode" : "testcode",
        solutionCode: data1[languageName].solutionCode,
        executionCode: data1[languageName].executionCode,
        returntype: currentType
          ? typeof currentType === "object"
            ? currentType.value
            : currentType
          : null,
      });
      url =
        process.env.REACT_APP_CONTAINER_API + "node/learner/code/python/test";
    } else if (languageName.toLowerCase() === "c") {
      setIsLoaded(true);
      questionsData = JSON.stringify({
        testCases: ioData,
        evalrules: EvalRules,
        learnerid: getDataFromStorage("learnerid"),
        type: isNoTestCases ? "runcode" : "testcode",
        solutionCode: data1[languageName].solutionCode,
        executionCode: data1[languageName].executionCode,
        returntype: currentType
          ? typeof currentType === "object"
            ? currentType.value
            : currentType
          : null,
      });
      url = process.env.REACT_APP_CONTAINER_API + "node/learner/code/c/test";
    } else if (languageName.toLowerCase() === "cpp") {
      setIsLoaded(true);
      questionsData = JSON.stringify({
        testCases: ioData,
        evalrules: EvalRules,
        learnerid: getDataFromStorage("learnerid"),
        type: isNoTestCases ? "runcode" : "testcode",
        solutionCode: data1[languageName].solutionCode,
        executionCode: data1[languageName].executionCode,
        returntype: currentType
          ? typeof currentType === "object"
            ? currentType.value
            : currentType
          : null,
      });
      url = process.env.REACT_APP_CONTAINER_API + "node/learner/code/cpp/test";
    }

    axios
      .post(url, questionsData, {
        headers: {
          "Content-type": "application/json",
        },
      })
      .then((res) => {
        let parseData;

        if (typeof res.data.result === "string") {
          parseData = JSON.parse(res.data.result);
        } else {
          parseData = res.data.result;
        }

        if (parseInt(res.data.resultCode) === constants.RESULT_STATUS.SUCCESS) {
          setShow(true);
          setTitle("Success !");
          if (isNoTestCases) {
            setMsg("Output : " + res.data.message);
          } else {
            setMsg(parseData.message);
          }

          enableTestandSaveButton(languageName, "add");
        } else {
          setShow(true);
          setTitle("Error !");
          setMsg(parseData.message);
          enableTestandSaveButton(languageName, "remove");
        }

        setIsLoaded(false);
      })
      .catch((error) => {
        setIsLoaded(false);

        if (error.message.includes("403")) {
          setShow(true);
          setIsUnAuth(true);
          setTitle("Error");
          setMsg("You have been logged-out due to inactivity. Login again.");
        }
      });
  };

  const addQuestion = async (e, status) => {
    e.preventDefault();
    setIsLoaded(true);
    userInput["functionname"] = functionName;
    userInput["returntype"] = currentType?.value;
    userInput["topicData"] = topicAndComplexity;

    let mandatoryFields = [];

    if (
      userInput.name === "" ||
      userInput.name === null ||
      userInput.name === undefined
    ) {
      mandatoryFields.push("Name");
    }

    if (topicAndComplexity.length<1
    ) {
      // if (!userInput.hasOwnProperty("topicname")) {
      // }

         mandatoryFields.push("Topic");
    }

    if (
      userInput.returntype === "" ||
      userInput.returntype === null ||
      userInput.returntype === undefined
    ) {
      mandatoryFields.push("Return Type");
    }

    if (
      userInput.inputvars === "" ||
      userInput.inputvars === null ||
      userInput.inputvars === undefined
    ) {
      mandatoryFields.push("Input Parameters");
    }

    if (
      userInput.functionname === "" ||
      userInput.functionname === null ||
      userInput.functionname === undefined
    ) {
      mandatoryFields.push("Function Name");
    }

    if (
      userInput.datatypes === "" ||
      userInput.datatypes === null ||
      userInput.datatypes === undefined
    ) {
      mandatoryFields.push("datatypes");
    }

    // if (
    //   userInput.complexity === "" ||
    //   userInput.complexity === null ||
    //   userInput.complexity === undefined
    // ) {
    //   mandatoryFields.push("Complexity");
    // }

    if (
      userInput.autoevaluate === "" ||
      userInput.autoevaluate === null ||
      userInput.autoevaluate === undefined
    ) {
      mandatoryFields.push("EvaluationType");
    }

    if (mandatoryFields.length > 0 && !isNoTestCases) {
      setIsLoaded(false);
      setShow(true);
      setTitle("Warning ");
      setMsg(`Missing ${mandatoryFields.join(",")} `);

      return;
    }

    let countHiddenTestCases = 0;

    for (let i = 0; i < ioData.length; i++) {
      if (ioData[i].isExample == 0 || ioData[i].isExample == false) {
        countHiddenTestCases = countHiddenTestCases + 1;
      }
    }

    if (countHiddenTestCases < 3 && !isNoTestCases) {
      setIsLoaded(false);
      setShow(true);
      setTitle("Warning ");
      setMsg("TestCases are Not Enough Make Sure add Atleast 3");

      return;
    } else if (countHiddenTestCases > 5 && !isNoTestCases) {
      setIsLoaded(false);
      setShow(true);
      setTitle("Warning ");
      setMsg("TestCases added More than  Enough Make Sure add AtMax 5");

      return;
    }

    let questionsData;
    let form = new FormData(),
      isProceed = false;

    let temp = evalRule.map((data) => data.value);

    userInput["evalrules"] = temp.join();

    let bplateLanguages = [];

    for (let i = 0; i < Object.keys(bplateCode).length; i++) {
      if (Props.selectque.qcodes?.hasOwnProperty("Javascript")) {
        Props.selectque.qcodes["Javascript"].boilerplateCode =
          bplateCode.Javascript;
      }

      if (Props.selectque.qcodes?.hasOwnProperty("Java")) {
        Props.selectque.qcodes["Java"].boilerplateCode = bplateCode.Java;
      }

      if (Props.selectque.qcodes?.hasOwnProperty("Python")) {
        Props.selectque.qcodes["Python"].boilerplateCode = bplateCode.Python;
      }

      if (Props.selectque.qcodes?.hasOwnProperty("C")) {
        Props.selectque.qcodes["C"].boilerplateCode = bplateCode.C;
      }

      if (Props.selectque.qcodes?.hasOwnProperty("CPP")) {
        Props.selectque.qcodes["CPP"].boilerplateCode = bplateCode.CPP;
      }
    }

    for (let language in allowedLanguages) {
      if (
        bplateCode[allowedLanguages[language]]?.trim() === "" ||
        bplateCode[allowedLanguages[language]] === undefined ||
        bplateCode[allowedLanguages[language]] === null
      ) {
        bplateLanguages.push(allowedLanguages[language]);
      }
    }

    if (bplateLanguages.length > 0) {
      setIsLoaded(false);
      setShow(true);
      setTitle("Warning ");
      setMsg("Missing Boilerplate Code For " + bplateLanguages.join(","));

      return;
    }

    setUserInp({
      ...userInput,
      functionname: functionName,
      evalrules: temp.join(),
    });

    if (Object.keys(qcodes).length === 0) {
      qcodes = Props.selectque.qcodes;
    }

    if (ioData.length === 0) {
      ioData = Props.selectque.io;
    }

    questionsData = JSON.stringify({
      io: ioData,
      qcodes: qcodes,
      qdata: userInput,
      isNoTestCases: isNoTestCases,
    });

    if (embedLinksList.length > 0) {
      form.append("embedLinks", embedLinksList);
    }

    if (Props.isqueReport === true && Props.selectque.testDetails.length > 0) {
      form.append(
        "testConfigIds",
        Props.selectque.testDetails
          .map((testData) => testData.testconfigid)
          .toString()
      );
    }

    form.append("qdata", questionsData);
    form.append("libtypeid", Props.tableShowStatus);
    form.append("libtypename", Props.selectedLibType);
    isProceed = true;

    if (isProceed) {
      let urls =
        isNoTestCases && status === "add"
          ? "node/admin/wotestcase/add"
          : !isNoTestCases && status === "add"
          ? "node/admin/library/add"
          : status === "update"
          ? "node/admin/library/update"
          : null;
      axios({
        url: urls,
        data: form,
        method: "post",
        headers: {
          "Content-Type": "multipart/form-data",
        },

        // status === "add"
        //   ? "node/admin/library/add"
        //   : "node/admin/library/update",
      })
        .then((res) => {
          setIsLoaded(false);

          if (res.data.resultCode === constants.RESULT_STATUS.SUCCESS) {
            if (fromList.isFromList) {
              setFromList({ isFromList: false, libType: "" });

              let newDetails = { ...lpData2 },
                uniqueId = 1,
                libraryData = {
                  isNew: true,
                  published: 0,
                  isEdited: false,
                  title: userInput.name,
                  id: res.data.data.libid,
                  libTypeId: lpData2.libTypeId,
                  topics: [
                    {
                      topicid: userInput.topic,
                    },
                  ],
                };

              if (isOldLpDetail) libraryData.status = "new";

              for (let i = 0; i < newDetails.modules.length; i++) {
                if (newDetails.modules[i].id === newDetails.moduleId)
                  newDetails.modules[i].moduleData.push(libraryData);

                for (
                  let j = 0;
                  j < newDetails.modules[i].moduleData.length;
                  j++
                ) {
                  newDetails.modules[i].moduleData[j].moduleDataId = uniqueId;
                  newDetails.modules[i].moduleData[j].key = nanoid();
                  uniqueId++;
                }

                newDetails.modules[i].id = uniqueId;
                uniqueId++;
              }

              newDetails.isAddTest = false;
              handleNavigateToLpAfterAddTest(newDetails);
            } else if (isOldLpDetail) {
              let newDetails = JSON.parse(JSON.stringify(lpData2));

              for (let i = 0; i < newDetails.modules.length; i++) {
                if (newDetails.modules[i].id === newDetails.moduleId) {
                  for (
                    let j = 0;
                    j < newDetails.modules[i].moduleData.length;
                    j++
                  ) {
                    if (
                      newDetails.modules[i].moduleData[j] &&
                      userInput.id === newDetails.modules[i].moduleData[j].libId
                    ) {
                      newDetails.modules[i].moduleData[j].libId =
                        res.data.data.libid;
                      newDetails.modules[i].moduleData[j].title =
                        userInput.name;
                      newDetails.modules[i].moduleData[j].testDuration =
                        userInput.maxtime;
                      newDetails.modules[i].moduleData[j].moved = "yes";
                    }
                  }
                }
              }

              setLpData2(newDetails);

              return (async () => {
                try {
                  let res = await handleAddOrUpdateLp({}, newDetails.modules);

                  if (res.title === "success") {
                    setShow(true);
                    setTitle("Success");
                    setMsg(res.message);
                    handleNavigateToLpAfterAddTest(newDetails);
                  }
                } catch (err) {
                  setIsUnAuth(err.hasOwnProperty("isUnAuth"));
                  setShow(true);
                  setTitle("Error");
                  setMsg(err.message);
                }
              })();
            } else {
              setResultCode(res.data.resultCode);
              setShow(true);
              setTitle("Success");
              setMsg(
                <>
                  {res.data.message} <br />
                  {"Inserted Question id : " + res.data.resultid}
                </>
              );
            }
          } else {
            setShow(true);
            setTitle("Error");
            setMsg(res.data.message);
          }
        })
        .catch((error) => {
          setIsLoaded(false);

          if (error.message.includes("403")) {
            setShow(true);
            setTitle("Error");
            setIsUnAuth(true);
            setMsg("You have been logged-out due to inactivity. Login again.");
          }
        });
    }
  };

  let [ioData, setIoData] = useState([]);

  const saveiodata = (data, activeLanguageName) => {
    removeLanguageFromPassedTestCases(activeLanguageName);
    setIoData(data);
  };

  let languageKeys = Props.selectque.qcodes;

  const [selectCode, setSelectCode] = useState(
    Props.isqueReport
      ? Object.keys(languageKeys).map((key) => {
          return {
            label: languageKeys[key].langname,
            value: languageKeys[key].languageid,
          };
        })
      : []
  );

  let bplateObj = {};

  // useEffect(()=>{
  //   if (Props.isqueReport) {  
  //     for (let i = 0; i < selectCode.length; i++) {
  //       let langName = selectCode[i].label;
  
  //       bplateObj[langName] = Props.selectque.qcodes[langName].boilerplateCode;
  //     }
  //   }
  // },[])

  let [bplateCode, setBplateCode] = useState(
    Props.isqueReport ? bplateObj : {}
  );

  const [allowedLanguages, setAllowedLanguages] = useState(
    Props.isqueReport ? Object.keys(bplateCode) : []
  );

  const [showLang, setShowLang] = useState(Props.isqueReport ? false : true);

  let [addLanguageData, setAddLanguageData] = useState([
    {
      langId: "",
      solutionCode: "",
      executionCode: "",
      boilerplateCode: "",
    },
  ]);

  const gettingLanguagesData = (data, languageName) => {
    if (languageName !== undefined) {
      setEnableSaveButton(false);
    }

    setQcodes(data);
    setInit(true);
  };

  const handleGenerateBoilerPlate = (e) => {
    const langInputLen = inputVarsObj.length;

    let columns;
    let dataType;
    let variables;

    if (langInputLen > 0) {
      dataType = inputVarsObj.map((ip) => ip.vartype);
      variables = inputVarsObj.map((ip) => ip.varname);
      columns = inputVarsObj.map((ip) => ip.col);
    } else {
      dataType = inputDataSource.map((ip) => ip.vartype);
      variables = inputDataSource.map((ip) => ip.varname);
      columns = inputDataSource.map((ip) => parseInt(ip.col));
    }

    let BoilerPlateCode = {};

    for (let lang = 0; lang < languageSelectedList.length; lang++) {
      if (languageSelectedList[lang] === "Javascript")
        BoilerPlateCode.Javascript = generateBoilerPlateJSCode(
          functionName,
          dataType,
          variables,
          columns,
          currentType
        );
      else if (languageSelectedList[lang] === "Python")
        BoilerPlateCode.Python = generateBoilerPlatePythonCode(
          functionName,
          dataType,
          variables,
          columns,
          currentType
        );
      else if (languageSelectedList[lang] === "Java")
        BoilerPlateCode.Java = generateBoilerPlateJavaCode(
          functionName,
          dataType,
          variables,
          columns,
          currentType
        );
      else if (languageSelectedList[lang] === "C")
        BoilerPlateCode.C = generateBoilerPlateCCode(
          functionName,
          dataType,
          variables,
          columns,
          currentType
        );
      else
        BoilerPlateCode.CPP = generateBoilerPlateCPPCode(
          functionName,
          dataType,
          variables,
          columns,
          currentType
        );
    }

    setBplateCode(BoilerPlateCode);
  };

  const handleLangChange = (e) => {
    let temp = e.map((data) => data.label),
      newData;

    setAllowedLanguages(temp);

    let boilerCode = { ...bplateCode };

    for (const lang in boilerCode) {
      if (!temp.includes(lang)) {
        delete boilerCode[lang];
      }
    }

    setBplateCode(boilerCode);

    for (let i = 0; i < languagesOfPassedTestCases.length; i++) {
      if (!temp.includes(languagesOfPassedTestCases[i])) {
        if (languagesOfPassedTestCases.length > 1) {
          newData = languagesOfPassedTestCases.filter(
            (e) => e !== languagesOfPassedTestCases[i]
          );
          setLanguagesOfPassedTestCases(newData);
        } else {
          newData = languagesOfPassedTestCases.filter(
            (e) => e !== languagesOfPassedTestCases[i]
          );
          setEnableSaveButton(false);
        }

        setLanguagesOfPassedTestCases(newData);
      }
    }

    // Props.setisqueReport(false);

    setIsChange(true);
    setSelectCode(e);
    setLangLabel(e);
    setFromEditQuestion(false);
    setLanguagesData(e.map((value) => value.value));

    let tempPassed = languagesOfPassedTestCases.filter((lang) =>
      temp.includes(lang)
    );

    setLanguagesOfPassedTestCases(tempPassed);

    if (temp.length > 0 && tempPassed.length > 0) {
      setEnableSaveButton(true);
    } else {
      setEnableSaveButton(false);
    }

    setLanguageSelectedList(e.map((obj) => obj.label));

    if (e.length === 0) {
      setShowLang(true);
    } else {
      setShowLang(false);
    }
  };

  const handleBackBtn = () => {
    Props.setselectque([]);
    setIsFromLp(false);
    Props.setisqueReport(false);
    setFromList({ isFromList: false, libType: "" });

    if (isFromBatch) {
      navigate(`/batches/${sessionStorage.getItem('abatchid')}/courses`);
    } else {
      navigate("/learningpath");
    }
  };

  const handleUpdate = (value, editor) => {
    handleInputChange(value, "description");
  };

  const [functionName, setFunctionName] = useState(
    Props.isqueReport ? userInput.functionname : ""
  );

  const handleFunctionName = (event) => {
    setFunctionName(event.target.value);
  };

  const setvarstates = (data) => {
    Props.setInVatypes(data.map((ip) => ip.vartype));
    setIoVarsData(JSON.parse(JSON.stringify(data)));
    setInVaTypes(data.map((ip) => ip.vartype));
    setInVa(data.map((ip) => ip.varname));
    varTypeRef.current = data.map((ip) => ip.vartypes);
  };

  const updateTestCases = (testcase, index) => {
    let testCases = JSON.parse(Props.selectque.io[0].testCases);

    testCases[index] = testcase;
    Props.selectque.io[0].testCases = testCases.toString();
  };

  useEffect(() => {
    let role = getDataFromStorage("role");

    setRole(role);

    axios
      .get("node/admin/library/gettypes", {
        headers: {
          "Content-type": "application/json",
        },
      })
      .then((res) => {
        let json = res.data.filter(
          (data) => data.parenttype === Props.tableShowStatus
        );

        if (Props.isqueReport) {
          let selectedData = json.filter(
            (data) => data.id === Props.selectque.type
          );

          setLibTypeIdByParent(selectedData[0].id);
          setLibTypeNameByParent(selectedData[0].name);
        } else {
          setLibTypeNameByParent(json[0].name);
          setLibTypeIdByParent(json[0].id);
        }
      })
      .catch((error) => {
        if (error.message.includes("403")) {
          setShow(true);
          setTitle("Error");
          setIsUnAuth(true);
          setMsg("You have been logged-out due to inactivity. Login again.");
        }
      });
  }, []);

  useEffect(() => {
    if (
      Props.isqueReport &&
      Props.selectque.qdata.istestcases === constants.ISNOTESTCASES.FALSE
    ) {
      if (
        Props.selectque.qdata.hasOwnProperty("inputvars") &&
        Props.selectque.qdata.inputvars !== null &&
        Props.selectque.qdata.inputvars !== undefined
      ) {
        setInVa(Props.selectque.qdata.inputvars?.split(","));
      } else {
        setInVa(
          JSON.parse(Props.selectque.io[0].testCases)[0]
            .input.split("\n")
            .map((val, ind) => "input-" + (ind + 1))
        );
      }
    }

    axios
      .get("node/admin/library/gettypes", {
        headers: {
          "Content-type": "application/json",
        },
      })
      .then((res) => {
        let json = res.data.filter((data) => data.parenttype === 0);
        let newData = [];

        if (Props.fromList.isFromList) {
          for (let i = 0; i < json.length; i++) {
            if (json[i].id === Props.fromList.libType) {
              newData.push({ label: json[i].name, value: json[i].id });
            }
          }
        } else {
          newData = json.map((data) => {
            return {
              value: data.id,
              label: data.name,
            };
          });
        }
      })
      .catch((error) => {
        if (error.message.includes("403")) {
          setShow(true);
          setIsUnAuth(true);
          setTitle("Error !");
          setMsg("You have been logged-out due to inactivity. Login again.");
        }
      });

    axios
      .get("node/admin/evalrules/get", {
        headers: {
          "Content-type": "application/json",
        },
      })
      .then((res) => {
        let json = res.data;
        let evalRules = json.map((rule) => {
          return {
            value: rule.id,
            label: rule.name,
          };
        });

        setEvaluationRules(evalRules);

        let rules;

        if (Props.isqueReport) {
          rules = Props.selectque.cc.evalrules.split(",");

          let presentRules = [];

          for (let i = 0; i < rules.length; i++) {
            for (let j = 0; j < evalRules.length; j++) {
              if (Object.values(evalRules[j]).includes(parseInt(rules[i]))) {
                presentRules.push(evalRules[j]);
              }
            }
          }

          setEvalRule(presentRules);
        }
      })
      .catch((error) => {
        if (error.message.includes("403")) {
          if (error.message.includes("403")) {
            setShow(true);
            setIsUnAuth(true);
            setTitle("Error !");
            setMsg("You have been logged-out due to inactivity. Login again.");
          }
        }
      });

    axios
      .get("node/admin/language/get", {
        headers: {
          "Content-type": "application/json",
        },
      })
      .then((res) => {
        let json = res.data;
        let newLang = json.map((lang) => {
          return {
            value: lang.id,
            label: lang.name,
          };
        });

        let lData = {};

        for (let i = 0; i < newLang.length; i++) {
          lData[newLang[i].label] = {
            solutionCode: "",
            executionCode: "",
            boilerplateCode: "",
            languageId: newLang[i].value,
          };
        }

        setMasterData(lData);
        setLanguageData(newLang);
      })
      .catch((error) => {
        if (error.message.includes("403")) {
          if (error.message.includes("403")) {
            setShow(true);
            setIsUnAuth(true);
            setTitle("Error !");
            setMsg("You have been logged-out due to inactivity. Login again.");
          }
        }
      });

    if (
      Props.selectque.qcodes === undefined ||
      Props.selectque.qcodes === null
    ) {
    } else {
      setLanguageSelectedList(Object.keys(Props.selectque.qcodes));
      setQcodes(Props.selectque.qcodes);

      setIoData(
        Props.selectque.qdata.istestcases === constants.ISNOTESTCASES.FALSE &&
          JSON.parse(Props.selectque?.io[0].testCases).map((data) => {
            let aop = [];

            aop.push(data.output);

            for (let i = 0; i < data.altout.length; i++) {
              if (data.altout[0].output !== null) {
                aop.push(data.altout[i].output);
              }
            }

            return {
              output: aop,
              id: nanoid(),
              input: data.input,
              isExample: data.isExample,
            };
          })
      );
    }

    setFromEditQuestion(Props.isqueReport);
  }, []);

  useEffect(() => {
    if (Props.isqueReport) {
      if (Props.selectque.qdata.istestcases === constants.ISNOTESTCASES.TRUE) {
        setIsChecked(false);
        setIsNoTestCases(true);
      }

      for (let i = 0; i < selectCode.length; i++) {
        let langName = selectCode[i].label;
  
        bplateObj[langName] = Props.selectque.qcodes[langName].boilerplateCode;
      }

      setLanguagesData(
        Props.isqueReport
          ? Object.keys(Props.selectque.qcodes).map((key) => Props.selectque.qcodes[key].languageid)
          : []
      );

    }
  }, [Props.isqueReport]);

  useEffect(()=>{
    if(handleadd){
      setHandleadd(!handleadd);
    }
  },[handleadd])

  return (
    <div style={{ width: "1320px" }}>
      {showNotification && (
        <Notify
          title="Result"
          message={response}
          show={setShowNotification}
          onHide={cancelClikedInConfirmBox}
        />
      )}

      <Form id="identifier">
        {fromList.isFromList || isFromBatch || isOldLpDetail || isFromLp ? (
          <div style={{ textAlign: "left" }}>
            <FontAwesomeIcon
              icon={faArrowLeft}
              onClick={handleBackBtn}
              className="adminTestBackBtn"
            />
            &nbsp;
            <span className="adminTestBack" onClick={handleBackBtn}>
              {Props.tableShowStatus === constants.libTypeIds.ASSIGNMENT
                ? "ASSIGNMENT"
                : "CONTENT"}
            </span>
          </div>
        ) : (
          <div style={{ textAlign: "left" }}>
            <FontAwesomeIcon
              icon={faArrowLeft}
              className="adminTestBackBtn"
              onClick={handleBackFromQuestion}
            />
            &nbsp;
            <span className="adminTestBack" onClick={handleBackFromQuestion}>
              {" "}
              Back{" "}
            </span>
          </div>
        )}
        {userInput.success && <p className="ccErrors">{userInput.result}</p>}
        {!userInput.success && <h3>{userInput.result}</h3>}
        <br />
        <Modules
          reset={reset}
          topic={topic}
          topics={topics}
          setTopic={setTopic}
          fromList={fromList}
          selectmodule={null}
          isFromLp={isFromLp}
          handleadd={handleadd}
          setTopics={setTopics}
          isFromBatch={isFromBatch}
          setvalue={handleInputChange}
          isOldLpDetail={isOldLpDetail}
          isqueReport={Props.isqueReport}
          alreadySelected={alreadySelected}
          isSelectedTopic={isSelectedTopic}
          tableShowStatus={Props.tableShowStatus}
        />{" "}
        <Complexity
          level={level}
          setLevel={setLevel}
          fromList={fromList}
          isFromLp={isFromLp}
          selectmodule={null}
          handleadd={handleadd}
          isFromBatch={isFromBatch}
          setvalue={handleInputChange}
          isOldLpDetail={isOldLpDetail}
          isqueReport={Props.isqueReport}
          setComplexities={setComplexities}
          tableShowStatus={Props.tableShowStatus}
          isSelectedComplexity={isSelectedComplexity}
        />{" "}
        <div className="addBtnToAddTopicAndComplexity">
          <button
            type="primary"
            onClick={handleAdd}
            className="btnMedium"
            disabled={topic?.name == null || level?.value == null}
          >
            Add
          </button>
        </div>
        <br />
        <div className="tableForTopicAndComplexity">
          <Table
            bordered
            columns={Columns}
            pagination={false}
            dataSource={dataTopicAndComplexity}

            // components={components}
            //rowClassName={() => "editable-row"}
          />
        </div>
        <br />
        <div className="ccQuestionLangName">
          <label htmlFor="ccQuestionLangName">
            Name<span style={{ color: "red", width: "4px" }}> *</span>
          </label>
          <br />
          <input
            name="name"
            type="text"
            value={userInput.name}
            id="ccQuestionLangName"
            onChange={handleInputChange}
          />
        </div>{" "}
        <br />
        <div className="ccQuestionDescription">
          <label htmlFor="ccQuestionDescription">Description</label>
          <br />
          <div></div>
        </div>{" "}
        <div className="ccEditorTinymce">
          <Editor
          tinymceScriptSrc='/tinymce/tinymce.min.js'
            onEditorChange={handleUpdate}
            value={userInput.description}
            onInit={(evt, editor) => (editorRef.current = editor)}
            init={{
              license_key: 'gpl',
              readonly: true,
              menubar: false,
              min_height: 150,
              branding: false,
              statusbar: false,
              elementpath: false,
              selector: "textarea",
              allow_html_in_named_anchor: true,
              plugins: [
                "link",
                "help",
                "code",
                "lists",
                "image",
                "table",
                "media",
                "anchor",
                "editor",
                "advlist",
                "preview",
                "charmap",
                "wordcount",
                "fullscreen",
                "autoresize",
                "visualblocks",
                "searchreplace",
                "insertdatetime",
              ],
              toolbar:
                "undo redo | blocks | " +
                "bold italic underline forecolor | link | alignleft aligncenter " +
                "alignright alignjustify | bullist numlist outdent indent | " +
                "removeformat | help |",
              content_style:
                "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            }}
          />
        </div>
        <br />
        <div className="questionToggle">
          <Form.Check.Label>Auto Evaluate:</Form.Check.Label>
          <Form.Check
            reverse
            type="switch"
            id="customSwitch"
            name="autoevaluate"
            checked={isChecked}
            style={{ boxShadow: "none" }}
            disabled={
              Props.isqueReport === true &&
              Props.selectque.qdata.istestcases === constants.ISNOTESTCASES.TRUE
            }
            onChange={(e) => {
              setIsChecked((prev) => !prev);
              handleToggleChange(e);
            }}
          />
          {!isChecked && (
            <>
              <input
                type="checkbox"
                name="No TestCases"
                className="noTestCases"
                checked={isNoTestCases}
                onChange={handleNoTestCases}
                disabled={
                  Props.isqueReport === true &&
                  Props.selectque.qdata.istestcases ===
                    constants.ISNOTESTCASES.TRUE
                }
              />
              <Form.Check.Label>No TestCases</Form.Check.Label>
            </>
          )}
        </div>
        <div className="Io">
          {((!isNoTestCases && !isChecked) || isChecked) && (
            <div className="inputVariablesDiv">
              <div className="functionForBoilerplate">
                <div className="inputVariablesDivLabel">
                  Function name{" "}
                  <span style={{ color: "red", width: "4px" }}> *</span>
                </div>
                <input
                  name="name"
                  type="text"
                  defaultValue={functionName}
                  onChange={handleFunctionName}
                  placeholder="Enter function name"
                  id="ccQuestionLangNameFunctionName"
                />
              </div>
              <div className="returnType">
                <div className="returnTypeDivLabel">
                  {" "}
                  Return Type{" "}
                  <span style={{ color: "red", width: "4px" }}> *</span>{" "}
                </div>
                <CreatableSelect
                  required
                  placeholder=""
                  name="returnType"
                  value={currentType}
                  options={returnTypes}
                  styles={customStylesForTopic}
                  onChange={handleReturnChange}
                  components={{ IndicatorSeparator: () => null }}
                />
              </div>
            </div>
          )}

          {((!isNoTestCases && !isChecked) || isChecked) && (
            <>
              <Io
                cuin={cuin}
                iodata={saveiodata}
                setInVa={setInVafun}
                inputvariables={inva}
                setcuin={settingCuin}
                selectque={Props.selectque}
                setvarstates={setvarstates}
                isqueReport={Props.isqueReport}
                inputDataSource={inputDataSource}
                updateTestCases={updateTestCases}
                returntype={currentType}
              />
              <br />
            </>
          )}
          <div className="ccMultiSelect">
            <div>
              <label htmlFor="supportedLanguage">
                Supported language
                <span style={{ color: "red", width: "4px" }}> *</span>
              </label>
              <div className="langAndBplate">
                <Select
                  isMulti
                  name="languages"
                  id="ccMultiSelect"
                  options={languageData}
                  placeholder="Add Code"
                  classNamePrefix="select"
                  className="ccBasicMultiSelect"
                  onChange={(e) => handleLangChange(e)}
                  components={{ ClearIndicator: () => null }}
                  value={
                    fromEditQuestion
                      ? languageData.filter((options) =>
                          Object.keys(languageKeys).includes(options.label)
                        )
                      : langLabel
                  }
                  styles={{
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      borderColor: enableSaveButton ? "green" : "red",
                    }),
                    menuList: (base) => ({
                      ...base,
                      textAlign: "left",
                      maxHeight: "170px",
                      overflowX: "hidden",
                      borderRadius: "2px",
                    }),

                  // }}
                  }}
                />
              </div>
            </div>
            {((!isNoTestCases && !isChecked) || isChecked) && (
              <div>
                <label htmlFor="evaluationRules" className="evaluationRules">
                  Evaluation Rules
                </label>
                <div className="generateBoilerplate">
                  <Select
                    isMulti
                    value={evalRule}
                    name="Evaluation rules"
                    options={evaluationRules}
                    placeholder="Add Evaluation Rules "
                    isOptionDisabled={isOptionDisabled}
                    onChange={(e) => handleEvaluationChange(e)}
                    components={{ IndicatorSeparator: () => null }}
                    styles={{
                      control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderColor: enableSaveButton ? "green" : "red",
                      }),
                      menuList: (base) => ({
                        ...base,
                        textAlign: "left",
                        maxHeight: "150px",
                        overflowX: "hidden",
                        borderRadius: "2px",
                      }),
                    }}
                  />
                </div>
              </div>
            )}
          </div>

          <br />
          <LanguageInput
            init={init}
            inva={inva}
            iodata={ioData}
            show={showLang}
            testMsg={testMsg}
            isChange={isChange}
            setIsTest={setIsTest}
            inputvariables={inva}
            masterData={masterData}
            bplateCode={bplateCode}
            selectCode={selectCode}
            saveiodata={saveiodata}
            returntype={currentType}
            LangInputs={inputVarsObj}
            setIsChange={setIsChange}
            languageId={languagesData}
            functionname={functionName}
            selectque={Props.selectque}
            isNoTestCases={isNoTestCases}
            setBplateCode={setBplateCode}
            onhandleSubmit={handleSubmit}
            isqueReport={Props.isqueReport}
            addLanguageData={addLanguageData}
            inputDataSource={inputDataSource}
            enableTestButton={enableTestButton}
            gettingLanguagesData={gettingLanguagesData}
            languagesOfPassedTestCases={languagesOfPassedTestCases}
            handlegenerateBoilerPlateCode={handleGenerateBoilerPlate}
            setQcodes = {setQcodes}
          />
        </div>
        <br />
        {isLoaded && <Loading />}
        <Notify
          show={show}
          title={title}
          message={msg}
          onHide={
            isUnAuth
              ? handleUnAuthAlert
              : resultCode === constants.RESULT_STATUS.SUCCESS && !isTest
              ? handleNavigate
              : () => {
                  setIsTest(false);
                  handleClose();
                }
          }
        />
        {(role === "admin" && Props.isqueReport) && (
          <div className="ccQuestionUpdateBtn">
            <button
              className="btnMedium"
              disabled={!enableSaveButton}
              onClick={(e) => {
                addQuestion(e, "update");
              }}
            >
              Update
            </button>
          </div>
        )}
        {!isFixedQn && (
        <div className="ccQuestionSaveBtn">
          <button
            className="btnMedium"
            disabled={!enableSaveButton}
            onClick={(e) => {
              addQuestion(e, "add");
            }}
          >
            {Props.isqueReport ? "Save as new" : "Save"}
          </button>
        </div>
        )}
        <div style={{ height: "100px", float: "left" }}></div>
      </Form>
    </div>
  );
}
