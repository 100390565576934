import Select from "react-select";
import { useParams } from "react-router-dom";
import { getDataFromStorage } from "../../util";
import AngleLeftSvg from "../../Svg/AngleLeftSvg";
import AngleRightSvg from "../../Svg/AngleRightSvg";
import React, { useContext, useEffect } from "react";
import AlertContext from "../../context/AlertContext";
import useAxiosPrivate from "../../Hooks/useAxiosPrivate";
import ProfileUser from "../CommonComponents/ProfileUser";
import Loading from "../Notification-Loading/Loading/Loading";
import { ProfileProvider } from "../../context/ProfileContext";
import { UserReportContext } from "../../context/UserReportContext";
import UserReportHooks from "../../Hooks/UserReport/UserReportHooks";
import useCommonFunctionHooks from "../../Hooks/useCommonFunctionHooks";
import UserTimeSpentReport from "./UserTimeSpentReport/UserTimeSpentReport";
import UserTestResultReportTable from "./UserTestResultReport/UserTestResultReportTable";

export default function UserReport() {
  const Alert = useContext(AlertContext);
  const UserReportCxt = useContext(UserReportContext);

  const axios = useAxiosPrivate();
  const UserReportHks = UserReportHooks();
  const useCommonFunctionHks = useCommonFunctionHooks();

  const { id } = useParams();

  useEffect(() => {
    const fetchLearnerDetails = async () => {
      let res = await axios.get(
        `node/admin/getemails/2/${id}`,
        useCommonFunctionHks.getHeaderDetailsForAxios()
      );

      let learnerDetails = res.data.data
        .sort((a, b) => a.firstname.localeCompare(b.firstname))
        .map((i, index) => {
          return {
            ind: index,
            value: i.id,
            label: i.firstname + " " + i.lastname,
          };
        });

      UserReportCxt.setLearnerList(learnerDetails);
    };

    fetchLearnerDetails();
  }, []);

  return (
    <div className="userReportMainContainer">
      {Alert.isLoaded && <Loading />}
      <div className="userReportHeader">
        <div className="userReportSubHeader">
          {getDataFromStorage("role") !== "hr" && (
            <label
              className={
                UserReportCxt.showPage === "profile" && "userReportProfilePage"
              }
              onClick={() => {
                UserReportCxt.setShowPage("profile");
              }}
            >
              Profile
            </label>
          )}

          <label
            className={
              UserReportCxt.showPage === "testresults" &&
              "userReportTestResultsPage"
            }
            onClick={() => {
              UserReportCxt.setShowPage("testresults");
            }}
          >
            Test Points
          </label>
          <label
            className={
              UserReportCxt.showPage === "timespent" &&
              "userReportTimeSpentPage"
            }
            onClick={() => {
              UserReportCxt.setShowPage("timespent");
            }}
          >
            Time Spent
          </label>
        </div>

        <div className="evaluationLearnerChange">
          <AngleLeftSvg
            disabled={Alert.showPrevBtn}
            handleFunction={() =>
              UserReportHks.handleChangeLearnerByBtn("prev")
            }
          />
          <Select
            isClearable={false}
            onChange={UserReportHks.handlestudentChange}
            styles={UserReportHks.customStyle}
            options={UserReportCxt.learnerList}
            value={UserReportCxt.currentLearnerDetails}
            className="codeEditorLanguageSelectorAdmin"
            isOptionDisabled={(option) => option.isdisabled}
            components={{
              IndicatorSeparator: () => null,
            }}
          />
          <AngleRightSvg
            disabled={Alert.showNextBtn}
            handleFunction={() =>
              UserReportHks.handleChangeLearnerByBtn("next")
            }
          />
        </div>
      </div>

      {UserReportCxt.showPage === "profile" ? (
        <ProfileProvider>
          <ProfileUser learnerId={UserReportCxt.currentLearnerDetails.value} />
        </ProfileProvider>
      ) : UserReportCxt.showPage === "testresults" ? (
        <UserTestResultReportTable />
      ) : (
        <UserTimeSpentReport />
      )}
    </div>
  );
}
