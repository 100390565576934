import constants from "../../constants";
import React, { useContext } from "react";
import AlertContext from "../../context/AlertContext";

export default function TestDueDate(Props) {
  const { status, testData, assignmentTestData } = useContext(AlertContext);

  const date = new Date(
      Props.component === "assignmentSubmission"
        ? assignmentTestData.submitteddate
        : Props.component === "warningNotice"
        ? testData.submitteddate
        : testData.duedate
    ),
    day = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ],
    month = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

  return (
    <div
      className={Props.component !== "assignmentSubmission" && "testDueDate"}
    >
      {status !== constants.PROGRESS_STATUS.EVALUATED &&
        status !== constants.PROGRESS_STATUS.TO_BE_EVALUATED &&
        status !== constants.PROGRESS_STATUS.PARTIAL_EVALUATED &&
        Props.component !== "assignmentSubmission" && (
          <span className="testDueDateLabel">Due :</span>
        )}{" "}
      {day[date.getDay()]},{" " + month[date.getMonth()]} {date.getDate()},
      {" " + date.getFullYear() + " "}
      {(date.getHours() > 12 ? date.getHours() - 12 : date.getHours()) >= 10
        ? date.getHours()
        : "0" + (date.getHours() - 12).toString()}
      :
      {(date.getMinutes() >= 10
        ? date.getMinutes()
        : "0" + date.getMinutes().toString()) +
        (date.getHours() <= 12 ? " AM" : " PM")}
    </div>
  );
}
