import React, { useContext, useState } from "react";
import ExportPdf from "../../ExportPdf";
import ExportToCsv from "../../ExportToCsv";
import useAxiosPrivate from "../../../../../Hooks/useAxiosPrivate";
import AlertContext from "../../../../../context/AlertContext";
import constants from "../../../../../constants";
import { getDisplayStatus } from "../AttendancendAssignmentStatus";
import { getCol } from "../AttendancendAssignmentStatus";

export const manipulateForTables = async (type, data, headers) => {
  let pdfData;
  if (
    type == constants.BOARD_WIDGETS.LEADERBOARD ||
    type == constants.BOARD_WIDGETS.PERCENTILE
  ) {
    pdfData = data.map((item) => {
      let statusObject = {};
      console.log("TYPE,", type);
      for (const key in item) {
        if (typeof item[key] === "object" && item[key] != null) {
          statusObject[key] = item[key].mark;
        } else {
          statusObject[key] = item[key];
        }
      }
      return statusObject;
    });
  } else {
    var assignHeaders = [];
    if (data[0]?.hasOwnProperty("name")) {
      assignHeaders.push("Name");
    }
    if (data[0].hasOwnProperty("email")) {
      assignHeaders.push("Email address");
    }
    if (data[0].hasOwnProperty("branch")) {
      assignHeaders.push("Branch");
    }
    if (data[0].hasOwnProperty("college")) {
      assignHeaders.push("College");
    }

    for (let i = 0; i < headers.length; i++) {
      assignHeaders.push(headers[i]); //.replace("\\n", "\n"));
    }

    if (data[0].hasOwnProperty("completedCount")) {
      assignHeaders.push("Completed");
    }
    if (data[0].hasOwnProperty("pendingCount")) {
      assignHeaders.push("Pending");
    }
    if (data[0].hasOwnProperty("dueDateCount")) {
      assignHeaders.push("Overdue");
    }
    if (data[0].hasOwnProperty("percentage_total_duration")) {
      assignHeaders.push("From Start(%)");
    }
    if (data[0].hasOwnProperty("percentage_selected_duration")) {
      assignHeaders.push("Selected range(%)");
    }

    pdfData = data.map((item) => {
      let statusObject1 = {};
      for (const key in assignHeaders) {
        if (
          typeof item[assignHeaders[key]] === "object" &&
          item[assignHeaders[key]] != null
        ) {
          if (type == constants.BOARD_WIDGETS.ATTENDANCE) {
            if (item[assignHeaders[key]].status == 2) {
              statusObject1[assignHeaders[key]] = "A";
            } else if (item[assignHeaders[key]].status == 1) {
              statusObject1[assignHeaders[key]] = "P";
            } else if (item[assignHeaders[key]].status == 3) {
              statusObject1[assignHeaders[key]] = "PP";
            } else if (item[assignHeaders[key]].status == 0) {
              statusObject1[assignHeaders[key]] = "NR";
            } else
              statusObject1[assignHeaders[key]] =
                item[assignHeaders[key]].status;
          } else if (type == constants.BOARD_WIDGETS.ASSIGNMENT_STATUS) {
            console.log("assignment status");
            statusObject1[assignHeaders[key]] = getDisplayStatus(
              item[assignHeaders[key]].status,
              item[assignHeaders[key]].libtype,
              item[assignHeaders[key]].duedate
            );
          } else {
            console.log("scores");
            statusObject1[assignHeaders[key]] = item[assignHeaders[key]].mark;
          }
        } else if (
          item[getCol(assignHeaders[key])] == undefined &&
          item[assignHeaders[key]] == undefined
        ) {
          statusObject1[assignHeaders[key]] = "N/A";
        } else {
          statusObject1[assignHeaders[key]] = item[getCol(assignHeaders[key])];
        }
      }
      return statusObject1;
    });
  }
  return pdfData;
};
function DownloadOptions(Props) {
  const axios = useAxiosPrivate();
  const { generatePDF, renderDataToPDF } = ExportPdf();
  const { downloadDataAsCSV } = ExportToCsv();
  const { setDownloadWidget, downloadWidget } = useContext(AlertContext);
  const fetchData = async (type, dId, wid) => {
    let response = await axios.get(
      `node/admin/dashboard/${dId}/widget/${wid}/report`,
      {
        params: {
          type: type,
        },
        headers: {
          "Content-type": "application/json",
        },
      }
    );

    console.log(
      "response?.data?.data?.data in widget all",
      response?.data?.data?.data
    );
    return response?.data?.data;
    //?.data;
  };

  const onFileDownloadPdf = async () => {
    setDownloadWidget(true);
    if (Props.hasOwnProperty("data")) {
      //setData(Props.data);
      let manipulatedData = await manipulateForTables(Props.type, Props.data);
      renderDataToPDF(manipulatedData, Props.orientation, "a4", Props.name);
      setDownloadWidget(0);
      console.log("leaderboard and percentile", Props.name);
    } else {
      console.log("remaining");
      let widData = await fetchData(
        Props.type,
        Props.dashboardId,
        Props.widgetId
      );
      console.log("widData>>>>>>", widData);
      let manipulatedData = await manipulateForTables(
        Props.type,
        widData?.data,
        widData?.headers
      );
      console.log("manipulatedData", manipulatedData);
      renderDataToPDF(manipulatedData, Props.orientation, "a1", Props.name);
      setDownloadWidget(0);
    }
  };

  const onFileDownloadCsv = async () => {
    setDownloadWidget(true);
    if (Props.hasOwnProperty("data")) {
      //setData(Props.data);
      let manipulatedData = await manipulateForTables(Props.type, Props.data);
      downloadDataAsCSV(manipulatedData, Props.name);
      setDownloadWidget(0);

      console.log("leaderboard and percentile", Props.name);
    } else {
      console.log("remaining");
      let widData = await fetchData(
        Props.type,
        Props.dashboardId,
        Props.widgetId
      );
      let manipulatedData = await manipulateForTables(
        Props.type,
        widData?.data,
        widData?.headers
      );
      downloadDataAsCSV(manipulatedData, Props.name);
      setDownloadWidget(0);
    }
  };

  return (
    <div className="downloadOptions">
      <div
        className="option"
        //onClick={()=>{renderDataToPDF(data,Props.orientation)}}
        onClick={() => {
          onFileDownloadPdf();
        }}
      >
        <span className="svgiconfordownload">
          <i
            id="pdfIcon"
            className="fa fa-file-pdf-o"
            style={{ color: "red", width: "20", height: "18" }}
          ></i>
        </span>
        <p className="optiontext">PDF</p>
      </div>
      <div
        className="option"
        onClick={() => {
          onFileDownloadCsv();
        }}
      >
        <span className="svgiconfordownload">
          <svg
            width="20"
            height="18"
            viewBox="0 0 20 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.8247 1.50248H12.486C12.4596 1.50248 12.4397 1.50248 12.4133 1.50908V-0.00297165L0 1.41664V16.5437L12.4133 18.003V16.4513C12.4397 16.4513 12.4596 16.4579 12.486 16.4579H18.8247C19.135 16.4579 19.4322 16.3391 19.6567 16.1212C19.8745 15.9033 20 15.6127 20 15.3024V2.65797C20 2.34764 19.8745 2.05711 19.6567 1.83922C19.4322 1.62793 19.135 1.50248 18.8247 1.50248ZM3.52592 9.88148C3.73721 9.88148 3.9485 9.84846 4.14658 9.78244L4.25883 10.3965C4.12017 10.4625 3.81644 10.5352 3.41367 10.522C2.31099 10.4955 1.75636 9.76263 1.75636 8.81182C1.75636 7.67613 2.51568 7.02245 3.4863 6.99604C3.86926 6.98283 4.16639 7.06207 4.29845 7.12809L4.14658 7.74876C3.9485 7.66953 3.73721 7.62331 3.51931 7.62991C2.93826 7.63651 2.49587 8.01287 2.49587 8.759C2.50248 9.43909 2.87884 9.86827 3.52592 9.88148ZM5.5926 10.5748C5.20304 10.5616 4.81347 10.4493 4.62859 10.3371L4.78706 9.69C5.05117 9.82866 5.3417 9.90789 5.64543 9.92109C6.00858 9.9277 6.20007 9.77583 6.20007 9.54473C6.20007 9.32024 6.035 9.19478 5.61902 9.04292C5.05117 8.83823 4.68141 8.51469 4.68141 8.01287C4.68141 7.41862 5.16342 6.94982 5.97557 6.93001C6.37174 6.9168 6.66887 6.99604 6.88016 7.08848L6.70188 7.74876C6.47078 7.64312 6.21987 7.58369 5.96236 7.59029C5.62562 7.5969 5.46055 7.75536 5.46055 7.94024C5.46055 8.16474 5.65203 8.26378 6.10102 8.42885C6.72829 8.65995 7.02542 8.99009 7.02542 9.49851C7.01882 10.0994 6.55662 10.6012 5.5926 10.5748ZM9.44206 10.621L8.43843 10.5946L7.25652 6.95642L8.16771 6.93661L8.62331 8.48168C8.74876 8.91746 8.86761 9.34005 8.96005 9.80224H8.97986C9.0723 9.35985 9.19775 8.91746 9.32321 8.49488L9.81842 6.89699L10.7626 6.87058L9.44206 10.621ZM19.3265 15.2958C19.3265 15.4279 19.2737 15.5533 19.1812 15.6458C19.0888 15.7382 18.9567 15.791 18.8247 15.791H12.486C12.4596 15.791 12.4397 15.791 12.4133 15.7844V2.16936C12.4397 2.16276 12.4596 2.16276 12.486 2.16276H18.8247C18.9567 2.16276 19.0888 2.21558 19.1812 2.30802C19.2737 2.40046 19.3265 2.52592 19.3265 2.65797V15.2958Z"
              fill="#0B8938"
            />
          </svg>
        </span>
        <p className="optiontext">CSV</p>
      </div>
    </div>
  );
}

export default DownloadOptions;
