import {
  useActiveCode,
  useSandpack,
} from "@codesandbox/sandpack-react";
import Editor from "@monaco-editor/react";

export const getLanguageOfFile = (filePath) => {
  const extensionDotIndex = filePath?.lastIndexOf(".");
  const extension = filePath?.slice(extensionDotIndex + 1);

  switch (extension) {
    case "js":
    case "jsx":
    case "ts":
    case "tsx":
      return "javascript";
    case "vue":
    case "html":
      return "html";
    case "css":
    case "scss":
    case "less":
      return "css";
    case "json":
      return "json";
    default:
      return "javascript";
  }
};

export default function MonacoEditor() {
  const { code, updateCode } = useActiveCode();
  const { sandpack } = useSandpack();
  const language = getLanguageOfFile(sandpack.activeFile);

  return (
    <Editor
      width="100%"
      height="100%"
      language={language}
      theme="vs-dark"
      key={sandpack.activeFile}
      value={code}
      defaultValue={code}
      onChange={(value) => updateCode(value || "")}
    />
  );
}
