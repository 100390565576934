import Button from "../../Button";
import Score from "../../Score/Score";
import Form from "react-bootstrap/Form";
import { Modal } from "react-bootstrap";
import useCollapse from "react-collapsed";
import React, { useContext } from "react";
import constants from "../../../constants";
import RejectSvg from "../../../Svg/RejectSvg";
import Offcanvas from "react-bootstrap/Offcanvas";
import ProctorEyeSvg from "../../../Svg/ProctorEyeSvg";
import Checkbox from "../../CommonComponents/Checkbox";
import CommentsHooks from "../../../Hooks/CommentsHooks";
import AlertContext from "../../../context/AlertContext";
import ApproveSvgIcon from "../../../Svg/ApproveSvgIcon";
import CommentSvgIcon from "../../../Svg/CommentSvgIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loading from "../../Notification-Loading/Loading/Loading";
import CodingHeaderAdminSideHooks from "../../../Hooks/CodingPage/CodingHeaderAdminSideHooks";

import {
  faXmark,
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

export default function CodingHeaderAdminSide() {
  const Alert = useContext(AlertContext);

  const { toggleDrawer } = CommentsHooks();
  const CodingHeaderAdminSideHks = CodingHeaderAdminSideHooks();

  const TrackTimelineDetails = (Props) => {
    const changeTimeandDate = (timestamp) => {
      const dateObj = new Date(timestamp);

      const date = dateObj.toLocaleString();

      return date;
    };

    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

    return (
      <div className="collapsible_userDetails">
        <div className="header" {...getToggleProps()}>
          <span className="timeline-header">Activity</span>
          <span className="timeline-header-arrowIcon">
            <FontAwesomeIcon
              className="arrowIcon"
              icon={isExpanded ? faChevronDown : faChevronRight}
            />
          </span>
        </div>
        <div className="details-collapse" {...getCollapseProps()}>
          <div className="content">
            {Props.eventLogData != null &&
              Props.eventLogData.map((data) => (
                <div className="activity-log">
                  <span className="activity-log-timestamp">
                    {changeTimeandDate(data.createdTimeStamp)}
                  </span>
                  <span className="activity-log-eventtext">
                    {data.eventtext}
                  </span>
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="codingHeaderAdminSideMainContainer">
      <div className="codingHeaderAdminSideHeader">
        {CodingHeaderAdminSideHks.showSubmitButton(
          Object.keys(Alert.codeDetails).length !== 0
            ? Alert.codeDetails.status
            : Alert.mcqAndDesTestAndLpId.status
        ) && (
          <Button
            size={"sm"}
            onClick={CodingHeaderAdminSideHks.adminSubmitTest}
            hierarchy={{
              type: "primary",
              buttonText: "Submit",
            }}
          />
        )}
        {(Alert.showBreadCrumbsData.status ===
          constants.PROGRESS_STATUS.EVALUATED ||
          Alert.showBreadCrumbsData.status ===
            constants.PROGRESS_STATUS.TO_BE_EVALUATED ||
          Alert.showBreadCrumbsData.status ===
            constants.PROGRESS_STATUS.PARTIAL_EVALUATED) && <Score />}
        {(Alert.showBreadCrumbsData.testMode === constants.MODE.TEST_MODE ||
          Alert.showBreadCrumbsData.type ===
            constants.libTypeIds.ASSIGNMENT) && (
          <>
            {Alert.showBreadCrumbsData.type !== constants.libTypeIds.MCQ &&
              Alert.showBreadCrumbsData.type !==
                constants.libTypeIds.DESCRIPTIVE && (
                <ApproveSvgIcon
                  onClick={CodingHeaderAdminSideHks.handleApprove}
                />
              )}
            <RejectSvg onClick={CodingHeaderAdminSideHks.handleReject} />
          </>
        )}
        <CommentSvgIcon onClick={toggleDrawer("right", true)} />
        {Alert.showBreadCrumbsData.type !== constants.libTypeIds.ASSIGNMENT && (
          <ProctorEyeSvg
            onClick={CodingHeaderAdminSideHks.handleShowProctoring}
          />
        )}
      </div>

      <div style={{ marginTop: "55px" }}>
        <Offcanvas
          placement="top"
          id="proctoringDetailContainer"
          show={CodingHeaderAdminSideHks.showProctoring}
          onHide={() => {
            CodingHeaderAdminSideHks.handleProctoringClosePopup();
            CodingHeaderAdminSideHks.setDeleteImages({
              withKey: [],
              withOutKey: [],
            });
          }}
        >
          {Alert.isLoaded && <Loading />}

          <div className="proctoringTitleSection">
            <FontAwesomeIcon
              icon={faXmark}
              className="proctoringCloseIcon"
              onClick={() => {
                CodingHeaderAdminSideHks.handleProctoringClosePopup();
                CodingHeaderAdminSideHks.setDeleteImages({
                  withKey: [],
                  withOutKey: [],
                });
              }}
            />
          </div>

          <Form noValidate name="userProctoringForm" className="proctoringForm">
            <div className="details-img-seperate">
              <div className="user-profile">
                <span
                  className="userImage"
                  onClick={CodingHeaderAdminSideHks.handleProfileShow}
                >
                  <img
                    width={"150"}
                    height={"150"}
                    alt="userImage"
                    src={Alert.profile.registerUrl}
                  />
                </span>
              </div>
              <Modal
                className="proctoringProfile"
                show={CodingHeaderAdminSideHks.showProfile}
                onHide={CodingHeaderAdminSideHks.handleProfileClose}
              >
                <img
                  width={"100%"}
                  height={"300"}
                  alt="userImage"
                  src={Alert.profile.registerUrl}
                />
              </Modal>
              <div className="student-details-proctor">
                <span className="userName">Student Name</span>:
                <span className="userName-durn">
                  {Alert.showBreadCrumbsData.studentName}
                </span>
                <div className="proctoringDetails">
                  <div>
                    <span className="userName">No of tab switches</span>:
                    <span className="userName-durn">
                      {Alert.profile.switched ? Alert.profile.switched : 0}
                    </span>
                  </div>
                  <div>
                    <span className="userName">Creditibility score</span>:
                    <span className="userName-durn">
                      {Alert.profile.credibilityScore
                        ? Alert.profile.credibilityScore
                        : 0}
                    </span>
                  </div>

                  <div>
                    <span className="userName">Duration</span>:
                    <span className="userName-durn">
                      {Alert.profile.duration !== ""
                        ? Alert.profile.duration
                        : "No Data"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <TrackTimelineDetails eventLogData={Alert.profile.eventdata} />
            </div>
            <div className="proctoringImagesTitleContainer">
              <div className="proctoringImagesTitleSec">
                <Checkbox
                  size="md"
                  text="Images"
                  disabled={!Alert.profile.proctoringdatas?.length > 0}
                  onChange={(checked) =>
                    CodingHeaderAdminSideHks.handleSelectImage(checked, "all")
                  }
                  checked={
                    Alert.profile.proctoringdatas?.length ===
                    CodingHeaderAdminSideHks.deleteImages.withKey.length
                  }
                />
              </div>
              {Alert.profile.proctoringdatas?.length > 0 && (
                <div>
                  <Button
                    size={"sm"}
                    onClick={CodingHeaderAdminSideHks.handleDeleteImg}
                    disabled={
                      !CodingHeaderAdminSideHks.deleteImages.withOutKey.length >
                      0
                    }
                    hierarchy={{
                      type: "primary",
                      buttonText: "Delete",
                    }}
                  />
                </div>
              )}
            </div>
            {Alert.profile.proctoringdatas ? (
              <div className="ProctoringImagesContainer">
                {Alert.profile.proctoringdatas?.map((data, index) => {
                  let utcDate = new Date(data.timestamp);

                  const istFormatter = utcDate.toLocaleString("en-IN", {
                    hour12: true,
                    day: "2-digit",
                    year: "numeric",
                    hour: "2-digit",
                    month: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                  });

                  return (
                    <div className="proctoringImgDiv" key={index}>
                      <div className="proctoringImgTimestampSec">
                        <Checkbox
                          size="sm"
                          text={istFormatter}
                          checked={data.isChecked}
                          onChange={(checked) =>
                            CodingHeaderAdminSideHks.handleSelectImage(
                              checked,
                              data.imagename
                            )
                          }
                        />
                      </div>
                      <img
                        width={"50px"}
                        height={"100"}
                        alt="proctoringFacedetected"
                        src={data.imagePresignedUrl}
                        className={
                          data.faceorientationnormal === 0
                            ? "proctoringFacedetected"
                            : "proctoringNotdetected"
                        }
                      />
                    </div>
                  );
                })}
              </div>
            ) : (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <p className="proctoringTimeStamp">No proctoring data</p>
              </div>
            )}
            <hr className="proctoringLine" />
            {/* <Button
              size={"sm"}
              onClick={() => {
                CodingHeaderAdminSideHks.handleProctoringClosePopup();
                CodingHeaderAdminSideHks.setDeleteImages({ withKey: [], withOutKey: [] });
              }}
              hierarchy={{
                type: "primary",
                buttonText: "Cancel",
              }}
            /> */}
          </Form>
        </Offcanvas>
      </div>
    </div>
  );
}
