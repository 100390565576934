import moment from "moment";
import { nanoid } from "nanoid";
import constants from "../constants";
import ModuleHooks from "./ModuleHooks";
import useAxiosPrivate from "./useAxiosPrivate";
import AlertContext from "../context/AlertContext";
import useSubmitAssignment from "./useSubmitAssignment";
import { getDataFromStorage, getPreSignedUrl } from "../util";
import useCommonFunctionHooks from "./useCommonFunctionHooks";
import { useCallback, useContext, useRef, useState } from "react";
import UploadAssignment from "../Components/Student/AssignmentPage/UploadAssignment";

export default function AssignmentPageHooks() {
  const {
    lpId,
    status,
    isAdmin,
    setScore,
    setLibId,
    navigate,
    testData,
    setStatus,
    setLibType,
    setTestData,
    setIsLoaded,
    setShowMain,
    setLearnerId,
    learnerNotes,
    setShowNotify,
    setFromReport,
    setLpConfigId,
    setContentData,
    setApproveData,
    setIsSubmitted,
    setLearnerNotes,
    setIsFullScreen,
    setCurrentIndex,
    setUpdateLeftNav,
    isEditSubmission,
    assignmentTestData,
    setIsAddSubmission,
    setSubmissionFiles,
    setIsEditSubmission,
    setAssignmentTestData,
    assignmentSubmissionFiles,
    learnerVideoLink,setLearnerVideoLink
  } = useContext(AlertContext);

  const axios = useAxiosPrivate();

  const submitAssignment = useSubmitAssignment();
  const { clearPopupStates, handlePopupStates } = ModuleHooks();

  const { getCatchBlockDetails, getHeaderDetailsForAxios } =
    useCommonFunctionHooks();

  const [referenceLinks, setReferenceLinks] = useState([]);

  const assignmentDueDate = moment(assignmentTestData.dueDate);
  const today = moment(assignmentTestData.serverDate).tz("Asia/Kolkata");
  const diff = assignmentDueDate.diff(today, "minutes");

  const assignmentReferenceLinkRef = useRef([]);

  const [submitWithSandPack, setSubmitWithSandPack] = useState(false);

  const handleRequestFullScreenMode = async (index, link) => {
    const iframeRef = assignmentReferenceLinkRef.current[index];

    iframeRef.requestFullscreen();
    setIsFullScreen(true);
    setCurrentIndex(index);
  };

  const handleExitFullScreen = () => {
    if (document.fullscreenElement === null) {
      setIsFullScreen(false);
    }
  };

  const openSandPackEditor = () => {
    setIsAddSubmission(true);
    setSubmitWithSandPack(true);
    navigate("/sandpack");
  };

  const handleSetSubmissionFile = () => {
    setSubmissionFiles(
      status === constants.PROGRESS_STATUS.UNLOCKED
        ? []
        : assignmentTestData?.anslinks !== null &&
          assignmentTestData?.anslinks !== undefined &&
          assignmentTestData?.anslinks !== "" &&
          JSON.parse(assignmentTestData?.anslinks).length !== 0
        ? JSON.parse(assignmentTestData.anslinks).map((link) => {
            let linkJson = {
              link: link,
              id: nanoid(),
              fileStatus: "old",
              isUploading: false,
              lastModified: assignmentTestData?.submitteddate,
              submissionDate: assignmentTestData?.submitteddate,
              name:
                link !== null ? link.substring(link.lastIndexOf("/") + 1) : "",
            };

            return linkJson;
          })
        : []
    );

    if (status === constants.PROGRESS_STATUS.UNLOCKED) setLearnerNotes("");
  };

  const handleCancel = () => {
    clearPopupStates();

    if (!isEditSubmission) {
      handleSetSubmissionFile();
      setIsAddSubmission(false);
    } else {
      setIsEditSubmission(false);
      setIsSubmitted(true);
      handleSetSubmissionFile();
      setIsAddSubmission(false);
      setStatus(constants.PROGRESS_STATUS.TO_BE_EVALUATED);
    }
  };

  const handleSubmitAssignment = async () => {
    try {
      setIsLoaded(true);

      let filterFile = assignmentSubmissionFiles.current.filter(
        (file) => file.fileStatus !== "remove"
      );

      if (filterFile.length === 1 || (learnerVideoLink !=="" && learnerVideoLink != null)) {
        let payloadArg = {
          lpId,
          isSave: true,
          libId: testData.libid,
          // file: filterFile[0].file,
          lpConfigId: testData.lpconfigid,
          learnerNotes: learnerNotes ? learnerNotes : "",
          uploadedlink:learnerVideoLink? learnerVideoLink :"",
          // filePath:
          //   "library/submission/" +
          //   getDataFromStorage("learnerid") +
          //   "/" +
          //   filterFile[0].file.name,
            
        }
        if(filterFile.length === 1){
          payloadArg.file = filterFile[0].file;
          payloadArg.filePath = "library/submission/" +getDataFromStorage("learnerid") +"/" +filterFile[0].file.name
        }


        let response = await submitAssignment(payloadArg);

        if (response.data.resultCode !== constants.RESULT_STATUS.SUCCESS) {
          setShowNotify({
            show: true,
            title: "Error",
            msg:
              response.data === ""
                ? "Something went wrong. Please try again later"
                : response.data.message,
          });
        } else {
          let newFiles = [...filterFile];

          for (let file = 0; file < newFiles.length; file++) {
            newFiles[file] =
              "library/submission/" +
              getDataFromStorage("learnerid") +
              "/" +
              newFiles[file].file.name;
          }

          let assignmentData = JSON.parse(JSON.stringify(assignmentTestData));

          setUpdateLeftNav(true);
          assignmentData.commentsdata = [];
          assignmentData.answer = learnerNotes;
          assignmentData.submitteddate = new Date();
          assignmentData.anslinks = JSON.stringify(newFiles);
          assignmentData.uploadedlink = learnerVideoLink;
          setAssignmentTestData(assignmentData);
          setStatus(constants.PROGRESS_STATUS.TO_BE_EVALUATED);
          setIsSubmitted(true);
          setIsAddSubmission(false);
          setIsEditSubmission(false);
          assignmentSubmissionFiles.current = [];
          clearPopupStates();
        }
      } else if (filterFile.length === 0 &&(learnerVideoLink == "" || learnerVideoLink == undefined || learnerVideoLink == null)) {
        setShowNotify({
          show: true,
          title: "Warning",
          msg: "No files selected. Please choose a file to upload.",
        });
      } else if(filterFile.length > 0) {
        setShowNotify({
          show: true,
          title: "Warning",
          msg: "Multiple files are not allowed.",
        });
      }
    } catch (err) {
      getCatchBlockDetails(err);
    } finally {
      setIsLoaded(false);
    }
  };

  const showPopUp = () => {
    // let popupdata = {};

    // popupdata.closebtn = true;
    // popupdata.showpopup = true;
    // popupdata.iscomponent = true;
    // popupdata.secbtntxt = "Cancel";
    // popupdata.primbtntxt = "Submit";
    // popupdata.singlebtnsize = "large";
    // popupdata.secbtnfunt = handleCancel;
    // popupdata.title = "Upload assignment";
    // popupdata.component = <UploadAssignment />;
    // popupdata.primbtnfunt = handleSubmitAssignment;
    // handlePopupStates(popupdata);


    setShowNotify({
      show: true,
      title: "Upload assignment",
      size: "lg",
      showClose: true,
      hideButton: true,
      component: "leaderBoard",
      msg: <UploadAssignment />,
    });
  };

  const handleAddAssignment = () => {
    setIsAddSubmission(true);
    setSubmitWithSandPack(false);
    showPopUp();
  };

  const handleEditSubmission = () => {
    setIsSubmitted(false);
    setIsAddSubmission(true);
    showPopUp();

    if (
      assignmentTestData.hasOwnProperty("template") &&
      !submitWithSandPack &&
      status === constants.PROGRESS_STATUS.TO_BE_EVALUATED
    ) {
      setIsEditSubmission(true);
    }

    if (
      !assignmentTestData.hasOwnProperty("template") &&
      status === constants.PROGRESS_STATUS.TO_BE_EVALUATED
    ) {
      setIsEditSubmission(true);
    }

    if (!isAdmin) {
      setSubmitWithSandPack(false);
    }
  };

  const handleDownloadReferenceFileForLearner = async (link) => {
    let getCurrentLink = await getPreSignedUrl(link.path);

    let anchorElement = document.createElement("a");

    anchorElement.download = link.name;
    anchorElement.href = getCurrentLink;
    anchorElement.setAttribute("target", "_blank");
    anchorElement.click();
  };

  const handleDownloadLearnerSubmissionFiles = async (filePath) => {
    let link = {};
    let URL = "",
      fileName = filePath.substring(filePath.lastIndexOf("/") + 1);

    if (process.env.REACT_APP_DEPLOYMENT === "LOCAL")
      URL = process.env.REACT_APP_NODE_API + filePath;
    else {
      let response = await getPreSignedUrl(filePath);

      URL = response;
    }

    link = {
      link: URL,
      name: fileName,
    };

    handleDownloadReferenceFileForLearner(link);
  };

  const handleNavContentOrAssignment = (
    data,
    lpId,
    type,
    lpConfigId,
    libId,
    status,
    learnerlist,
    fromreport,
    lid,
    cellobj
  ) => {
    setFromReport(fromreport);
    setLearnerId(lid);

    if (data.name === null) {
      setShowNotify({
        show: true,
        title: "Info",
        msg: "There is no data to see. Contact your trainer.",
      });
    } else {
      setLibId(libId);
      setLibType(type);
      setStatus(status);
      setContentData(data);
      setLpConfigId(lpConfigId);
      setScore(isAdmin && data.actualscore !== null ? data.actualscore : 0);

      setApproveData({
        marks: data.marks,
        actualscore: data.actualscore,
      });

      if (!isAdmin) {
        setShowMain(true);
      } else {
        setAssignmentTestData(data);
        setTestData({
          testtype: cellobj.type,
          testmode: cellobj.testMode,
          testname: cellobj.testName,
        });

        navigate("/assignment");
      }
    }
  };

  const handleGetLinks = useCallback(() => {
    return new Promise((resolve, reject) => {
      return (async () => {
        try {
          let contentData1 = JSON.parse(JSON.stringify(assignmentTestData));
          let links =
            contentData1 !== "" &&
            contentData1?.hasOwnProperty("qnlinks") &&
            contentData1?.qnlinks !== null &&
            contentData1?.qnlinks !== undefined &&
            contentData1?.qnlinks.length > 0
              ? JSON.parse(contentData1.qnlinks)
              : contentData1 !== "" &&
                contentData1?.hasOwnProperty("links") &&
                contentData1?.links !== null &&
                contentData1?.links !== undefined &&
                contentData1?.links.length > 0
              ? JSON.parse(contentData1.links)
              : [];
          let getLinkCount = 0;

          setLearnerVideoLink(contentData1.uploadedlink);

          setLearnerNotes(
            status === constants.PROGRESS_STATUS.UNLOCKED
              ? ""
              : contentData1?.answer !== null
              ? contentData1.answer
              : ""
          );

          /* get reference links */

          if (
            isAdmin &&
            (status === constants.PROGRESS_STATUS.EVALUATED ||
              status === constants.PROGRESS_STATUS.TO_BE_EVALUATED)
          ) {
            setLearnerNotes(
              contentData1?.answer !== null ? contentData1.answer : ""
            );
          }

          for (let link = 0; link < links.length; link++) {
            if (link.link !== null) {
              let fileName = links[link].link.substring(
                links[link].link.lastIndexOf("/") + 1
              );

              links[link].id = nanoid();
              links[link].name = fileName;
              links[link].path = links[link].link;
              links[link].extension = fileName
                .replace(/^.*\./, "")
                .toLowerCase();

              if (links[link].type !== constants.LINK_TYPE.EMBEDED_LINK) {
                if (process.env.REACT_APP_DEPLOYMENT === "LOCAL")
                  links[link].link =
                    process.env.REACT_APP_NODE_API + links[link].link;
                else {
                  let getPreSignedUrlRes = await getPreSignedUrl(
                    links[link].link
                  );

                  links[link].link = getPreSignedUrlRes;
                }
              } else links[link].name = links[link].link;

              getLinkCount++;
            } else links.splice(link, 0);
          }

          if (getLinkCount === links.length) resolve(links);
        } catch (err) {
          reject([]);
        }
      })();
    });
  }, [assignmentTestData]);

  const handleAssignment = (type, lpConfigId, libId, status, date) => {
    setIsLoaded(true);
    axios
      .post(
        `node/learner/course/assignment/${
          status === constants.PROGRESS_STATUS.EVALUATED ||
          status === constants.PROGRESS_STATUS.TO_BE_EVALUATED
            ? "report"
            : "get"
        }`,
        status === constants.PROGRESS_STATUS.EVALUATED ||
          status === constants.PROGRESS_STATUS.TO_BE_EVALUATED
          ? JSON.stringify({
              libid: libId,
              lpid: sessionStorage.getItem("lpId"),
              learnerid: getDataFromStorage("learnerid"),
            })
          : JSON.stringify({
              type: type,
              libid: libId,
              lpid: sessionStorage.getItem("lpId"),
              learnerid: getDataFromStorage("learnerid"),
            }),
        getHeaderDetailsForAxios()
      )
      .then((res) => {
        if (res.data.resultCode !== constants.RESULT_STATUS.SUCCESS) {
          setShowNotify({
            show: true,
            title: "Info",
            msg: res.data.message,
          });
        } else {
          let data = res.data.data;

          data.dueDate = date;
          data.serverDate = res.headers.date;

          if (
            status === constants.PROGRESS_STATUS.UNLOCKED ||
            status === constants.PROGRESS_STATUS.REATTEMPT
          ) {
            setIsSubmitted(false);
          } else {
            setIsSubmitted(true);
          }

          setAssignmentTestData(data);
          handleNavContentOrAssignment(
            data,
            lpId,
            type,
            lpConfigId,
            libId,
            status
          );
          setIsLoaded(false);
        }
      })
      .catch((error) => {
        getCatchBlockDetails(error);
      })
      .finally(() => setIsLoaded(false));
  };

  return {
    diff,
    showPopUp,
    referenceLinks,
    handleGetLinks,
    handleAssignment,
    setReferenceLinks,
    openSandPackEditor,
    submitWithSandPack,
    handleAddAssignment,
    handleEditSubmission,
    handleExitFullScreen,
    setSubmitWithSandPack,
    assignmentReferenceLinkRef,
    handleRequestFullScreenMode,
    handleNavContentOrAssignment,
    handleDownloadLearnerSubmissionFiles,
    handleDownloadReferenceFileForLearner,
  };
}
