import { useContext } from "react";
import { getDataFromStorage } from "../util";
import useAxiosPrivate from "./useAxiosPrivate";
import useUploadLargeFileToS3 from "./useUploadLargeFileToS3";
import AlertContext from "../context/AlertContext";

const useSubmitAssignment = () => {
  const axios = useAxiosPrivate();
  const { sandpackFiles } = useContext(AlertContext);
  const {
    uploadLargeFileToS3,
    uploadFileToS3,
    deleteS3Objects,
    getS3ObjectByFolder,
  } = useUploadLargeFileToS3();

  const submitAssignment = async (submissionData) => {
    return new Promise((resolve, reject) => {
      return (async () => {
        try {
          let form = new FormData();

          form.append("lpid", submissionData.lpId);
          form.append("libid", submissionData.libId);

          // form.append("contenttype", filterFile[0].get('file').name.replace(/^.*\./, ""));
          let fileName = "",
            attachment,
            filePath;
          if (submissionData.template) {
            console.log("submissionData.template");
            fileName = submissionData.file.contentType;
            attachment =
              process.env.REACT_APP_DEPLOYMENT === "AWS"
                ? submissionData.file.file
                : JSON.stringify(submissionData.file.file);
            form.append("template", submissionData.template);
            form.append("fileName", submissionData.file.name);
            form.append("contenttype", fileName);
            filePath =
              "library/submission/" +
              getDataFromStorage("learnerid") +
              "/" +
              submissionData.file.name;
          } else if (submissionData.hasOwnProperty("file")) {
            fileName = submissionData.file.name.replace(/^.*\./, "");
            attachment = submissionData.file;
            form.append("contenttype", fileName);
            form.append("fileName", submissionData.file.name);
            filePath =
              "library/submission/" +
              getDataFromStorage("learnerid") +
              "/" +
              submissionData.file.name;
          }

          // form.append("contenttype", fileName);
          form.append("isSave", submissionData.isSave);

          form.append("lpconfigid", submissionData.lpConfigId);
          // form.append("fileName", submissionData.file.name);
          form.append("learnerid", getDataFromStorage("learnerid"));
          form.append("learnerNotes", submissionData.learnerNotes);
          form.append("uploadedlink", submissionData.uploadedlink);

          if (process.env.REACT_APP_DEPLOYMENT === "AWS") {
            if (submissionData.template) {
              let fileData = await getS3ObjectByFolder(
                `${process.env.REACT_APP_WEBSITE_HOST}/${
                  submissionData.libId
                }/${getDataFromStorage("learnerid")}`
              );
              if (Object.keys(fileData ?? {}).length)
                await deleteS3Objects(
                  Object.keys(fileData).map((file) => ({
                    Key: `${process.env.REACT_APP_WEBSITE_HOST}/${
                      submissionData.libId
                    }/${getDataFromStorage("learnerid")}${file}`,
                  })),
                  "deleteWebHost"
                );
              for (let file in sandpackFiles) {
                await uploadFileToS3(
                  sandpackFiles[file]?.code,
                  `${process.env.REACT_APP_WEBSITE_HOST}/${
                    submissionData.libId
                  }/${getDataFromStorage("learnerid")}${file}`,
                  file.split(".")[1],
                  "uploadAssignment"
                );
              }
            } else if (submissionData.hasOwnProperty("file")) {
              await uploadLargeFileToS3(
                attachment,
                filePath,
                submissionData.file.name.replace(/^.*\./, "")
              );
            }
          } else if (submissionData.hasOwnProperty("file")) {
            form.append("attachment", attachment);
          }
          console.log("submissionData", submissionData);
          let response = await axios.post(
            "node/learner/course/assignment/submit",
            form,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          resolve(response);
        } catch (err) {
          reject(err);
        }
      })();
    });
  };
  return submitAssignment;
};

export default useSubmitAssignment;
