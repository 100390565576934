import React, { useEffect, useState, useCallback } from "react";
import { pdfjs, Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { getDataFromStorage, getPreSignedUrl } from "../../util";
import DownloadSvgIcon from "../../Svg/DownloadSvgIcon";
import useUploadLargeFileToS3 from "../../Hooks/useUploadLargeFileToS3";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.mjs`;

const PdfViewer = ({ path, isDownloadable, name }) => {
  const { getS3Object } = useUploadLargeFileToS3();
  const [numPages, setNumPages] = useState(null);
  const [pdfDataUrl, setPdfDataUrl] = useState(null);

  const streamToUint8Array = useCallback(async (stream) => {
    const chunks = [];
    for await (let chunk of stream) {
      chunks.push(chunk);
    }

    const totalLength = chunks.reduce((acc, chunk) => acc + chunk.length, 0);
    const uint8Array = new Uint8Array(totalLength);

    let offset = 0;
    for (const chunk of chunks) {
      uint8Array.set(chunk, offset);
      offset += chunk.length;
    }

    return uint8Array;
  }, []);

  useEffect(() => {
    const fetchPdfData = async () => {
      try {
        let url;
        if (process.env.REACT_APP_DEPLOYMENT === "LOCAL") {
          url = { url: process.env.REACT_APP_NODE_API + path };
        } else {
          const result = await getS3Object(path);
          const uint8Array = await streamToUint8Array(result.Body);
          url = { data: uint8Array };
        }
        setPdfDataUrl(url);
      } catch (error) {
        console.error("Error fetching PDF data:", error);
      }
    };

    fetchPdfData();
  }, [path, streamToUint8Array]);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const handleDownload = async (path) => {
    let getCurrentLink = process.env.REACT_APP_NODE_API + path;
    if (process.env.REACT_APP_DEPLOYMENT === "AWS") {
      getCurrentLink = await getPreSignedUrl(path);
    }

    const anchorElement = document.createElement("a");
    anchorElement.download = name;
    anchorElement.href = getCurrentLink;
    anchorElement.setAttribute("target", "_blank");
    anchorElement.click();
  };

  return (
    <div className="pdfViewerContainer">
      {getDataFromStorage("role") === "learner" && (
        <div className="pdfViewerFileNameSec">
          <p className="pdfViewerFileName">
            {name?.substring(0, name?.lastIndexOf("."))}
          </p>
          {isDownloadable && (
            <DownloadSvgIcon onClick={() => handleDownload(path)} />
          )}
        </div>
      )}
      {pdfDataUrl && (
        <Document
          file={pdfDataUrl}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={(err) => console.error(err)}
          onSourceError={(err) => console.error(err)}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page pageNumber={index + 1} key={`page_${index + 1}`} />
          ))}
        </Document>
      )}
    </div>
  );
};

export default PdfViewer;
