import React, { useState } from "react";
import InputDropdown from "../../../CommonComponents/InputDropdown"; // Assuming this is a custom component
import InputField from "../../../CommonComponents/InputField";
import { getDataFromStorage } from "../../../../util";
import constants from "../../../../constants";

const ArrowIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 18L15 12L9 6"
      stroke="#667085"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const CheckIcon = ({ isActive }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.6666 5L7.49998 14.1667L3.33331 10"
      stroke={isActive ? "#12B76A" : "#D0D5DD"}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const EditIcon = ({ onClick, styles }) => (
  <button className="bdPathCheckBtn" style={styles} onClick={onClick}>
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 16.6667H17.5M13.75 2.91669C14.0815 2.58517 14.5312 2.39893 15 2.39893C15.2321 2.39893 15.462 2.44465 15.6765 2.53349C15.891 2.62233 16.0858 2.75254 16.25 2.91669C16.4142 3.08085 16.5444 3.27572 16.6332 3.4902C16.722 3.70467 16.7678 3.93455 16.7678 4.16669C16.7678 4.39884 16.722 4.62871 16.6332 4.84319C16.5444 5.05766 16.4142 5.25254 16.25 5.41669L5.83333 15.8334L2.5 16.6667L3.33333 13.3334L13.75 2.91669Z"
        stroke="#667085"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </button>
);

const BatchPaths = ({
  batchDetails,
  paths,
  onChangeOldPathName,
  pathNames,
  isChangePathName,
  setIsChangePathName,
  updateOldPathName,
  newPath,
  setNewPath,
  addNewPath,
}) => {
  const isLMSorClientEval =
    (parseInt(getDataFromStorage("accountType")) ===
      constants.ACCOUNT_TYPE.LMS &&
      (batchDetails.batchtype === constants.BATCH_TYPE.LEARNING ||
        (batchDetails.batchtype === constants.BATCH_TYPE.TESTING &&
          !batchDetails?.learningpath?.length))) ||
    (parseInt(getDataFromStorage("accountType")) ===
      constants.ACCOUNT_TYPE.CLIENT_EVAL &&
      !batchDetails?.learningpath?.length);

  const [editPathIndex, setEditPathIndex] = useState(null);

  const handleEditPathDetails = (event, index) => {
    event.preventDefault();
    setIsChangePathName(true);
    setEditPathIndex(index);
  };

  return (
    <div className="bdPathSec">
      {batchDetails?.learningpath?.map((lp, index) =>
        lp.lpid && lp.lpname ? (
          <div className="bdPath" key={index}>
            <InputDropdown
              type="default"
              placeholder="Select Path"
              menuLists={paths}
              value={lp.lpname}
              label={index === 0 ? "Path" : null}
              disabled
            />
            <div className={index === 0 ? "bdPathArrowIcon" : ""}>
              <ArrowIcon />
            </div>
            <div className="bdRenameSec">
              <InputField
                type="default"
                placeholder="Rename path name"
                onChange={(event) =>
                  onChangeOldPathName(event.target.value, lp.lpid)
                }
                value={pathNames[lp.lpid]?.coursename}
                label={
                  index === 0 ? (
                    <div className="bdPathNameLabel">
                      Rename Path for Batch<span> (Optional)</span>
                    </div>
                  ) : null
                }
                disabled={editPathIndex !== index}
              />
              {editPathIndex !== index && (
                <EditIcon
                  onClick={(event) => handleEditPathDetails(event, index)}
                  styles={{ marginTop: index === 0 ? "26px" : "0" }}
                />
              )}
              {isChangePathName && editPathIndex === index && (
                <button
                  className="bdPathCheckBtn"
                  onClick={(event) => updateOldPathName(event, lp.lpid)}
                  style={{ marginTop: index === 0 ? "26px" : 0 }}
                >
                  <CheckIcon isActive />
                </button>
              )}
            </div>
          </div>
        ) : null
      )}
      {isLMSorClientEval && (
        <div className="bdPath">
          <InputDropdown
            type="default"
            placeholder="Select Path"
            menuLists={paths}
            value={newPath.lpName}
            label={
              batchDetails?.learningpath.length > 0 &&
              batchDetails?.learningpath[0]?.lpid
                ? null
                : "Path"
            }
            onChange={(event) =>
              setNewPath((prev) => ({
                ...prev,
                lpName: event.target.value,
                lpId: event.target.id,
              }))
            }
          />
          <div
            className={
              batchDetails?.learningpath.length === 0 ? "bdPathArrowIcon" : ""
            }
          >
            <ArrowIcon />
          </div>
          <div className="bdRenameSec">
            <InputField
              type="default"
              placeholder="Rename path name"
              onChange={(event) =>
                setNewPath((prev) => ({
                  ...prev,
                  courseName: event.target.value,
                }))
              }
              value={newPath.courseName}
              label={
                batchDetails?.learningpath.length > 0 &&
                batchDetails?.learningpath[0]?.lpid ? null : (
                  <div className="bdPathNameLabel">
                    Rename Path for Batch<span> (Optional)</span>
                  </div>
                )
              }
            />
            <button
              disabled={!newPath.lpId}
              className={`bdPathCheckBtn ${newPath.lpId ? "allowed" : ""}`}
              style={{
                marginTop: !batchDetails?.learningpath.length ? "26px" : "",
              }}
              onClick={addNewPath}
            >
              <CheckIcon />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default BatchPaths;
