import { nanoid } from "nanoid";
import TestConfig from "./TestConfig";
import Radio from "@mui/material/Radio";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import constants from "../../../constants";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import { default as ReactSelect } from "react-select";
import AlertContext from "../../../context/AlertContext";
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate";
import CreateTestHooks from "../../../Hooks/CreateTestHooks";
import FormControlLabel from "@mui/material/FormControlLabel";
import React, { useContext, useEffect, useRef, useState } from "react";
import useCommonFunctionHooks from "../../../Hooks/useCommonFunctionHooks";
import { getDataFromStorage } from "../../../util";

// import Notify from '../../Notification-Loading/Notify';

const CreateTest = (props) => {
  const { setOldTestConfig, setPostViewTest, setShowNotify } =
    useContext(AlertContext);

  const axios = useAxiosPrivate();
  const { customStylesForTests } = CreateTestHooks();
  const { getCatchBlockDetails, getHeaderDetailsForAxios } =
    useCommonFunctionHooks();

  let MAXCHAR = 300;

  const [isNext, setIsNext] = useState(false);
  const [testOptions, setTestOptions] = useState([]);

  let [testData, setTestData] = useState(
    props.oldTestConfig.isOldTestConfig
      ? props.oldTestConfig.oldTestData
      : {
          testDes: "",
          viewTest: 0,
          ListView: 1,
          testMode: 2,
          id: nanoid(),
          testName: "",
          isOld: false,
          key: nanoid(),
          weightage: 10,
          status: "new",
          testDuration: "",
          testStatus: "new",
          testDurationNum: "",
          testType: testOptions,
          customInstruction: "",
          testDurationUnit: "min/s",
          sections: [
            {
              id: 1,
              key: nanoid(),
              status: "new",
              testStatus: "new",
              secName:
                props.lpData.libTypeId === constants.libTypeIds.CODING_CHALLENGE
                  ? "Coding section"
                  : "",
              secData: [
                {
                  id: 2,
                  key: nanoid(),
                  status: "new",
                  testStatus: "new",
                },
              ],
            },
          ],
        }
  );

  const [isBackDetails, setIsBackDetails] = useState({
    testType: "",
    isBack: false,
  });

  const [descriptiveOptions, setDescriptiveOptions] = useState([]);

  const [isChangeType, setIsChangeType] = useState({
    type: "",
    isChange: false,
  });

  const [maxCharForDes, setMaxCharForDes] = useState(
    props.oldTestConfig.isOldTestConfig &&
      props.oldTestConfig.oldTestData.testDes !== null
      ? MAXCHAR - props.oldTestConfig.oldTestData.testDes.length
      : 300
  );

  const editorRef = useRef(null);

  // const [displayResults, setDisplayResults] = useState(props.oldTestConfig.isOldTestConfig ? props.oldTestConfig.oldTestData.displayResults:constants.DISPLAY_TEST_RESULT.NONE);
  // const [isViewTest, setIsViewTest] = useState(props.oldTestConfig.isOldTestConfig ? props.oldTestConfig.oldTestData.viewTest : false);

  const navigate = useNavigate();

  const [viewTest, setViewTest] = useState(
    props.oldTestConfig.isOldTestConfig
      ? props.oldTestConfig.oldTestData.viewTest
      : constants.DISPLAY_TEST_RESULT.NONE
  );

  const [listview, setlistview] = useState(
    props.oldTestConfig.isOldTestConfig
      ? props.oldTestConfig.oldTestData.listview
      : constants.SHOW_LIST_VIEW.YES
  );

  const [autoEvaluate, setAutoEvaluate] = useState(
    props.oldTestConfig.isOldTestConfig
      ? props.oldTestConfig.oldTestData.autoEvaluate
      : false
  );

  const [videoQn, setVideoQn] = useState(
    props.oldTestConfig.isOldTestConfig
      ? props.oldTestConfig.oldTestData.videoQn
      : false
  );

  const [qnsCount, setQnsCount] = useState(0);

  const handleUpdateTestSettings = async (viewTest) => {
    // const handleUpdateTestSettings = async (display) => {

    try {
      // props.setIsLoaded(true);
      // viewTestRef.current.disabled=true;
      // for(let element of DisplayTestRef.current)  if(element)element.disabled=true;
      // let displayResultsValue = viewTest ? display[0] ? constants.DISPLAY_TEST_RESULT.ONLY_FINAL_SCORE : constants.DISPLAY_TEST_RESULT.SHOW_ALL : 0;

      let response = await axios.put(
        `node/admin/test/settings/update/${testData.testId}`,
        JSON.stringify({ viewTest: viewTest }),
        getHeaderDetailsForAxios()
      );

      if (response.data.resultCode !== constants.RESULT_STATUS.SUCCESS) {
        setShowNotify({
          show: true,
          title: "Warning",
          msg: "omething went wrong. Please try again later.",
        });

        // setIsViewTest(props.oldTestConfig.oldTestData.viewTest);
        // setDisplayResults(props.oldTestConfig.oldTestData.displayResults === constants.DISPLAY_TEST_RESULT.ONLY_FINAL_SCORE ? [true, false] : props.oldTestConfig.oldTestData.displayResults === constants.DISPLAY_TEST_RESULT.SHOW_ALL ? [false, true] : [false, false]);

        setViewTest(props.oldTestConfig.oldTestData.viewTest);
        setPostViewTest(props.oldTestConfig.oldTestData.viewTest);

        // setDisplayResults(props.oldTestConfig.oldTestData.displayResults);
      } else {
        return;
      }
    } catch (error) {
      getCatchBlockDetails(error);
      setViewTest(props.oldTestConfig.oldTestData.viewTest);
      setPostViewTest(props.oldTestConfig.oldTestData.viewTest);

      // setDisplayResults(props.oldTestConfig.oldTestData.displayResults === constants.DISPLAY_TEST_RESULT.ONLY_FINAL_SCORE ? [true, false] : props.oldTestConfig.oldTestData.displayResults === constants.DISPLAY_TEST_RESULT.SHOW_ALL ? [false, true] : [false, false]);
    } finally {
      props.setIsLoaded(false);

      // viewTestRef.current.disabled=false;
      // for(let element of DisplayTestRef.current) if(element)element.disabled=false;
    }
  };

  const handleChangeViewTest = async (event) => {
    let viewTestvalue = parseInt(event.target.value);

    if (props.oldTestConfig.isOldTestConfig)
      await handleUpdateTestSettings(viewTestvalue);

    // setDisplayResults(displayResultsValue)

    setViewTest(viewTestvalue);
    setPostViewTest(viewTestvalue);
    setTestData({ ...testData, ViewTest: viewTestvalue });
  };

  const handleUpdateViewListtSettings = async (listview) => {
    // const handleUpdateTestSettings = async (display) => {

    try {
      // props.setIsLoaded(true);
      // viewTestRef.current.disabled=true;
      // for(let element of DisplayTestRef.current)  if(element)element.disabled=true;
      // let displayResultsValue = viewTest ? display[0] ? constants.DISPLAY_TEST_RESULT.ONLY_FINAL_SCORE : constants.DISPLAY_TEST_RESULT.SHOW_ALL : 0;

      let response = await axios.put(
        `node/admin/test/settings/update/${testData.testId}`,
        JSON.stringify({ ListView: listview }),
        getHeaderDetailsForAxios()
      );

      if (response.data.resultCode !== constants.RESULT_STATUS.SUCCESS) {
        setShowNotify({
          show: true,
          title: "Warning",
          msg: "Something went wrong. Please try again later.",
        });

        // setIsViewTest(props.oldTestConfig.oldTestData.viewTest);
        // setDisplayResults(props.oldTestConfig.oldTestData.displayResults === constants.DISPLAY_TEST_RESULT.ONLY_FINAL_SCORE ? [true, false] : props.oldTestConfig.oldTestData.displayResults === constants.DISPLAY_TEST_RESULT.SHOW_ALL ? [false, true] : [false, false]);

        setlistview(parseInt(props.oldTestConfig.oldTestData.listview));

        // setDisplayResults(props.oldTestConfig.oldTestData.displayResults);
      }
    } catch (error) {
      getCatchBlockDetails(error);

      setViewTest(props.oldTestConfig.oldTestData.viewTest);
      setPostViewTest(props.oldTestConfig.oldTestData.viewTest);

      // setDisplayResults(props.oldTestConfig.oldTestData.displayResults === constants.DISPLAY_TEST_RESULT.ONLY_FINAL_SCORE ? [true, false] : props.oldTestConfig.oldTestData.displayResults === constants.DISPLAY_TEST_RESULT.SHOW_ALL ? [false, true] : [false, false]);
    } finally {
      props.setIsLoaded(false);

      // viewTestRef.current.disabled=false;
      // for(let element of DisplayTestRef.current) if(element)element.disabled=false;
    }
  };

  const handleChangeListView = async (event) => {
    let listviewvalue = parseInt(event.target.value);

    if (props.oldTestConfig.isOldTestConfig)
      await handleUpdateViewListtSettings(listviewvalue);

    // setDisplayResults(displayResultsValue)

    setlistview(parseInt(event.target.value));
    setTestData({ ...testData, ListView: listviewvalue });
  };

  const handleConfirmChangeType = (isTrue) => {
    if (isTrue) {
      setIsBackDetails({ isBack: false, testType: "" });
      setTestData({
        testDes: "",
        id: nanoid(),
        testName: "",
        key: nanoid(),
        status: "old",
        testDuration: "",
        testDurationNum: "",
        testDurationUnit: "day/s",
        testType: isChangeType.type,
        sections: [
          {
            id: 1,
            secName: "",
            key: nanoid(),
            status: "old",
            secData: [
              {
                id: 2,
                key: nanoid(),
                status: "old",
              },
            ],
          },
        ],
      });
    } else {
      testData.testType = isBackDetails.testType;
      setIsBackDetails({ isBack: true, testType: "" });
    }

    setIsChangeType({ isChange: false, type: "" });
  };

  useEffect(() => {
    if (!props.oldTestConfig.isOldTestConfig) {
      props.setIsLoaded(true);
      axios
        .get("node/admin/library/gettypes", getHeaderDetailsForAxios())
        .then((response) => {
          let responseData = JSON.stringify(response);

          responseData = JSON.parse(responseData).data;

          let testOptions = [],
            descriptiveOptions = [];

          for (let i = 0; i < responseData.length; i++) {
            let temp = {
              value: responseData[i].id,
              label: responseData[i].name,
            };

            // if (responseData[i].id !== 1 && responseData[i].parenttype === 0) {
            //     testOptions.push(temp);
            // } else if (responseData[i].parenttype > 0) {
            //     descriptiveOptions.push(temp);
            // }

            if (responseData[i].id === props.lpData.libTypeId) {
              testOptions = temp;
            } else if (
              responseData[i].parenttype === constants.libTypeIds.DESCRIPTIVE
            ) {
              descriptiveOptions.push(temp);
            }
          }

          testData.testType = testOptions;
          setTestOptions(testOptions);
          setDescriptiveOptions(descriptiveOptions);
        })
        .catch((error) => {
          getCatchBlockDetails(error);
        })
        .finally(() => props.setIsLoaded(false));
    } else {
      setTestOptions(props.oldTestConfig.oldTestData.testType);
      setDescriptiveOptions([
        { label: "SAQ", value: constants.libTypeIds.SAQ },
        { label: "LAQ", value: constants.libTypeIds.LAQ },
      ]);
    }

    //  setTimeout(() => {

    setViewTest(
      props.oldTestConfig.isOldTestConfig
        ? props.oldTestConfig.oldTestData.viewTest
        : constants.DISPLAY_TEST_RESULT.NONE
    );

    setlistview(
      props.oldTestConfig.isOldTestConfig
        ? props.oldTestConfig.oldTestData.listview
        : parseInt(constants.SHOW_LIST_VIEW.YES)
    );

    //  }, 100);
  }, [
    props.oldTestConfig.isOldTestConfig,
    props.oldTestConfig.oldTestData.testType,
  ]);

  useEffect(() => {
    // props.oldTestConfig.isOldTestConfig ? props.oldTestConfig.oldTestData.viewTest:constants.DISPLAY_TEST_RESULT.NONE

    if (props.oldTestConfig.isOldTestConfig) {
      setViewTest(
        props.oldTestConfig.isOldTestConfig
          ? props.oldTestConfig.oldTestData.viewTest
          : constants.DISPLAY_TEST_RESULT.NONE
      );
      setPostViewTest(
        props.oldTestConfig.isOldTestConfig
          ? props.oldTestConfig.oldTestData.viewTest
          : constants.DISPLAY_TEST_RESULT.NONE
      );
    } else {
      setViewTest(constants.DISPLAY_TEST_RESULT.NONE);
      setPostViewTest(constants.DISPLAY_TEST_RESULT.NONE);
    }
  }, []);

  useEffect(() => {
    if (props.oldTestConfig.isOldTestConfig) {
      let newDetails = JSON.parse(
          JSON.stringify(props.oldTestConfig.oldTestData)
        ),
        count = 0,
        regexForNum = /^[0-9]\d*$/;

      for (let sec in newDetails.sections) {
        for (let secData in newDetails.sections[sec].secData) {
          if (
            regexForNum.test(
              newDetails.sections[sec].secData[secData].fixedQnCount
            )
          )
            count += newDetails.sections[sec].secData[secData].fixedQnCount;
          if (newDetails.sections[sec].secData[secData].randomQnCount)
            count += newDetails.sections[sec].secData[secData].randomQnCount;
        }
      }

      setQnsCount(count);
    } else return;
  }, [props.oldTestConfig]);

  const handleTestDetails = (event, field) => {
    let newDetails = { ...testData };

    if (field === "testType") {
      if (isBackDetails.isBack) {
        if (event.label === isBackDetails.testType.label)
          setIsBackDetails({ isBack: true, testType: "" });
        else {
          setIsChangeType({ isChange: true, type: event });
        }
      }

      newDetails[field] = event;
      newDetails.testTypeId = event.value;
    } else if (field === "testMode") {
      newDetails[event.target.name] = event.target.id;
    } else if (field === "customInstruction") {
      newDetails[field] = event;
    } else {
      if (
        event.target.name === "testDurationNum" ||
        event.target.name === "weightage"
      ) {
        if (event.target.value !== "") {
          let regexForNum = /^[1-9]\d*$/;

          if (regexForNum.test(event.target.value)) {
            newDetails[event.target.name] = Number(event.target.value);
          } else {
            setShowNotify({
              show: true,
              title: "Warning",
              msg: "Please enter valid number",
            });
            newDetails[event.target.name] = "";
          }
        }
      }

      newDetails[event.target.name] = event.target.value;

      if (event.target.name === "testDes")
        setMaxCharForDes(MAXCHAR - event.target.value.length);

      // if (event.target.name === "videoqn")
      //   newDetails[event.target.name] = event.target.checked ? 1 : 0;
    }

    if (props.oldTestConfig.isOldTestConfig) newDetails.type = "update";
    setTestData(newDetails);
  };

  const handleClickNext = () => {
    if (testData.testType === "") {
      setShowNotify({
        show: true,
        title: "Warning",
        msg: "Please select test type.",
      });
    } else if (testData.testName === "") {
      setShowNotify({
        show: true,
        title: "Warning",
        msg: "Please enter test name.",
      });
    } /* else if (testData.weightage === "") {
            props.setShow(true);
            props.setMsg("Please enter weightage for this test.");
            props.setTitle("Warning !");
        } */ else if (
      testData.testType.value !== constants.libTypeIds.CODING_CHALLENGE
    ) {
      if (testData.testDurationNum === "") {
        setShowNotify({
          show: true,
          title: "Warning",
          msg: "Please enter duration.",
        });
      } else {
        // let regexForCheckDuration = /[0-9]\u0068|[0-9]\u006D/g;
        // if (!regexForCheckDuration.test(testData.testDuration)) {
        //     props.setShow(true);
        //     props.setMsg("Please enter valid duration.");
        //     props.setTitle("Warning !");
        // } else {

        if (!isBackDetails.isBack && !props.oldTestConfig.isOldTestConfig) {
          testData.sections[0].secData[0].complexity = "";
          testData.sections[0].secData[0].topic = "";
          testData.sections[0].secData[0].marks = "";
          if (
            testData.testType.value === constants.libTypeIds.DESCRIPTIVE &&
            !videoQn
          )
            testData.sections[0].secData[0].desType = "";
          else if (
            testData.testType.value === constants.libTypeIds.DESCRIPTIVE &&
            videoQn
          ) {
            testData.sections[0].secData[0].durationNum = "";
            testData.sections[0].secData[0].durationUnit = "min/s";
          }
          testData.sections[0].secData[0].randomQnCount = "";
          testData.sections[0].secData[0].fixedQnCount = "";
        }

        testData.testDuration =
          testData.testDurationNum +
          (testData.testDurationUnit === "hour/s"
            ? "h"
            : testData.testDurationUnit === "day/s"
            ? "d"
            : "m");
        setIsNext(true);

        // }
      }
    } else {
      if (!isBackDetails.isBack && !props.oldTestConfig.isOldTestConfig) {
        testData.sections[0].secData[0].complexity = "";
        testData.sections[0].secData[0].topic = "";
        testData.sections[0].secData[0].marks = "";
        testData.sections[0].secData[0].randomQnCount = "";
        testData.sections[0].secData[0].fixedQnCount = "";
        testData.sections[0].secData[0].languages = [];
        testData.sections[0].secData[0].durationNum = "";
        testData.sections[0].secData[0].durationUnit = "min/s";
      }
      testData.testDuration =
        (testData.testDurationNum === "" ? 0 : testData.testDurationNum) +
        (testData.testDurationUnit === "hour/s"
          ? "h"
          : testData.testDurationUnit === "day/s"
          ? "d"
          : "m");
      setIsNext(true);
    }
    testData.viewTest = viewTest;
    testData.autoEvaluate = autoEvaluate;
    testData.videoQn = videoQn;
  };

  const handleCancelTest = () => {
    if (!props.oldTestConfig.isOldTestConfig) {
      props.setIsAddTest(false);
      props.setIsNewTest(false);
    } else if (
      props.oldTestConfig.isOldTestConfig &&
      props.isOldLpDetail &&
      props.isFromBatch
    )
      navigate(`/batches/${sessionStorage.getItem('abatchid')}/courses`);
    else if (props.oldTestConfig.isOldTestConfig && props.fromMenu) {
      props.setIsAddTest(false);
      props.setFromMenuToEditTest(true);
      setOldTestConfig({
        isOldTestConfig: false,
        oldTestData: {},
      });
    } else navigate("/learningpath");
  };

  // const children = (
  //     <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
  //         <FormControlLabel
  //             label="Only show the Final Scores"
  //             control={<Checkbox checked={displayResults[0]} inputRef={(ref)=>DisplayTestRef.current[0]=ref} onChange={(event) => { handleChangeDisplayResult([event.target.checked, !displayResults[1]]); setDisplayResults([event.target.checked, !displayResults[1]]) }} />}
  //         />
  //         <FormControlLabel
  //             label="Show all details (Scores, correct answers, and wrong answers)"
  //             control={<Checkbox checked={displayResults[1]} inputRef={(ref)=>DisplayTestRef.current[1]=ref} onChange={(event) => { handleChangeDisplayResult([!displayResults[0], event.target.checked]); setDisplayResults([!displayResults[0], event.target.checked]) }} />}
  //         />
  //     </Box>
  // );

  const handleAutoEvaluate = () => {
    setAutoEvaluate((pre) => !pre);

    if (props.oldTestConfig.isOldTestConfig) {
      let newDetails = JSON.parse(JSON.stringify(testData));

      newDetails.type = "update";
      setTestData(newDetails);
    }
  };

  const handleVideoQn = () => {
    setVideoQn((pre) => !pre);

    if (props.oldTestConfig.isOldTestConfig) {
      let newDetails = JSON.parse(JSON.stringify(testData));

      newDetails.type = "update";
      setTestData(newDetails);
    }
  };

  return (
    <div>
      {!isNext && (
        <div className="adminTestNewSec">
          <div className="adminTestNewSec-left-sec">
            <div className="adminTestNewSec-left-sec-header">
              {/* <h3> Details</h3> */}
            </div>
            <div className="adminTestTypeSection">
              <label className="adminTestTypeLabel" htmlFor="adminTestType">
                Test type{" "}
              </label>
              <ReactSelect
                isDisabled
                inputId="1"
                name="testType"
                value={testOptions}
                allowSelectAll={true}
                closeMenuOnSelect={true}
                className="adminTestType"
                hideSelectedOptions={false}
                styles={customStylesForTests}
                placeholder="Select test type"
                components={{
                  DropdownIndicator: () => null,
                  IndicatorSeparator: () => null,
                }}
                // options={testOptions}
                // onChange={(selectedType) => handleTestDetails(selectedType, "testType")}
              />
            </div>
            <div className="adminTestNameSection">
              <label className="adminTestNameLabel" htmlFor="adminTestName">
                Name{" "}
              </label>
              <input
                type="text"
                name="testName"
                maxLength="100"
                id="adminTestName"
                className="adminTestName"
                value={testData.testName}
                onChange={handleTestDetails}
                placeholder="Enter new test name"
                disabled={
                  /* props.isOldLpDetail  && testData.status === 'old'&& */ testData.published ===
                    0 || props.isNewTest
                    ? false
                    : true
                }
              />
            </div>
            <div className="adminTestDesSection">
              <label className="adminTestDesLabel" htmlFor="adminTestDes">
                Description{" "}
                <span className="adminOptionalText">(Optional)</span>
                {/* <span className='adminTestDesMaxChar'>Max character : {maxCharForDes}</span> */}{" "}
              </label>
              <textarea
                className="adminTestDes"
                name="testDes"
                id="adminTestDes"
                maxLength="300"
                placeholder="Description"
                onChange={handleTestDetails}
                value={
                  testData.testDes === undefined ||
                  testData.testDes === null ||
                  testData.testDes === "null"
                    ? ""
                    : testData.testDes
                }
                disabled={
                  testData.published === 0 || props.isNewTest ? false : true
                }
              ></textarea>
              {/* <ReactQuill type='text' className='adminTestDes' name="testDes" id='adminTestDes' value={testData.testDes} placeholder='Description' maxLength="300" onChange={(event) => handleTestDetails(event)}/> */}
            </div>
            {testData.testType.value !==
              constants.libTypeIds.CODING_CHALLENGE && (
              <div className="adminTestDurationSection">
                <label
                  htmlFor="adminTestDuration"
                  className="adminTestDurationLabel"
                >
                  Duration{" "}
                </label>
                <div>
                  <input
                    type="text"
                    maxLength="100"
                    name="testDurationNum"
                    id="adminTestDuration"
                    placeholder="Duration"
                    className="adminTestDurationNum"
                    value={testData.testDurationNum}
                    disabled={
                      testData.published === 0 || props.isNewTest ? false : true
                    }
                    onChange={handleTestDetails}
                  />
                  <select
                    placeholder="Duration"
                    name="testDurationUnit"
                    onChange={handleTestDetails}
                    className="adminTestDurationUnit"
                    value={testData.testDurationUnit}
                    disabled={
                      testData.published === 0 || props.isNewTest ? false : true
                    }
                  >
                    <option>min/s</option>
                    <option>hour/s</option>
                    <option>day/s</option>
                  </select>
                </div>
              </div>
            )}
            {parseInt(
              getDataFromStorage("accountType")) === constants.ACCOUNT_TYPE.LMS
             && (
              <div className="adminTestModeSection">
                <label className="adminTestModeLabel" htmlFor="adminTestMode">
                  Mode
                </label>
                <div className="adminTestModeSectionRadioDiv">
                  <input
                    id="1"
                    type="radio"
                    name="testMode"
                    value={testData.testMode}
                    onChange={(event) => handleTestDetails(event, "testMode")}
                    checked={
                      parseInt(testData.testMode) ===
                      constants.MODE.PRACTICE_MODE
                    }
                    disabled={
                      testData.published === 0 || props.isNewTest ? false : true
                    }
                  />
                  <label className="adminTestModeLabel" htmlFor="1">
                    Practice
                  </label>
                  <input
                    id="2"
                    type="radio"
                    name="testMode"
                    value={testData.testMode}
                    onChange={(event) => handleTestDetails(event, "testMode")}
                    checked={
                      parseInt(testData.testMode) === constants.MODE.TEST_MODE
                    }
                    disabled={
                      testData.published === 0 || props.isNewTest ? false : true
                    }
                  />
                  <label className="adminTestModeLabel" htmlFor="2">
                    Test
                  </label>
                  <input
                    id="3"
                    type="radio"
                    name="testMode"
                    value={testData.testMode}
                    onChange={(event) => handleTestDetails(event, "testMode")}
                    checked={
                      parseInt(testData.testMode) === constants.MODE.SELF_MODE
                    }
                    disabled={
                      testData.published === 0 || props.isNewTest ? false : true
                    }
                  />
                  <label className="adminTestModeLabel" htmlFor="3">
                    Self
                  </label>
                </div>
              </div>
            )}

            {testData.testType.value === constants.libTypeIds.DESCRIPTIVE && (
              <div className="adminTestToggleBtnSec">
                <div className="adminTestAutoEvalSec">
                  <Form.Check.Label id="adminTestAutoEvalLabel">
                    Auto Evaluate:
                  </Form.Check.Label>
                  <Form.Check
                    reverse
                    type="switch"
                    name="autoevaluate"
                    id="adminTestAutoEval"
                    checked={autoEvaluate}
                    className="adminTestAutoEval"
                    onChange={handleAutoEvaluate}
                    style={{ boxShadow: "none" }}
                    disabled={
                      /* props.isOldLpDetail  && testData.status === 'old'&& */ testData.published ===
                        0 || props.isNewTest
                        ? false
                        : true
                    }
                    // value={isChecked}
                    // label="Auto Evaluate"
                    // value={UserInput.autoevaluate}
                  />
                </div>
                <div className="adminTestAutoEvalSec">
                  <Form.Check.Label id="adminTestAutoEvalLabel">
                    Video Assessment:
                  </Form.Check.Label>
                  <Form.Check
                    reverse
                    type="switch"
                    name="videoqn"
                    checked={videoQn}
                    id="adminTestVideoQn"
                    onChange={handleVideoQn}
                    className="adminTestAutoEval"
                    style={{ boxShadow: "none" }}
                    disabled={
                      /* props.isOldLpDetail  && testData.status === 'old'&& */ testData.published ===
                        0 || props.isNewTest
                        ? false
                        : true
                    }
                    // value={isChecked}
                    // label="Auto Evaluate"
                    // value={UserInput.autoevaluate}
                  />
                </div>
              </div>
            )}

            <div className="adminTestInstructionSection">
              <label
                className="adminTestInstructionLabel"
                htmlFor="adminCustomInstructionLabel"
              >
                Instructions
              </label>
              <div>
                <Editor
                  id="adminCustomInstruction"
                  value={testData.customInstruction}
                  tinymceScriptSrc="/tinymce/tinymce.min.js"
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  onEditorChange={(event) =>
                    handleTestDetails(event, "customInstruction")
                  }
                  disabled={
                    /* props.isOldLpDetail  && testData.status === 'old'&& */ testData.published ===
                      0 || props.isNewTest
                      ? false
                      : true
                  }
                  init={{
                    license_key: 'gpl',
                    readonly: true,
                    menubar: false,
                    min_height: 150,
                    branding: false,
                    statusbar: false,
                    elementpath: false,
                    selector: "textarea",
                    allow_html_in_named_anchor: true,
                    plugins: [
                      "link",
                      "help",
                      "code",
                      "lists",
                      "image",
                      "table",
                      "media",
                      "anchor",
                      "editor",
                      "advlist",
                      "preview",
                      "charmap",
                      "wordcount",
                      "fullscreen",
                      "autoresize",
                      "visualblocks",
                      "searchreplace",
                      "insertdatetime",
                    ],
                    toolbar:
                      "undo redo | blocks | " +
                      "bold italic underline forecolor | link | alignleft aligncenter " +
                      "alignright alignjustify | bullist numlist outdent indent | " +
                      "removeformat | image | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | help |",
                    content_style:
                      "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                  }}
                />
              </div>
            </div>
            {/* <div className="adminTestWeightageSection">
              <label
                className="adminTestWeightageLabel"
                htmlFor="adminTestWeightage"
              >
                Weightage
              </label>
              <input
                className="adminTestWeightage"
                type="text"
                name="weightage"
                onChange={handleTestDetails}
                value={testData.weightage}
                disabled={
                   testData.published ===
                    0 || props.isNewTest
                    ? false
                    : true
                }
              />
            </div> */}
          </div>
          <div className="adminTestNewSec-right-sec">
            {/* <div> <h3>Post test Score Setting</h3> </div> */}
            <div className="post-test-section">
              <FormControl>
                <FormLabel
                  className="post-test-section-label"
                  id="demo-controlled-radio-buttons-group"
                >
                  {" "}
                  Select Student view after submitting this test?
                </FormLabel>
                <RadioGroup
                  value={viewTest}
                  onChange={handleChangeViewTest}
                  name="controlled-radio-buttons-group"
                  aria-labelledby="demo-controlled-radio-buttons-group"
                >
                  <FormControlLabel
                    control={<Radio />}
                    label="Show Nothing"
                    value={constants.DISPLAY_TEST_RESULT.NONE}
                  />
                  <FormControlLabel
                    control={<Radio />}
                    value={constants.DISPLAY_TEST_RESULT.SUBMISSION}
                    label="Allow Students to view the test and the given answers"
                  />
                  <FormControlLabel
                    control={<Radio />}
                    label="Only show the Final Scores"
                    value={constants.DISPLAY_TEST_RESULT.SCORE}
                  />
                  <FormControlLabel
                    control={<Radio />}
                    value={constants.DISPLAY_TEST_RESULT.ALL}
                    label="Show all details (Scores, correct answers, and wrong answers)"
                  />
                </RadioGroup>
              </FormControl>
            </div>

            {testData.testType.value !==
            constants.libTypeIds.CODING_CHALLENGE ? (
              <div>
                <br />
                <br />

                <div>
                  <FormControl>
                    <FormLabel
                      className="post-test-section-label"
                      id="demo-controlled-radio-buttons-group"
                    >
                      {" "}
                      Enable students to see questions in List View?
                    </FormLabel>
                    <RadioGroup
                      value={listview}
                      onChange={handleChangeListView}
                      name="controlled-radio-buttons-group"
                      aria-labelledby="demo-controlled-radio-buttons-group"
                    >
                      <FormControlLabel
                        label="Yes"
                        control={<Radio />}
                        value={constants.SHOW_LIST_VIEW.YES}
                      />
                      <FormControlLabel
                        label="No"
                        control={<Radio />}
                        value={constants.SHOW_LIST_VIEW.NO}
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
              </div>
            ) : null}

            <div className="adminTestBtn2Sec">
              <button className="adminTestCancelBtn" onClick={handleCancelTest}>
                Cancel
              </button>
              <button className="adminTestNextBtn" onClick={handleClickNext}>
                Next
              </button>
            </div>
          </div>
        </div>
      )}
      {isNext && (
        <TestConfig
          msg={props.msg}
          show={props.show}
          testData={testData}
          title={props.title}
          ListView={listview}
          viewTest={viewTest}
          qnsCount={qnsCount}
          setIsNext={setIsNext}
          lpData={props.lpData}
          setMsg={props.setMsg}
          setShow={props.setShow}
          setVideoQn={setVideoQn}
          fromMenu={props.fromMenu}
          setTitle={props.setTitle}
          isLoaded={props.isLoaded}
          isUnAuth={props.isUnAuth}
          setlistview={setlistview}
          setQnsCount={setQnsCount}
          setViewTest={setViewTest}
          setTestData={setTestData}
          setLists={props.setLists}
          setLpData={props.setLpData}
          isNewTest={props.isNewTest}
          setIsLoaded={props.setIsLoaded}
          isFromBatch={props.isFromBatch}
          setIsUnAuth={props.setIsUnAuth}
          setAutoEvaluate={setAutoEvaluate}
          setIsNewTest={props.setIsNewTest}
          setIsAddTest={props.setIsAddTest}
          oldTestConfig={props.oldTestConfig}
          isOldLpDetail={props.isOldLpDetail}
          setIsBackDetails={setIsBackDetails}
          setIsFromBatch={props.setIsFromBatch}
          descriptiveOptions={descriptiveOptions}
          setIsOldLpDetail={props.setIsOldLpDetail}
          handleNavigateToLpAfterAddTest={props.handleNavigateToLpAfterAddTest}
        />
      )}
      {/* <Notify show={props.show} msg={props.msg} title={props.title} onHide={props.isUnAuth?props.handleUnAuthAlert:props.handleClose}/> */}
      <Modal
        centered
        show={isChangeType.isChange}
        onHide={handleConfirmChangeType}
        style={{
          fontSize: "14px",
          fontWeight: "700",
          lineHeight: "16px",
          fontFamily: "Inter",
          fontstyle: "normal",
        }}
      >
        <Modal.Body style={{ height: "50px" }}>
          Do you want to change this type.If you change the type ,you will lose
          you configuration ?{" "}
        </Modal.Body>
        <Modal.Footer style={{ margin: "10px", padding: "0px" }}>
          <Button
            variant="primary"
            style={{ margin: "10px 10px 0 0" }}
            onClick={() => handleConfirmChangeType(true)}
          >
            Yes
          </Button>
          <Button
            variant="danger"
            style={{ margin: "10px 10px 0 0" }}
            onClick={() => handleConfirmChangeType(false)}
          >
            No
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CreateTest;
