import constants from "../../../constants";
import { getDataFromStorage } from "../../../util";
import SandPackEditorTools from "./SandPackEditorTools";
import AlertContext from "../../../context/AlertContext";
import useAxiosPrivate from "../../../Hooks/useAxiosPrivate";
import { SandpackProvider } from "@codesandbox/sandpack-react";
import Loading from "../../Notification-Loading/Loading/Loading";
import React, { memo, useContext, useEffect, useState } from "react";

export default memo(function SandPackEditor(Props) {
  const {
    isLoaded,
    lpConfigId,
    isFullScreensp,
    setIsFullScreensp,
    openSandpackEditor,
    setOpenSandpackEditor,
  } = useContext(AlertContext);

  const axios = useAxiosPrivate();
  const [startTime, setStartTime] = useState(null);

  useEffect(() => {
    const handleFullScreenChange = () => {
      setIsFullScreensp(
        document.fullscreenElement ||
          document.msFullscreenElement ||
          document.mozFullScreenElement ||
          document.webkitFullscreenElement
      );
    };

    document.addEventListener("fullscreenchange", handleFullScreenChange);
    document.addEventListener("MSFullscreenChange", handleFullScreenChange);
    document.addEventListener("mozfullscreenchange", handleFullScreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullScreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);

      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullScreenChange
      );

      document.removeEventListener(
        "mozfullscreenchange",
        handleFullScreenChange
      );

      document.removeEventListener(
        "MSFullscreenChange",
        handleFullScreenChange
      );
    };
  }, []);

  useEffect(() => {
    // When component mounts

    let role = getDataFromStorage("role");

    if (
      role === constants.Roles.learner &&
      ![
        constants.PROGRESS_STATUS.SUBMITTED &&
          constants.PROGRESS_STATUS.TO_BE_EVALUATED,
      ].includes(Props.status)
    ) {
      const newStartTime = Date.now();

      let storedTime;
      let learnerId = getDataFromStorage("learnerid");

      async function duration() {
        // storedTime = localStorage.getItem('timeSpent');

        let response = await axios.get(
          `node/learner/content/timespent/${learnerId}/${lpConfigId}`,
          {
            headers: {
              "Content-type": "application/json",
            },
          }
        );

        storedTime = response?.data?.data?.duration;
        storedTime = storedTime * 1000 * 60;
        setStartTime(newStartTime);
      }

      duration();

      // When component unmounts

      return () => {
        async function save() {
          const endTime = Date.now();

          let timeSpent =
            endTime - newStartTime + (storedTime ? storedTime : 0);
          timeSpent = Math.round(timeSpent / (60 * 1000));

          let learnerId = getDataFromStorage("learnerid");
          let requestData = {
            duration: timeSpent,
            learnerid: learnerId,
            lpconfigid: lpConfigId,
          };

          // localStorage.setItem('timeSpent', timeSpent.toString());

          let response = await axios.post(
            `node/learner/content/timespent`,
            requestData,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
        }

        save();
      };
    }
  }, []);

  return (
    <div
      className={
        isFullScreensp ? "sandpackEditorContainer1" : "sandpackEditorContainer2"
      }
    >
      {isLoaded && <Loading />}
      <SandpackProvider
        template={Props.selectedTemplate?.value?.toLowerCase()}
        options={{
          autorun: false,
          autoReload: false,
          showConsole: false,
        }}
      >
        <SandPackEditorTools
          status={Props.status}
          learnerId={Props.learnerId}
          libDetails={Props.libDetails}
          isqueReport={Props.isqueReport}
          template={Props.selectedTemplate}
          isAddSubmission={Props.isAddSubmission}
          openSandpackEditor={openSandpackEditor}
          setOpenSandpackEditor={setOpenSandpackEditor}
        />
      </SandpackProvider>
    </div>
  );
});
