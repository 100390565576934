import WarningMsg from "./WarningMsg";
import constants from "../../../../constants";
import RightMain from "../RightMain/RightMain";
import React, { useContext, useEffect } from "react";
import BackSvgIcon from "../../../../Svg/BackSvgIcon";
import TestSvgIcon from "../../../../Svg/TestSvgIcon";
import Header from "../../../CommonComponents/Header";
import ContentPage from "../../ContentPage/ContentPage";
import Overlay from "../../../CommonComponents/Overlay";
import TestDueHooks from "../../../../Hooks/TestDueHooks";
import LeftNavSvgIcon from "../../../../Svg/LeftNavSvgIcon";
import WarningSvgIcon from "../../../../Svg/WarningSvgIcon";
import AlertContext from "../../../../context/AlertContext";
import LeftNavBarHooks from "../../../../Hooks/LeftNavBarHooks";
import Progressbar from "../../../CommonComponents/Progressbar";
import CompanyLogo from "../../../CommonComponents/CompanyLogo";
import Loading from "../../../Notification-Loading/Loading/Loading";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import ModuleProgressSvgIcon from "../../../../Svg/ModuleProgressSvgIcon";

export default function LeftNavBar() {
  const Alert = useContext(AlertContext);

  const {
    lpData,
    testId,
    module,
    showMain,
    testData,
    isLoaded,
    testActive,
    contentData,
    moduleActive,
    updateLeftNav,
  } = useContext(AlertContext);

  const {
    openTest,
    collapsed,
    openModule,
    setCollapsed,
    activeTestRef,
    gotToDashboard,
    calculatePercentage,
    handleGetDataForHome,
  } = LeftNavBarHooks();

  const { currentDueLabel } = TestDueHooks();

  useEffect(() => {
    if (activeTestRef.current) {
      activeTestRef.current.scrollIntoView({
        block: "center",
        behavior: "smooth",
      });
    }
  }, [activeTestRef.current]);

  useEffect(() => {
    if (updateLeftNav || Object.keys(lpData).length === 0) {
      handleGetDataForHome();
    }
  }, [updateLeftNav]);

  return (
    <>
      {isLoaded && <Loading />}
      {Object.keys(lpData).length !== 0 && (
        <div className="testPageMain">
          <Sidebar collapsed={collapsed} className="leftNavMainContainer">
            <div className="leftNavheaderDiv">
              <header className="leftNavheader">
                <CompanyLogo component={"leftNav"} />
              </header>
              <div className="leftNavLearningPath">
                {(localStorage.getItem("issinglelp") !== "true" ||
                  localStorage.getItem("issingleBatch") !== "true") && (
                  <BackSvgIcon onClick={gotToDashboard} />
                )}
                <span className="leftNavLearningPathName">{lpData.lpname}</span>
                <div className="leftNavLearningPathInfo">
                  {localStorage.getItem("isTestBatch") === "true" ? (
                    <span className="leftNavLearningPathModule">
                      {lpData.modules.length} Sections
                    </span>
                  ) : (
                    <span className="leftNavLearningPathModule">
                      {lpData.modules.length} modules • {lpData.totalItems}{" "}
                      chapters
                    </span>
                  )}
                  <span className="leftNavLearningPathPercentage">
                    {calculatePercentage(
                      lpData.completedItems,
                      lpData.totalItems
                    )}
                    %
                  </span>
                </div>
                <Progressbar
                  bgColor="#475467"
                  component="leftNav"
                  percentage={calculatePercentage(
                    lpData.completedItems,
                    lpData.totalItems
                  )}
                />
              </div>
            </div>
            <Menu className="leftNavMenu">
              {lpData.modules.map((moduleData) => (
                <SubMenu
                  key={moduleData.modid}
                  label={moduleData.modname}
                  className="leftNavLearningPathModuleName"
                  open={moduleActive && moduleData.modid === module.id}
                  onClick={() => {
                    openModule(moduleData);
                  }}
                  disabled={
                    moduleData.data.length === 0 ||
                    moduleData.data[0].status ===
                      constants.PROGRESS_STATUS.LOCKED
                  }
                  icon={
                    <ModuleProgressSvgIcon
                      currentModuleStatus={calculatePercentage(
                        moduleData.completedItems,
                        moduleData.totalItems
                      )}
                    />
                  }
                >
                  {moduleData.data.map((test) => (
                    <Overlay
                      key={test.testid !== null ? test.testid : test.libid}
                      title={
                        currentDueLabel(testData.duedate) === "Due Today" ||
                        test.status === constants.PROGRESS_STATUS.LOCKED ||
                        (testActive &&
                          (test.testid === testId || test.libid === testId))
                          ? ""
                          : test.testname !== null
                          ? test.testname
                          : test.libname
                      }
                      component={
                        <MenuItem
                          className="leftNavLearningPathTestName"
                          disabled={
                            test.status === constants.PROGRESS_STATUS.LOCKED
                          }
                          ref={
                            testActive &&
                            (test.testid === testId || test.libid === testId)
                              ? activeTestRef
                              : null
                          }
                          onClick={() => {
                            openTest(test);
                          }}
                          active={
                            testActive &&
                            (test.testid === testId || test.libid === testId)
                          }
                          icon={
                            <TestSvgIcon
                              videoQn={test.videoqn}
                              autoEval={test.autoeval}
                              disabled={
                                test.status === constants.PROGRESS_STATUS.LOCKED
                              }
                              active={
                                testActive &&
                                (test.testid === testId ||
                                  test.libid === testId)
                              }
                              libType={
                                test.testtype !== null
                                  ? test.testtype
                                  : test.libtype
                              }
                            />
                          }
                        >
                          {test.testname !== null
                            ? test.testname
                            : test.libname}
                          {((currentDueLabel(test.duedate) === "Due Today" &&
                            test.status !==
                              constants.PROGRESS_STATUS.EVALUATED) ||
                            (((test.status !==
                              constants.PROGRESS_STATUS.UNLOCKED &&
                              test.status !==
                                constants.PROGRESS_STATUS.EVALUATED &&
                              test.testtype !==
                                constants.libTypeIds.CODING_CHALLENGE &&
                              test.libtype !==
                                constants.libTypeIds.ASSIGNMENT) ||
                              (test.status ===
                                constants.PROGRESS_STATUS.IN_PROGRESS &&
                                test.testtype !==
                                  constants.libTypeIds.CODING_CHALLENGE &&
                                test.libtype !==
                                  constants.libTypeIds.ASSIGNMENT)) &&
                              currentDueLabel(test.duedate) !== "Past Due")) &&
                            test.status !==
                              constants.PROGRESS_STATUS.TO_BE_EVALUATED &&
                            test.status !==
                              constants.PROGRESS_STATUS.PARTIAL_EVALUATED &&
                            test.status !==
                              constants.PROGRESS_STATUS.LOCKED && (
                              <Overlay
                                title={
                                  <WarningMsg
                                    status={test.status}
                                    due={currentDueLabel(test.duedate)}
                                  />
                                }
                                component={
                                  <WarningSvgIcon
                                    status={test.status}
                                    due={currentDueLabel(test.duedate)}
                                  />
                                }
                              />
                            )}
                        </MenuItem>
                      }
                    />
                  ))}
                </SubMenu>
              ))}
            </Menu>
          </Sidebar>
          <LeftNavSvgIcon
            collapsed={collapsed}
            onClick={() => setCollapsed(!collapsed)}
          />

          <main
            className={
              !collapsed
                ? "rightNavMainContainer"
                : "rightNavMainContainerCollapsed"
            }
          >
            <Header component="leftNav" collapsed={collapsed} />
            {showMain &&
              (testData.libtype !== null &&
              Object.keys(contentData).length !== 0 &&
              testData.libtype === constants.libTypeIds.CONTENT ? (
                <ContentPage />
              ) : (
                testData.libtype !== constants.libTypeIds.CONTENT && (
                  <RightMain />
                )
              ))}
          </main>
        </div>
      )}
    </>
  );
}
