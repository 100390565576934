import AlertContext from "../../../context/AlertContext";
import { SandpackFileExplorer } from "sandpack-file-explorer";
import React, { useContext, useEffect, useState } from "react";
import Loading from "../../Notification-Loading/Loading/Loading";

const styles = {
  div: {
    position: "absolute",
    right: "50px",
    top: "18px",
    zIndex: 2,
    transform: "translate(-0px, -0px)",
  },
  svg: {
    position: "absolute",
    right: "0px",
    width: "14px",
    height: "14px",
  },
  div2: {
    position: "relative",
  },
  input: {
    position: "absolute",
    left: "-15px",
    zIndex: 2,
    width: "14px",
    height: "14px",
    opacity: 0,
  },
};

export default function CustomFileExplorer({ sandpack }) {
  const {
    navigate,
    isFullScreensp,
    setIsFullScreensp,
    setIsAddSubmission,
    openSandpackEditor,
    setIsEditSubmission,
    setOpenSandpackEditor,
    isAdmin,
  } = useContext(AlertContext);

  let [hasred, sethadred] = useState(false);

  useEffect(() => {
    if (!hasred) {
      setTimeout(() => {
        sethadred(true);
      }, 1000);
    }
  }, [hasred]);

  const handleBack = () => {
    if (isFullScreensp) {
      document.exitFullscreen();
      setIsFullScreensp(false);
    }

    setIsAddSubmission(false);
    setIsEditSubmission(false);

    if (!openSandpackEditor) {
      if (isAdmin) {
        navigate("/assignment");
      } else {
        navigate("/home");
      }
    } else {
      setOpenSandpackEditor(false);
    }
  };

  const onUploadFile = (event) => {
    const reader = new FileReader();
    const fileName = event.target.files[0].name;
    reader.onload = (e) => {
      sandpack.addFile({
        ...sandpack.files,
        [`/${fileName}`]: { code: e.target.result },
      });
      sethadred(false)
    };
    reader.readAsDataURL(event.target.files[0]);
    event.target.value = "";
  };

  return (
    <div className="fileexplorercontainer" style={{ background: "#151515" }}>
      <div className="backfromsandpack" title="back">
        <svg
          width="18"
          height="18"
          fill="none"
          viewBox="0 0 18 18"
          onClick={handleBack}
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke="#EAECF0"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M11.25 13.5L6.75 9L11.25 4.5"
          />
        </svg>
      </div>

      <div
        style={{ background: "#151515", position: "relative" }}
        className="fileExplorercustomsandpack"
      >
        {hasred ? (
          <>
            <div style={styles.div}>
              <div style={{ position: "relativeF" }}>
                <svg
                  style={styles.svg}
                  className="sandpackUploadIcon"
                  width="14"
                  height="14"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21 15V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V15M17 8L12 3M12 3L7 8M12 3V15"
                    stroke="#FFFFFF"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <input
                  style={styles.input}
                  type="file"
                  onChange={onUploadFile}
                  accept="image/*"
                />
              </div>
            </div>

            <SandpackFileExplorer />
          </>
        ) : (
          <div className="loadinfileexp">
            <Loading />
          </div>
        )}
      </div>
    </div>
  );
}
