import { EDITOR_JS_TOOLS } from "./tools/tools";
import { createReactEditorJS } from "react-editor-js";
import React, {
  useRef,
  useCallback,
  memo,
} from "react";

function Editor(Props) {
  const editorRef = useRef(null);
  const editorCore = useRef(null);
  // const [startTime, setStartTime] = useState(null);

  // const { lpConfigId } = useContext(AlertContext);

  let ReactEditorJS = createReactEditorJS();

  const handleInitialize = useCallback((instance) => {
    instance._editorJS.isReady
      .then(() => {
        editorCore.current = instance;
      })
      .catch((err) => console.log("An error occured", err));
  }, []);

  const handleOnChange = useCallback(async () => {
    try {
      const savedData = await editorCore.current.save();

      let newData = JSON.stringify(JSON.parse(JSON.stringify(savedData)));

      Props.setuserinp({ ...Props.UserInput, "description": newData });
      Props.setNewContent(savedData);
      Props.setData(savedData);

      const editorContainer =
        document.getElementsByClassName("editor-container");
      editorContainer.scrollTop = editorContainer.scrollHeight;
    } catch (error) {}
  }, [Props.data]);

  // useEffect(() => {
  //   // When component mounts
  //   let role = getDataFromStorage("role");
  //   if (role == constants.Roles.learner) {
  //     const newStartTime = Date.now();
  //     let storedTime;
  //     let learnerId = getDataFromStorage("learnerid");
  //     async function duration() {
  //       // storedTime = localStorage.getItem('timeSpent');
  //       let response = await axios.get(
  //         `node/learner/content/timespent/${learnerId}/${lpConfigId}`,
  //         {
  //           headers: {
  //             "Content-type": "application/json",
  //           },
  //         }
  //       );
  //       storedTime = response?.data?.data?.duration;
  //       storedTime = storedTime * 1000 * 60;
  //       setStartTime(newStartTime);
  //     }
  //     duration();
  //     // When component unmounts -> newStartTime
  //     return () => {
  //       async function save() {
  //         const endTime = Date.now();
  //         let timeSpent =
  //           endTime - startTime  + (storedTime ? storedTime : 0);
  //         timeSpent = Math.round(timeSpent / (60 * 1000));
  //         let learnerId = getDataFromStorage("learnerid");
  //         let requestData = {
  //           duration: timeSpent,
  //           learnerid: learnerId,
  //           lpconfigid: lpConfigId,
  //         };
  //         // localStorage.setItem('timeSpent', timeSpent.toString());
  //         let response = await axios.post(
  //           `node/learner/content/timespent`,
  //           requestData,
  //           {
  //             headers: {
  //               "Content-Type": "application/json",
  //             },
  //           }
  //         );
  //       }
  //       save();
  //     };
  //   }
  // }, []);

  return (
    <div className="editor-container" ref={editorRef}>
      {Object.keys(Props.UserInput).length !== 0 && (
        <ReactEditorJS
          autofocus={true}
          readOnly={!Props.Mode}
          tools={EDITOR_JS_TOOLS}
          onChange={handleOnChange}
          className={"reactEditorjs"}
          onInitialize={handleInitialize}
          placeholder={"Let's Write an Awesome Tutorial!"}
          instanceRef={(instance) => (editorRef.current = instance)}
          defaultValue={
            Props.UserInput.description
              ? typeof Props.UserInput.description === "string"
                ? JSON.parse(Props.UserInput.description)
                : Props.UserInput.description
              : {}
          }
        />
      )}
    </div>
  );
}
export default memo(Editor);
