import React from "react";

export default function NotificationSvgIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.79496 17.4998C8.38257 18.0185 9.15444 18.3332 9.99981 18.3332C10.8452 18.3332 11.6171 18.0185 12.2047 17.4998M14.9998 6.6665V1.6665M12.4998 4.1665H17.4998M10.8331 1.73642C10.5596 1.69018 10.2809 1.6665 9.99981 1.6665C8.67373 1.6665 7.40196 2.19329 6.46428 3.13097C5.5266 4.06865 4.99981 5.34042 4.99981 6.6665C4.99981 9.24166 4.3502 11.0048 3.62453 12.171C3.01242 13.1547 2.70636 13.6466 2.71758 13.7838C2.73001 13.9357 2.7622 13.9937 2.88463 14.0845C2.99519 14.1665 3.49364 14.1665 4.49052 14.1665H15.5091C16.5059 14.1665 17.0044 14.1665 17.1149 14.0845C17.2374 13.9937 17.2696 13.9357 17.282 13.7838C17.2932 13.6466 16.9871 13.1547 16.375 12.1708C15.9649 11.5118 15.5792 10.6622 15.3212 9.58317"
        stroke="#F97256"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}
